@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

#content-profile {
  height: 108px;
  width: auto;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin-right: 2%;
  justify-content: space-evenly;
  background-color: $color-blue-light-profile;

  &.mobile-mode {
    max-width: 400px;
    width: auto;
    margin: 15px 0;
    background-color: white;

    .adjust-data {
      margin-left: 15px;
      padding-right: 15px;
      height: 90px;
      display: flex;
      justify-content: flex-start;

      .avatarIconProfileMX {
      margin: 10px 0px 0 0px;
      }
      .avatarIconProfile {
      margin: 10px 0px 0 0px;
      }
    }

    .container-text-personal-info {
      margin-left: 25px;

      .title-info {
        line-break: normal;
      }
      .description-info {
        line-break: anywhere;
      }
    }
  }

  .adjust-data {
    width: 100%;
    height: 85%;
    padding: 0 32px 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .avatarIconProfile {
      margin: 10px 20px 0 20px;
    h1 {
      text-transform: uppercase;
      font-weight: 500;
      background-color: $color-accent;
      width: 48px;
      height: 48px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center !important;
      font-family: "Ubuntu-Medium", sans-serif;
      font-size: 24px;
      color: $color-light;
    }
  }

  .avatarIconProfileMX {
    margin: 10px 20px 0 20px;
    h1 {
      text-transform: uppercase;
      font-weight: 500;
      background-color: $color-text-profile-mx;
      width: 48px;
      height: 48px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center !important;
      font-family: "Ubuntu-Medium", sans-serif;
      font-size: 24px;
      color: $color-light;
    }
  }

  .container-text-personal-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .title-info {
      font-size: 18px;
      color: $color-text-label;
      font-family: "Heebo-Medium", sans-serif;
    }

    .description-info {
      font-size: 15px;
      color: $color-empty-state-text;
      font-family: "Heebo-Regular", sans-serif;
    }

    .country-flag {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }
  }
  }
}
