.container-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    position: relative;
    top: 20px;
    left: 20px;
    height: 40px;
    width: 40px;
    border: transparent 5px solid;
    border-top-color: #afb4b8;
    border-left-color: #afb4b8;
    border-radius: 50%;
    animation: loader 1s linear infinite;
  }

  @keyframes loader {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
