@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

h1 {
  font-family: "Ubuntu-Medium", serif;
  font-size: 20px;
  color: $color-dark-title;
  font-weight: unset;

  &.italic {
    font-family: "Ubuntu-MediumItalic", serif;
    letter-spacing: .5px;
  }

  &.hebboMedium {
    font-family: "Heebo-Medium", serif;
  }

  &.fontSize18 {
    font-size: 18px;
  }
  &.currency {
    font-family: "Heebo-Medium", serif;
  }

  &.UbuntuBold {
    font-family: "Ubuntu-BoldItalic", sans-serif;
  }
}

h2 {
  font-family: "Ubuntu-Medium", serif;
  font-size: 18px;
  color: $color-dark;
  font-weight: unset;

  &.heebo-bold {
    font-family: "Heebo-Bold", serif;
    color: $color-empty-state-text;
  }

}

h3 {
  font-family: "Ubuntu-Medium", serif;
  font-size: 16px;
  color: $color-dark;
  font-weight: unset;

  &.left-spacing{
    margin-left: 20px;
  }

}

span.field{
  font-family: "Heebo-Medium", serif;
  font-size: 16px;
  color: $color-text-label;
}

span.field-gray{
  font-family: "Heebo-Medium", serif;
  font-size: 16px;
  color: $color-text-label-gray;

  &.color-darker {
    color: $color-dark-gray;
  }
}

span.field-gray-light{
  font-family: "Heebo-Regular", serif;
  font-size: 14px;
  color: $color-text-label-input-fiel;
}

span.header-quote-gray{
  font-family: "Heebo-Regular", serif;
  font-size: 14px;
  color: $color-header-gray;
}

span.field-dark-gray{
  font-family: "Heebo-Regular", serif;
  font-size: 14px;
  color: $color-text-title-dark-gray;

  &.color-darker {
    color: $color-dark-gray;
  }

  &.color-darker-gray {
    color: $color-dark;
  }

  &.color-lither-gray {
    color: $color-text-value;
  }

  &.color-gray-dark {
    color: $color-empty-state-text
  }

  &.heebo-medium {
    font-family: "Heebo-Medium", serif;
  }
}

span.title-dark {
  font-family: "Heebo-Regular", serif;
  font-size: 16px;
  color: $color-empty-state-text;
}
span.field-title-dark {
  font-family: "Heebo-Medium", serif;
  font-size: 14px;
  color: $color-empty-state-text;
}

span.field-subtitle-dark-light {
  font-family: "Heebo-Regular", serif;
  font-size: 14px;
  color: $color-empty-state-text-light
}

span.mini-subtitle-dark {
  font-family: "Heebo-Bold", serif;
  font-size: 16px;
  color: $color-empty-state-text;
}

span.value{
  font-family: "Heebo-Regular", serif;
  font-size: 16px;
  color: $color-text-value;
}

.font-size-16{
  font-size: 16px !important;
}

.marginTop8{
  margin-top: 8px;
}

.colorBlue{
  color: $color-accent !important;
}

.hebboMedium {
  font-family: "Heebo-Medium", serif;
}

.heeboRegular {
  font-family: "Heebo-Regular", serif;
}

.font-size-14 {
  font-size: 14px;
}

.font-weight-normal {
  font-weight: normal;
}

p {
  font-family: "Heebo-Regular", serif;
  color: $color-dark;
  font-size: 14px;

  &.color-ligther-gray{
    color: $color-ligther-gray
  }

  &.separation {
    margin: 0 8px;
  }
}
