@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";
@import "../../../../themes/general.scss";

.summary {
  font-family: "Heebo-Medium", sans-serif;
  font-size: 16px;
  margin: 16px 0;
}

.no-value {
  font-size: 14px !important;
}

.balance-to-recharge {
  font-size: 28px;
  margin-top: 12px;
  margin-bottom: 16px;
}

.space {
  font-family: "Heebo-Regular", sans-serif;
  color: $color-general-text;
  margin-top: 12px;
  margin-bottom: 16px;
}

#pse-container {
  width: 100%;
  text-align: center;

  .pse-info {
    .date-and-ref {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
    }

    .pse-button {
      width: 84%;
      padding: 8px 24px;
      margin: 16px 0;
      display: flex;
      align-items: center;
      border: 1px solid #d6dbdf;
      border-radius: 5px;
      cursor: pointer;

      .img {
        width: 24px;
        height: 24px;
        background-color: #009af0;
        mask: url(../../../../assets/images/payment_black.svg) no-repeat center / contain;
        -webkit-mask: url(../../../../assets/images/payment_black.svg) no-repeat center / contain;
      }

      .left-column {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .method {
          font-size: 14px;
          padding-left: 16px;
        }

        .arrow {
          width: 24px;
          height: 24px;
          background-color: #f08700;
          transform: rotate(180deg);
          mask: url(../../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
          -webkit-mask: url(../../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
        }
      }
    }

    &.mobile-mode {
      .date-and-ref {
        width: 99%;
      }

      .pse-button {
        padding: 8px 18px;
      }
    }
  }

  .pse-data {
    text-align: start;

    .bank-select {
      width: 99%;

      .container-value-selected__select-general {
        width: 100%;
        justify-content: space-between;

        span {
          white-space: break-spaces;
        }
      }

      .container-options__select-general {
        width: 100%;

        &.visible {
          span {
            white-space: break-spaces;
          }
        }
      }
    }

    .first-select {
      z-index: 10;
    }

    .second-select {
      z-index: 9;
    }

    .third-select {
      z-index: 8;
    }

    .less-width {
      width: 240px;
      height: 42px;
      min-height: 42px;
      margin: 0 auto;
    }
  }
}

#card-container {
  width: 100%;

  .first-row {
    display: flex;
    justify-content: space-between;

    .wrapper {
      width: 45%;

      .custom-icon {
        .container__input-general {
          .content-top__input-general {
            img {
              width: 35px;
              height: 35px;
            }
          }
        }
      }

      .bank-select {
        .container-value-selected__select-general {
          width: 100%;
          justify-content: space-between;

          span {
            white-space: break-spaces;
          }
        }

        .container-options__select-general {
          width: 100%;

          &.visible {
            span {
              white-space: break-spaces;
            }
          }
        }
      }

      .containter-select-all.top-left {
        width: 99%;
        .container-options__select-general {
          width: 100%;
        }
      }

      .first-select {
        z-index: 10;
      }

      .second-select {
        z-index: 9;
      }
    }
  }

  .second-row {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .date {
      width: 45%;
      text-align: justify;
      display: flex;
      justify-content: space-between;
    }

    .buttons-container {
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 240px;
      width: 100%;

      .first-button {
        width: 240px;
        margin: 24px 0;
      }

      .second-button {
        width: 84%;
      }
    }
  }

  .row {
    width: 100%;
  }

  &.mobile-mode {
    transform: translateY(-24px);
    .first-row {
      flex-direction: column;

      .wrapper {
        width: 100%;
      }
    }

    .second-row {
      .date {
        width: 100%;
      }
    }
  }
}
