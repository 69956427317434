// @import "../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

#movement-list {
  position: relative;
  height: calc(100% - 70px);
  width: 100%;
  padding: 20px 60px;
  overflow: scroll;
  overflow-x: hidden;
  box-sizing: border-box;

  button {
    padding: 12px;
    width: 50%;
    margin: 20px auto;

    .loadData {
      text-align: right;
      font-size: 15px;
      color: $color-primary;
      font-weight: bold;
    }
  }

  &.isMobileDeviceTabs {
    padding: 10px;
    height: 75%;
  }

}

#secctionFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 70px;

  .buttonEmpyState {
    width: 240px;
    height: 50px;
    margin: 24px auto;
  }

  .downloadFile {
    width: 259px;
    height: 40px;
    border-radius: 50px;

    .downloadFileText {
      text-align: center;
      font-size: 16px;
      color: #fff;
    }
  }

  .downloadFile-mx {
    width: 259px;
    height: 40px;
    border-radius: 50px;

    .downloadFileText {
      text-align: center;
      font-size: 16px;
      color: #fff;
    }
  }

  .toggleFilterButton {
    width: 45%;
  }

  &.isMobileDevice {
    margin: 20px 10px;
    width: calc(100% - 20px);

    .downloadFile {
      background: transparent linear-gradient(108deg, #009af0 0%, #1483c1 100%) 0% 0% no-repeat padding-box;
      padding: 12px;
      width: 259px;
      height: 40px;
      border-radius: 50px;

      .downloadFileText {
        text-align: center;
        font-size: 16px;
        color: #fff;
      }
    }

    .downloadFile-mx {
      background: transparent linear-gradient(108deg, $color-primary 0%, $color-primary-variant 100%) 0% 0% no-repeat padding-box;
      padding: 12px;
      width: 259px;
      height: 40px;
      border-radius: 50px;

      .downloadFileText {
        text-align: center;
        font-size: 16px;
        color: #fff;
      }
    }
  }
}

.image-empty-state {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: default !important;

  &:hover {
    background-color: transparent;
  }
}

.in-build {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 160px;
    max-height: 50%;
  }

  .in-build__text {
    margin-top: 20px;
    max-width: 60%;
    font-size: 32px;
    color: $color-accent;
  }
}

@media (max-width: 1023px) {
  .image-empty-state {
    justify-content: flex-start;
    overflow-y: auto;
  }
}

@media (max-width: 1366px) {
  .image-empty-state {
    justify-content: flex-start;
    overflow-y: auto;
  }
}
