@import "../../../../themes/colors.scss";

#shipmentDetail-collection {
  width: calc(100% - 25px);
  height: calc(100% - 30px);
  background-color: $color-light;
  border-radius: 10px;
  margin-top: 15px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
    border-bottom: 1px solid $color-primary-variant;

    h2 {
      font-family: "Ubuntu-MediumItalic", serif;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .right-mobile {
      display: none;
    }

    .arrow-back {
      width: 30px;
      height: 30px;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 50%;

      &.arrow-back {
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 156, 25, .3);
        }
      }
    }
    .arrow-back-mobile {
      display: none;
    }

    .title {
      margin-left: 10px;
    }
  }

  .flexbox-container {
    display: flex;
    width: 95%;
    padding-left: 5%;
    padding-bottom: 120px;
    margin-top: 3em;

    &>div {
      padding: 0 3%;
    }

    h3 {
      font-size: 16px;
      color: $color-dark;
      font-weight: unset;
      padding-bottom: 12px;
    }
    p {
      line-height: 24px;

    }
  }

  .flexbox-container-bottom {
    display: flex;
    width: 100%;
    padding-left: 3%;
    padding-bottom: 120px;

    &>div {
      padding: 0 5%;
    }

    h3 {
      font-size: 16px;
      color: $color-dark;
      font-weight: unset;
      padding-bottom: 12px;
    }

    p {
      line-height: 24px;

    }

  }
  .deliverycompanycollection {
    width: 32px;
    position: absolute;
    margin: 3em 4em;
  }

  .button-shipment-detail {
    position: fixed;
    width: 12%;
    bottom: 4em;
    right: 3.2em;
  }

}
.blue {
  color: $color-blue
}
.red {
  color: red;
}

@media only screen and (max-width: 1366px) {
  .flexbox-container {
    padding-left: 8% !important;
  }
  .flexbox-container-bottom {
    padding-left: 6% !important;
  }
  .button-shipment-detail {
    width: 16% !important;
  }
}
@media only screen and (max-width: 1023px) {
  #shipshipmentDetail-collection {
    overflow-y: scroll;
  }

  .container {
    display: grid;
    grid-template-columns: [col1] 55% [col2] 45%;
    grid-gap: 8px;
    text-align: center;
    color: #FFF;
  }

  .grid-cell-1, .grid-cell-2, .grid-cell-3, .grid-cell-4,
  .grid-cell-5, .grid-cell-6, .grid-cell-7,
  .grid-cell-8, .grid-cell-9, .grid-cell-10  {
    padding: 1em;
    border-radius: 6px;
    text-align: start;
  }
  .grid-cell-2 {
    margin-top: 1em;
  }

  .button-shipment-detail {
    width: 80% !important;
    margin: 1em auto;
  }

  .deliverycompanycollectionMobile {
    width: 32px;
    margin-right: 1.7em;
    margin-left: 0.9em;
  }
  .image-delivery {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10% auto;
  }
  .button-shipment-detail {
    top: 1em;
    position: relative !important;
    left: 0.5em;
  }

}
