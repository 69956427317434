@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.contacInfo-container {
  height: 85%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  .main-title {
    font-size: 14px;
    margin-bottom: 6%;
    color: $color-empty-state-text;
    font-family: "Heebo-Regular", sans-serif;

    span {
      font-size: 14px !important;
      color: $color-empty-state-text;
      font-family: "Heebo-Medium", sans-serif;
    }
  }

  .content-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .content-left-contact {
      width: 300px;
      padding-right: 6%;

      div {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .title-info {
          font-size: 16px;
          color: $color-empty-state-text;
          font-family: "Heebo-Medium", sans-serif;
        }
        .subtitle-info {
          font-size: 16px;
          color: $color-empty-state-text;
          font-family: "Heebo-Regular", sans-serif;
        }
      }
      .last {
        margin-bottom: 0;
      }
    }
    .content-right-contact {
      width: 300px;
      padding-left: 6%;
      border-left: 1px solid $color-gray-disabled;

      div {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .title-info {
          font-size: 16px;
          color: $color-empty-state-text;
          font-family: "Heebo-Medium", sans-serif;
        }
        .subtitle-info {
          font-size: 16px;
          color: $color-empty-state-text;
          font-family: "Heebo-Regular", sans-serif;
        }
      }
      .last {
        margin-bottom: 0;

        .cellphone-img {
          display: flex;
          flex-direction: row;

          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &.mobile-mode {
    width: 100%;
    padding-left: 10px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .main-title {
      width: auto;
      max-width: 274px;
      margin: 3% 0;
      text-align: start;
      align-items: center;
    }

    .content-info {
      width: auto;
      max-width: 350px;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .content-right-contact {
        width: auto;
        max-width: 350px;
        border: none;
        padding: 0;

        div {
          .title-info {
            line-break: auto;
          }
          .subtitle-info {
            line-break: auto;
          }
        }
      }
      .content-left-contact {
        width: auto;
        max-width: 350px;
        border: none;
        padding: 0;

        div {
          .title-info {
            line-break: auto;
          }
          .subtitle-info {
            line-break: auto;
          }
        }

        .last {
          margin-bottom: 30px;
        }
      }
    }
  }
}
