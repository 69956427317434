@import '../../../themes/colors.scss';
@import '../../../themes/fonts.scss';

#first-sending{
  border-radius: 12px;
  background: $color-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  box-shadow: 0px 16px 12px -10px rgba(108, 152, 181, 0.5);

  &::-webkit-scrollbar-track{ margin: 8px 0 }

  .container-text__first-sending{
    max-width: 480px;
    margin-top: 20px;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text__first-sending{

      margin: 5px 0;

      &.italic{
        margin: 0 0 5px;
        font-size: 18px !important;
        font-family: "Ubuntu-MediumItalic", sans-serif;
        text-align: center;
      }

      &.margin-top{
        margin-top: 14px;
      }

      &.margin-bottom{
        margin-bottom: 14px;
        color: #FF9C19;
      }
    }
  }

  .button-and-guide-man{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    img{
      max-height: 220px;
    }

    button{
      margin-top: 10px;
      width: 90%;
    }
  }

  &.mobile-mode{
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
    justify-content: space-between;

    .container-text__first-sending{
      margin-top: 50px;
      width: 80%;
    }

    .button-and-guide-man{
      margin-bottom: 30px;
      button{
        width: 80%;
      }
    }
  }

  &.desktop-mode{
    width: 600px;
    max-width: 90%;
    height: auto;
    min-height: 75%;
    max-height: 90%;
    margin: 20px auto;
    padding: 20px 0;
    box-sizing: border-box;
    justify-content: flex-start;

    .button-and-guide-man{
      button{
        max-width: 400px;
      }
    }
  }
}
