$color-primary: #FF9C19;
$color-primary-variant: #F08700;
$color-primary-variant-alpha: #F08700;
$color-primary-alpha3: #FF9C194C;
$color-primary-variant-light: #FCE7CC;
$color-accent: #009AF0;
$color-accent-variant: #0069A3;
$color-blue-variant: #009AF01A;
$color-blue-hover: #009AF040;
$color-accent-variant-light: #CCEBFC;
$color-accent-variant-gradiant: #1483C1;
$color-accent-variant-register: #009AF0;
$color-accent-varian-gray: #B0B9C1;
$color-secondary-hover-button: #F0870040;
$color-primary-hover-button: #009AF030;
$color-primary-hover-download-button: #1971C2;
$color-gray: #707070;
$color-blue-light-hover: #009AF04C;

$color-background: #E6EFF5;
$sw-color-background: #BECBD4;
$color-background-hover-menu: #E9F0F3;
$color-surface: #FFFFFF;
$color-white: #FFFFFF;
$color-background-opaque-white: #EEF1F2;
$color-gray-disabled: #D6DBDF;
$color-header-gray: #707B84;
$color-menu-background: #CEDCE5;
$color-hover-image-button: #E6EFF580;
$color-background-light: #009AF026;
$color-load-icon: #979797;

$color-success: #82cb06;
$color-success-payments: #0DB519;
$color-green-discount: #079B0C;
$color-new-green-discount: #078A0B;
$color-light-gray: #69767D;
$color-error: #F00000;
$color-red: #F00000;
$colot-background-error: #DB22250D;
$color-border-error: #DB2225;
$color-blue: #009AF0;
$color-anotation: #516575;
$new-color-gray-label: #808D97;

$color-line: #BBC3C9;
$color-blue-light-profile: #C2D0D94C;
$color-light: #FFFFFF;
$color-dark-light: #D6DBDF;
$color-dark: #353A3D;
$color-empty-state-text: #535A5F;
$color-dark-variant: #1a445b29;
$colr-ligth-gray: #B4B4B4;
$color-ligth-orange: #FBDBB2;
$color-gray-light: #B7E9BA;
$color-dark-gray: #5A6064;
$colot-darker-gray: #686E78;
$color-ligther-gray: #878F95;
$color-ligther-gray: #878F95;
$color-box-shadow-select: #00000029;
$color-empty-state-text-light: #707B84;


$color-text-value: #707B84;
$color-text-label: #353A3D;
$color-text-label-gray: #7B8287;
$color-text-title-gray: #7D8488;
$color-text-label-input: #6B6B6B;
$color-text-modal-operator: #70797E;
$color-text-title-dark-gray: #6B7177;
$color-text-label-input-fiel: #AFB4B8;

$color-bg-buton-quternary-gradiant1: #F99A1c;
$color-bg-buton-quternary-gradiant2: #FF9307;

$shadow-create-delivery-company: #443426;

$color-dark-title: #353a3d; // Color para títulos
$color-general-text: #707B84; // Color de textos generales
$color-general-disabled: #D6DBDF; // Color se usa para los elementos inactivos, descativados o no editables
$color-general-placeholder: #A2AAAC; // Color se usa para Textos de apoyo o captions

$color-background-error: #F0000033;
$color-background-hover-menu: #F087001A;

$color-line: #E1E1E1;
$color-background-mx: #CED9E0;
$color-background-mx: #CED9E0;
$color-background-hover: #CED9E0;
$color-text-profile-mx: #535A5F;

$color-border-light: #C9D6DD;
