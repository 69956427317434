@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

.content-terms-and-conditions{
  display: flex;
  flex-direction: column;
  align-items: center;

  p{
    margin: 0 20px;
    max-height: 35vh;
    overflow: auto;
  }

  .container-checkbox-and-label{
    max-width: 400px;
    margin-top: 20px;
    .text-checkbox{
      font-size: 14px;
      span{ display: block; }
    }
  }
}

.modal-success-or-error-redeem-points{
  min-width: 450px;

  .container-content-header{
    margin: auto;
    width: 250px;
    text-align: center;
  }

  button {
    box-shadow: none;
  }
}

.paragraph-modal-redeem-point-section{
  max-width: 200px;
  text-align: left;

  &.more-width{
    max-width: 380px;
  }
}

.modal-product-or-service-details{
  .carousel-images-product-or-service-details{
    .container-content-carousel{
      img{
        border-radius: 4px;
        background-color: $color-light;
        object-fit: cover;
      }
    }
  }

  .container-text-product-or-service-details{
    width: 500px;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    p{ margin: 8px 0 }

    div:last-child{
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button{
        max-width: 200px;
      }
    }
  }
}

.modal-carrier-service-redeem-points{
  .content-modal-carrier-service{
    & > img{
      width: 100px;
      height: 100px;
      margin: 0 30px;
      border-radius: 4px;
    }

    .container-text-carrier-service{
      width: 500px;
      max-width: 50vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      p{ margin: 8px 0 }

      div:last-child{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
          max-width: 200px;

          &:last-child{
            max-width: 250px;
          }
        }
      }
    }
  }
}

.bold{
  font-family: "Heebo-Medium", sans-serif;
}

@media only screen and (min-width: 1024px) {
  .modal-product-or-service-details{
    max-width: none !important;
    .container-content-header{
      h1{ display: none }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .content-terms-and-conditions{ p{ margin: 0 } }

  .modal-success-or-error-redeem-points{ min-width: 90% }

  .paragraph-modal-redeem-point-section{ max-width: 100% }

  .modal-product-or-service-details{
    .container-text-product-or-service-details{
      h1{ display: none }
    }
    .modal-children{
      padding: 0;

      .modal-children-header{
        padding: 20px;
        box-sizing: border-box;
      }

      .modal-children-body{
        .content-modal-product-or-service-details{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .container-images-product-or-service-details{
            margin: 0 40px;
            width: calc(100% - 80px) !important;
            box-sizing: border-box;

            .content-images-product-or-service-details{
              margin: 0 auto;
              overflow: hidden;
            }
          }

          .container-text-product-or-service-details{
            width: auto;
            max-width: none;
            max-height: calc(65vh - 240px);
            padding: 0 20px;
            margin-bottom: 20px;
            overflow-y: auto;

            div:nth-child(4){
              flex-direction: column;
              align-items: flex-start;
              p:last-child{
                width: 100%;
                text-align: center;
                font-size: 16px;
              }
            }

            div:last-child{
              margin-bottom: 20px;
              flex-direction: column-reverse;
              align-items: center;
              justify-content: space-between;

              button{
                max-width: 95%;
                margin: 8px 0;

                &:first-child{
                  max-width: 85%;
                }
              }
            }
          }
        }
      }
    }
  }
}
