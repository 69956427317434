@import '../../../themes/colors.scss';
@import '../../../themes/fonts.scss';

#first-login{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container-text__first-login{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text__first-login{
      margin: 5px 0;
    }
  }

  .button-and-guide-man{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
      max-width: 245px;
    }

    button{
      margin-top: -10px;
      width: 100%;
    }
  }

  &.mobile-mode{
    .container-text__first-login{
      width: 80%;
    }

    .button-and-guide-man{
      button{
        width: 90%;
      }
    }
  }

  &.desktop-mode{
    .button-and-guide-man{
      button{
        max-width: 400px;
      }
    }
  }
}

#container-modal__input-password{
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  img.icon-close{
    position: absolute;
    height: 18px;
    top: 20px;
    right: 20px;
    cursor: pointer;

    &:hover{
      transform: scale(1.1);
    }
  }

  h1{
    max-width: 450px;
    text-align: center;
  }

  .input-password-first-login{
    max-width: 300px;
  }

  .container-checkbox-and-label{
    max-width: 300px;
    margin: 10px 0 20px;
  }

  button{
    max-width: 250px;
  }
}
