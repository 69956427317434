@import '../../../../themes/colors.scss';
@import '../../../../themes/fonts.scss';

.no-tickets-container{
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-content {
    align-items: center;
    justify-content: center;
    padding-right: 0% !important;
    margin-top: -10px !important;
    margin-bottom: -20px !important;
    p {
      font-size: 13px;

      a {
        font-size: 14px;
      }
    }
  }

  .title-no-tickets {
    color: $color-primary;
    font-family: Heebo-Medium, sans-serif;
  }

  .subtitle-no-tickets {
    margin-top: 4px;
  }

  .container-description-empty-state {
    width: 640px;

    .emptyDescription, .emptySupportDescription {
      max-width: 100%;
    }

  }

  .no-tickets-button {
    margin-top: 20px;
    max-width: 240px;
    width: 85%;
  }
}


@media only screen and (max-width: 1023px) {
  .no-tickets-container{
    padding: 0 12px;

    .subtitle-no-tickets {
      text-align: center;
      margin-bottom: 4px;
    }

    .container-description-empty-state {
      width: 100%;

      .emptyDescription, .emptySupportDescription {
        text-align: left;
      }
    }

    .no-tickets-button {
      margin: 12px 0;
    }
  }

}
