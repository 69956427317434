@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.empty-state-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .logo {
    width: 98px;
    height: 116px;
    padding-bottom: 10px;
  }

  .title {
    font-size: 22px;
    color: $color-text-label;
    padding-bottom: 15px;
    text-align: center;
    font-family: 'Ubuntu-MediumItalic', sans-serif;
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    color: $color-general-text;
    font-family: 'Heebo-Regular', sans-serif;
  }

  .button {
    height: 38px;
    width: 172px;
  }
}
