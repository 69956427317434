@import "../../../../themes/colors.scss";

#ticket-details {
  width: calc(100% - 25px);
  height: calc(100% - 30px);
  background-color: $color-light;
  border-radius: 10px;
  margin-top: 15px;

  .top {
    min-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 18px;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      .button-block{
        .filter-content__button {
          display: none;
        }
      }
    }

    .right-mobile {
      display: none;
    }

    .arrow-back {
      width: 30px;
      height: 30px;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 50%;

       &.arrow-back {
       cursor: pointer;

          &:hover{
         background-color: rgba(255, 156, 25, .3);
         }
       }
    }

    .arrow-back-mobile {
      display: none;
    }

    .title {
      margin-left: 10px;
    }
 }

  .line {
    border: 1px solid $color-primary-variant;
  }

  .content-header{
    padding: 8px 39px;
    display: flex;
    justify-content: space-between;

    .header-text {
      width: 100%;
      display: flex;
      gap: 12px;
      .content-header__block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      word-wrap: normal;
      word-spacing: normal;
      align-items: flex-start;
      margin: 16px 0px 16px 0px;
      .title-field{
        width: 60%;

        .tooltip {
          .tooltip__content-overlay {
            &.top-rigth {
              top: 20px;
            }
          }
        }

        &.small-block {
          width: 100%;
          margin-right: 0px;
        }
        &.high-block {
          width: 100%;
          margin-left: 15px;
        }
        &.tooltip {
          display: flex;
          justify-content: flex-start;
        }

        .secondary-field {
          margin-left: 9px;
        }
      }
    }
    .content-header__block:nth-child(1) {
      height: 74px;
      width: 117px;
      background-color: #C2D0D94D;
      border-radius: 12px;
      padding-left: 1.3%;
      padding-right: 2.5%;
      padding-top: 1%;
      padding-bottom: 1%;
    }
    .content-header__block:nth-child(2) {
      background-color: #C2D0D94D;
      border-radius: 12px;
      padding-left: 0.2%;
      padding-right: 2.5%;
      padding-top: 1%;
      padding-bottom: 1%;
    }
    .content-header__block:nth-child(3) {
      margin-right: -80px;
      margin-left: 25px;
      padding-top: 0.5%;
      padding-bottom:0.5%;
      width: 30%;
    }
    .content-header__block:nth-child(4) {
      padding-top: 1%;
      padding-bottom: 1%;
    }
    }

    .button-block {
      margin: 26px 0px 12px 0;
      .filter-content__button {
        width: 25% !important;
        display: flex;
      }
    }


    .buttons-filter {
      display: flex;
      max-width: 85%;
      justify-content: space-between;
      align-items: center;
      margin: 0 24px;
      position: relative;

      &__position {
        display: flex;
        align-items: center;
      }

      .select-content {
        display: flex;

        .select-button {
          margin-right: 15px;
        }
      }

      .next-collection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 20px;
        right: -60px;
        width: 238px;
        height: 68px;
        box-shadow: 0 5px 15px #23292E26;
        border-radius: 10px;
        padding: 0 15px;

        &__tooltip {
          display: flex;
          justify-content: space-between;

          .text-overlay {
            .span {
              color: $color-dark;
              font-weight: bold;
              display: inline-flex;
              margin-right: 3px;
            }

            .link {
              color: $color-accent;
              text-decoration: underline;
              display: block;
              font-family: Heebo-Regular, serif;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .shipment-list-wrapper {
    height: calc(100% - 216px);
  }
  .message-collection-desktop {
    width: 32em;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    margin-left: 24px;

    p {
      font-size: 16px;
    }

    span {
      color: $color-primary-hover-download-button;
      cursor: pointer;
      font-weight: 800;
    }
  }
}

@media (max-width: 1024px) {
  #ticket-details {
    .top{
      min-width: 240px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 2px;

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .button-block{
          .filter-content__button {
            display: flex !important;
            width: 10px !important;
          }
        }
      }
    }

    .line{
      z-index: 1 !important;
      position: relative;
    }

    .content-header{
      padding: 8px 16px;
      overflow-x: auto;
      z-index: 2 !important;
      position: relative;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      .header-text {
        width: auto;

        .content-header__block {
          width: auto;
          .title-field {
            min-width: fit-content;

            &.small-block {
              min-width: 128px;
            }

            &.high-block {
              min-width: 320px;
            }

            &.user-data {
              min-width: 128px;
            }
          }
        }

        .content-header__block:nth-child(2) {
          width: 29%;
          border-radius: 12px;
          padding-left: 0.2%;
          padding-right: 0px;
          padding-top: 1%;
          padding-bottom: 1%;
        }

        .content-header__block:nth-child(3) {
          width: 25%;
          margin-right: -105px;
        }
        .content-header__block:nth-child(4) {
          width: 30%;
        }
      }
      .button-block {
        .filter-content__button {
          display: none;
        }
      }
    }
    .message-collection-desktop {
      display: none;
    }
    .message-collection-mobile {
      width: 100%;


      p {
        font-size: 16px;
      }

      span {
        color: $color-primary-hover-download-button;
        cursor: pointer;
        font-weight: 800;
      }
    }
  }
}
@media (1025px <= width <= 1213px) {
  #ticket-details {
    .top{
      min-width: 290px;
      width: 98%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 2px;

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .button-block{
          .filter-content__button {
            display: flex !important;
            width: 10px !important;
          }
        }
      }
    }

    .content-header{
      padding: 8px 0px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      .header-text {
        margin-left: 15px;
        width: auto;
        display: flex;
        justify-content: flex-start;

        .content-header__block {
          width: auto;
        }
        .content-header__block:nth-child(1) {
          height: 84px;
          width: 130px;
        }
        .content-header__block:nth-child(2) {
          height: 84px;
          width: 260px;
          padding-left: 0.2%;
          padding-top: 1%;
          padding-bottom: 1%;
          padding-right: 0px;
        }
        .content-header__block:nth-child(3) {
          margin-left: 5px;
        }
        .content-header__block:nth-child(4) {
          margin-right: -140px;
        }
      }

      .button-block {
        .filter-content__button {
          display: block;
        }
      }
    }
  }
}
@media (min-width: 1025px) {
  .message-collection-mobile {
    display: none;
  }
}
