@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.paginationComponent{
  position: relative;
  display: flex;
  align-items: center;

  .paginationState{
    font-family: 'Heebo-Regular', sans-serif;
    font-size: 14px;
    padding: 6px 10px;
    margin: 0;
    border-radius: 4px;
    min-width: fit-content;

    &:hover{
      cursor: pointer;
      color: $color-accent;
      background-color: $color-background;
    }
  }

  .filterSelect{
    position: absolute;
    top: 36px;
    display: flex;
    flex-direction: column;
    z-index: 500;
    background-color: white;
    border-radius: 4px;
    padding-bottom: 4px;
    box-shadow: 0px 3px 6px #00000029;

    *{
      margin: 2px 0;
      padding: 2px 8px;
      height: 21px;
      overflow: hidden;
    }

    .filterTitle{
      font-family: 'Heebo-Medium', sans-serif;
      font-size: 14px;
      color: $color-dark-title;
      width: max-width;
      text-align: center;
      border-bottom: 1px solid #b3b3b3;
    }

    .filterItem{
      font-family: 'Heebo-Regular', sans-serif;
      font-size: 14px;
      color: $color-general-text;
      width: max-width;
      text-align: center;

      &.selected{ font-family: 'Heebo-Bold', sans-serif  }
      &:not(.selected){ cursor: pointer }
      &:hover:not(.selected){ background-color: #EEF0F2 }
    }

    &:not(.visible){
      padding-bottom: 0;

      *{
        margin: 0px 0;
        padding: 0px 8px;
        height: 0;
        border-bottom: 0;
      }
    }
  }

  .pagination{
    min-width: 16px;
    min-height: 16px;
    padding: 6px;
    border-radius: 50%;
    margin: 0 2px;

    &.back{ transform: rotate(180deg) }
    &:hover:not(.disabled){
      cursor: pointer;
      background-color: rgba(255, 156, 25, .3);
      path{ fill: $color-primary-variant !important }
    }
    &.disabled path{ fill: #818181 !important }
  }
}


