@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.bankData-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 72%;


  .content-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .title-info {
        font-size: 16px;
        color: $color-empty-state-text;
        font-family: "Heebo-Medium", sans-serif;

        span {
          cursor: pointer;
          font-size: 14px;
          font-family: "Heebo-Regular", sans-serif;
        }
      }
      .subtitle-info {
        font-size: 16px;
        margin-right: 10px;
        color: $color-empty-state-text;
        font-family: "Heebo-Regular", sans-serif;
      }
    }

    .account-number {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        flex-direction: row;

        .account-type {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 76px;
          height: 22px;
          font-size: 12px;
          border-radius: 25px;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: rgba(183, 188, 193, 0.3);
        }
      }
    }
  }

  .main-title {
    display: flex;
    margin: 0 15px;
    font-size: 14px;
    text-align: start;
    color: $color-empty-state-text;
    font-family: "Heebo-Regular", sans-serif;

    .title-interactive {
      display: inline;
      width: auto;
      margin: 0 5px;
      height: 20px;
      font-size: 14px;
      cursor: pointer;
      font-family: "Heebo-Bold", sans-serif;
      color: $color-primary-hover-download-button;
      background: none;
      border: none;
      padding: 0;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .bankData-container {
    .content-info {
      margin: 0;
    }
  }
}
