@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.notificationsIcon {
  position: relative;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    background: rgba(240, 136, 0, 0.464);
    border-radius: 30px;
    height: 30px;
    width: 30px;
    transition-duration: 0.2s;
  }

  img {
    height: 24px;
    width: 24px;

    &.animationBell {
      animation: animationBell 3000ms
    }
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    background: red;
    font-family: 'Heebo-Medium',
    sans-serif;
    font-size: 14px;

    &.positionOfNumbers-bottom {
      position: absolute;
      top: 8px;
      left: 13px;
    }
  }

  &.mobile-mode {
    position: static;
    img {
      position: absolute;
      left: 62%;
      top: 24px;

      .animationBell {
        position: absolute;
        top: 22px;
        left: 62%;
      }
  }
    .positionOfNumbers-top {
      top: 28px;
      left: 65%;
    }
  }
}

@keyframes animationBell {
  0% {
    transform: rotate(0deg)
  }

  8% {
    transform: rotate(0deg)
  }

  12% {
    transform: rotate(42deg)
  }

  16% {
    transform: rotate(-35deg)
  }

  20% {
    transform: rotate(0deg)
  }

  23% {
    transform: rotate(28deg)
  }

  26% {
    transform: rotate(-20deg)
  }

  29% {
    transform: rotate(0deg)
  }

  31% {
    transform: rotate(16deg)
  }

  33% {
    transform: rotate(-12deg)
  }

  35% {
    transform: rotate(0deg)
  }

  37% {
    transform: rotate(-6deg)
  }

  39% {
    transform: rotate(0deg)
  }
}
