@import "../../../../../themes/fonts";
@import "../../../../../themes/colors";

#delivery-info__sending-details {
  overflow-y: scroll;
  height: 100%;

  .delivery-info__sending-details {
    width: 72%;
    margin: 0 auto;

    .shipping-info__sending-details {
      display: flex;
      justify-content: space-between;
    }
  }

  .buttons {
    display: flex;
    max-width: 240px;
    margin: 49px auto 20px auto;
  }

  &.mobile-mode {
    height: 100%;

    .delivery-info__sending-details {
      width: 94%;
      padding: 0 15px;
      box-sizing: border-box;
      overflow: auto;
      max-height: -webkit-fill-available;

      .delivery-company-collection-service__details {
        .details-prices {
          width: max-content;
          margin-bottom: 40px;
        }
      }
      .shipping-info__sending-details {
        flex-direction: column;
      }

      .shippin-info-buttons__sendings-details {
        .buttons {
          width: 100%;
        }
      }
    }
  }
}

.modal-product-type-list {
  padding: 15px;
  text-align: center;
  width: 98%;
  max-width: 420px !important;
  height: 82%;
  max-height: 644px;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    .title-center {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .italic {
        font-family: "Ubuntu-MediumItalic", sans-serif;
        font-size: 20px;
        width: 100%;
      }

      .close {
        mask: url(../../../../../assets/images/close.svg) no-repeat center / contain;
        -webkit-mask: url(../../../../../assets/images/close.svg) no-repeat center / contain;
        cursor: pointer;
        background-color: $color-primary-variant;
        width: 13px;
        height: 13px;
        padding-left: 30px;
        padding-top: 1px;
      }
    }
  }

  .comments {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    span {
      margin-left: 2px;

      :first-child {
        margin-left: 0;
      }
    }

    .emphasis {
      font-family: "Heebo-Regular", sans-serif;
      color: $color-header-gray;
      font-size: 14px;
    }

    .tres-text {
      font-family: "Heebo-Medium", sans-serif;
    }
  }

  .product-type-list {
    padding: 0 80px;

    .text-checkbox {
      color: $color-header-gray;
    }

    .change-at-font-family {
      .text-checkbox {
        font-family: "Heebo-Medium", sans-serif;
      }
    }
  }

  .buttons {
    max-width: 240px;
    margin: 20px auto 0  auto;
    width: 100%;

    button {
      margin: 10px 0;
      height: 42px;
      min-height: 42px;
    }
  }

  &.mobile-mode {
    .product-type-list {
      padding: 0;
      width: fit-content;
      margin: 0 auto;

      .container-checkbox-and-label {
        margin: 12px 0;
      }
    }
  }
}

.text-end {
  text-align: end;
  width: 100%;
}
