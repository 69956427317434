@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

.page-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 19px;
}

.modal__notifications-info .body__notifications-info .page-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
}

.modal__notifications-info .body__notifications-info .page-indicator.selected {
  background-color: $color-bg-buton-quternary-gradiant1;
}

.modal__notifications-info {
  width: 617px;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-evenly;

  .header__notifications-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 23px;

    .left,
    .right {
      margin-right: 15px;
      margin-top: 10px;
      height: 62px;
      width: 10px;

      img {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
  }

  .body__notifications-info {
    width: 610px;
    height: calc(100% - 80px);
    top: 100px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .carousel-notifications-info-list {
      width: 92%;
      height: 98%;
      display: flex;
      align-items: center;
      justify-content: center;

      .container-content-carousel {
        width: 100%;
        overflow: hidden;

        &>div {
          display: flexbox !important;
          align-items: flex-start !important;
          justify-content: flex-start !important;
          transition: all 200ms ease;
          margin-left: 0px;
        }

        .page-Container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0px !important;

          .container-notifications {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 274px;
            margin: 0;
            margin-right: 7px;
            margin-left: 1px;
            justify-content: center;
            align-items: center;

            .infoCollectionType {

              align-items: center;
              justify-content: center;

              span {
                height: 21px;
                display: block;
              }
            }

            .infoState {
              align-items: center;
              justify-content: center;
              letter-spacing: 0px;
              word-spacing: 0px;
              white-space: nowrap;
            }

            .infoPrice {
              align-items: center;
              justify-content: right;

              span {
                color: $color-bg-buton-quternary-gradiant1;
              }
            }

            .notificationImage {
              max-width: 237px;
              max-height: 100%;
            }
          }
        }
      }
    }

    .page-indicator {
      margin-top: -10px;
      margin-bottom: 9px;
      width: 12px;
      height: 12px;
      margin-left: 9px;
      margin-right: 9px;
    }
  }

  &.mobile-mode {
    height: 600px;
    z-index: 2;
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    overflow: hidden;
    width: 350px;
    flex-direction: column;
    justify-content: space-around;

    .header__notifications-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .left,
      .right {
        margin-right: 50px;
        height: 10px;
        width: 10px;

        img {
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
      }
    }

    .body__notifications-info {
      width: 100%;
      height: calc(100% - 60px);
      top: 100px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 1;

      .carousel-notifications-info-list {
        width: 86%;
        height: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;

        .container-content-carousel {
          margin-left: 0px !important;

          .container-notifications {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin-left: 12px;
            margin-right: 63px;
            width: 294px;
            justify-content: space-evenly;
            align-items: center;

            .infoCollectionType {

              align-items: center;
              justify-content: center;

              span {
                height: 21px;
                display: block;
              }
            }

            .infoState {
              align-items: center;
              justify-content: center;
              white-space: nowrap;
            }

            .infoPrice {
              align-items: center;
              justify-content: right;

              span {
                color: $color-bg-buton-quternary-gradiant1;
              }
            }

            .notificationImage {
              height: 450px;
              width: 240px;
            }
          }

          .container-notifications:nth-child(3) {
            margin-left: 28px;
            margin-right: 97px;
          }

          .container-notifications:nth-child(5) {
            margin-left: 44px;
            margin-right: 96px;
          }

          .container-notifications:nth-child(7) {
            margin-left: 45px;
            margin-right: 108px;
          }
        }
      }

      .page-indicator {
        display: flex;
        flex-direction: column;
        margin-top: -10px;
        margin-bottom: 5px;
        width: 12px;
        height: 12px;
        margin-left: 9px;
        margin-right: 9px;
      }
    }
  }

}
