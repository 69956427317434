@import '../../../themes/fonts.scss';
@import '../../../themes/colors.scss';

.delivery-confirmation-modal{
  .text-container {
    max-width: 480px;

    .header-quote-gray {
      padding: 0 68px;
      display: block;

      p {
        color: $color-header-gray;
      }

      span {
        &.relevant {
          font-family: "Heebo-Bold", sans-serif;
          color: $color-header-gray;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .delivery-confirmation-modal{
    .text-container {
      .header-quote-gray {
        padding: 0 34px;
      }
    }
  }
}
