@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.container-button-international {

  .description-international {
    width: 283px;
    background-color: #ffffff;
    font-family: "Heebo-Regular", serif;
    color: $color-primary-variant;
    padding: 8px;
    position: fixed;
  }

  .flexbox-container-international {
    display: flex;
    width: 80%;

    .button-international {
      width: 48px;
      height: 48px;
      padding: 3%;
      font-family: "Heebo-Regular", serif;
      background-color: $color-white;
      border-radius: 100%;

      p {
        color: $color-accent-variant;
        font-size: 18px;
        font-family: "Ubuntu-MediumItalic", serif;
      }

      img {
        border-radius: 100%;
        border: 2px #009AF0 solid;
        height: 36px;
        padding: 8px 8px;
      }

      img:hover {
        background-color: #009AF033;
      }
    }


    .hidden-tooltip-international {
      visibility: hidden;
    }

    img {
      cursor: pointer;
    }

    .right-image-international {
      background-color: #FFFFFF;
      width: 340px;
      height: 112px;
      box-shadow: 8px 6px 16px rgba(0, 0, 0, 0.1607843137);
      border-radius: 15px;
      opacity: 1;
      z-index: 9999;
      margin-top: -5.3rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-left: 1.3rem;

      &.height-auto {
        height: 54px;
        margin-top: 0.0rem;
      }


      .hover-container-set {
        padding: 18px 4px 12px 18px;

        &:hover {
          width: 212px;
          border-radius: 3px
        }

        &.hidden-element {
          visibility: hidden;
        }
      }

      .container-set {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 10px;
        cursor: pointer;

        .image-container {
          display: block;

          img {
            width: 16px;
          }
        }

        .left {
          margin-left: -2px;
        }

        .right {
          position: absolute;
          margin-left: 50%;

          &:hover {
            mask: url('../../assets/images/arrow-black.svg');
            background-color: black;
          }
        }
      }

      hr {
        background-color: $color-border-light;
        width: 90%;
        margin: 0 auto;
        border: 0.1em solid $color-border-light;

        &.hidden-element {
          visibility: hidden;
        }
      }
    }

    .title-contact-international {
      font-family: "Ubuntu-Regular", serif;
      font-size: 16px;

      &:hover {
        color:$color-dark-title;
        font-weight: 700;
      }
    }

    .title-contact-international-under {
      font-family: "Heebo-Regular", serif;
      font-size: 15px;

      &:hover {
        color:$color-dark-title;
        font-weight: 700;
      }
    }

    .z-index-international {
      z-index: 9999;
      border-radius: 100%;      
      position: relative;      

      img {
        width: 48%;
        height: 48%;
      }
    }

    .right-image-tooltip {
      margin: 12px;
      position: relative;
      left: 10px;

      .comment-connector {
        position: absolute;
        width: 0;
        height: 5px;
        background-color: $color-white;
        border-left: 5px solid $color-white;;
        border-right: 5px solid $color-white;;
        border-bottom: 3px solid $color-white;;
        right: -6px;
        transform: rotate(45deg);
        top: 1px;
        border-top: 5px solid $color-white;;
      }

      .contact {
        position: absolute;
        background: $color-white;
        border-radius: 5px;
        width: 135px;
        height: 30px;
        box-shadow: 0 0 11px 1px rgba(35, 41, 46, 0.1490196078);
        padding: 1px 10px;
        text-align: center;
        z-index: 9999;

        .title-contact {
          left: 22px;
          color: $color-general-text;
          position: absolute;
          top: 4.5px;
          font-family: 'Heebo-regular', serif;
        }
      }
    }
  }
}
