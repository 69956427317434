.whatsapp-icon-container {
  position: absolute;
  top: 56px;
  z-index: 1000;

  &:hover .right-image-tooltip_wa {
    opacity: 1;
    visibility: visible;
    width: 136px;
    height: 50px;
    bottom: -10px;
  }
}

.whatsapp-icon {
  width: 48px;
  height: 48px;
  background-color: #25D366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-image: url('../../assets/images/whatsapp withe.svg');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
}

.right-image-tooltip_wa {
  position: absolute;
  bottom: 6px;
  left: 70px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  .comment-connector_wa {
    position: absolute;
    width: 0;
    height: 5px;
    background-color: #FFFFFF;
    border-left: 5px solid #FFFFFF;
    border-right: 5px solid #FFFFFF;
    border-bottom: 3px solid #FFFFFF;
    left: -3px;
    transform: rotate(45deg);
    top: 2px;
    border-top: 5px solid #FFFFFF;
  }

  .contact_wa {
    background: white;
    border-radius: 5px;
    width: 136px;
    height: 50px;
    box-shadow: 0 0 11px 1px rgba(35, 41, 46, 0.15);
    padding: 1px 10px;
    text-align: left;
    align-content: center;

    .title-contact_wa {
      color: #707B84;
      font-family: 'Heebo-regular', serif;      
      font-size: 15px;
    }
  }
}
