@import '../../themes/colors.scss';
@import '../../themes/fonts.scss';

*{
  transition: all 200ms ease;
}

.tooltip{
  position: relative;
  cursor: pointer;

  .tooltip-area {
    .icon-hover {
      display: none;
    }
  }

  .tooltip-area:hover {
    .icon {
      display: none;
    }
    .icon-hover {
      display: block;
    }
  }

  img {
    height: 20px;
    width: 20px;
    margin: 0 5px;

    &.cursor-pointer{
      cursor: pointer;
    }
  }

  .tooltip__content-overlay{
    position: absolute;
    width: 200px;
    height: auto;
    padding: 20px;
    background-color: $color-light;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    box-shadow: 0 12px 12px -4px rgba(108, 152, 181, 0.5);
    z-index: 1;

    &.top-left, &.top-middle{
      top: 0;
      left: 0;
    }

    &.top-rigth{
      top: 0;
      right: 0;
    }

    &.bottom-left{
      bottom: 0;
      left: 0;
    }

    &.bottom-rigth{
      bottom: 0px;
      right: 0px;

      &::after {
        content: "";
        position: absolute;
        visibility: hidden;
        margin-left: -1px;
        line-height: 10px;
        border-color: #fff transparent transparent;
        border-style: solid;
        border-width: 20px 2px 0px 21px;
      }
    }

    &.bottom-middle{
      bottom: 27px;
      right: 0;
    }

    &.top-middle{
      top: 20px;
      left: 0;
    }

    .tooltip__title{
      font-family: 'Ubuntu-MediumItalic', serif;
      font-size: 16px;
      color: $color-text-label;
    }

    .tooltip__text{
      font-family: 'Heebo-Regular', serif;
      font-size: 14px;
      color: $color-text-value;
      margin-top: 10px;
    }

    .tooltip__link-text {
      font-family: 'Heebo-Regular', serif;
      font-size: 14px;
      color: $color-accent;
      margin-top: 10px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &:hover > .tooltip__content-overlay, .tooltip__content-overlay:hover{
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (min-width: 375px) and (max-width: 800px) {
  .tooltip {
    .tooltip__content-overlay {
      &.top-middle{
        top: 24px;
        left: -189px;
        z-index: 99;
      }
    }
  }
}
