@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

.changeOrCreatePassword{
  .containerHeader{
    display: flex;
    align-items: center;
    height: 100%;

    .arrowBack{ margin-right: 12px }
    h1{ width: max-content }
  }

  .containerBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    overflow-y: auto;

    section{
      height: 300px;
      width: 100%;
      max-width: 640px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label{
        width: 280px;
        &.fullWidth{
          width: 100%;
          .container__input-general{ width: 280px }
          .container-lines__input-general{ width: 280px }
        }
      }

      .changeColor { font-size: 15px }
      .orange {
        width: 29px;
        height: 22px;
        background-color: #B0B9C1;
        mask: url(../../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;
        -webkit-mask: url(../../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;

        &.focusOrange {
          background-color: $color-primary-variant;
        }
      }

      .orangeOff {
        width: 29px;
        height: 22px;
        background-color: #B0B9C1;
        mask: url(../../../assets/images/icon-visibility-off-color-gray.svg) no-repeat center / contain;
        -webkit-mask: url(../../../assets/images/icon-visibility-off-color-gray.svg) no-repeat center / contain;

        &.focusOrange {
          background-color: $color-primary-variant;
        }
      }
    }

    .contentButtons{
      width: 80%;
      margin: 12px auto 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      button{
        width: 264px;
        &.buttonCancel{ width: 200px }
      }
    }
  }
}
