@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;

  &.two {
    .div {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }

  &.three {
    .div {
      -ms-flex: 33%;
      flex: 33%;
      max-width: 33%;
    }
  }

  &.four {
    .div {
      -ms-flex: 25%;
      flex: 25%;
      max-width: 25%;
    }
  }

  &.five {
    .div {
      -ms-flex: 20%;
      flex: 20%;
      max-width: 20%;
    }
  }

  &.rowTwoImportant {
    -ms-flex: 100% !important;
    flex: 100% !important;
    max-width: 100% !important;

    div:first-child {
      -ms-flex: 22%;
      flex: 22%;
      max-width: 22%;
    }

    div:last-child {
      -ms-flex: 78%;
      flex: 78%;
      max-width: 78%;

      div {
        max-width: 100%;
        font-family: Heebo-Regular;
        color: $color-text-value;
      }
    }
  }

  &.rowThreeImportant {
    -ms-flex: 100% !important;
    flex: 100% !important;
    max-width: 100% !important;

    &.margins {
      margin-top: 9px;
      margin-bottom: 15px;
    }

    div {
      -ms-flex: 34%;
      flex: 34%;
      max-width: 34%;
    }

    .deliveryCompanyName {
      -ms-flex: 37% !important;
      flex: 37% !important;
      max-width: 37% !important;
    }

    div:first-child {
      -ms-flex: 26%;
      flex: 26%;
      max-width: 26%;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .row {
    &.two,
    &.three,
    &.four,
    &.five {
      .div {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .row {
    &.two,
    &.three,
    &.four,
    &.five {
      .div {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
      }
    }
  }
}
