@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";

#modal-bank-account {
  width: 464px;
  display: flex;
  flex-direction: column;
  align-items: center;


  .message-container {
    width: 350px;

    .color-dark {
      color: $color-empty-state-text;
    }

    .f14 {
      font-size: 14px;
    }

    .hyper-link {
      color: $color-accent;

      &:hover {
        cursor: pointer;
      }

      p {
        width: inherit;
        height: inherit;
      }
    }
  }

  .button-container {
    width: 270px;

    button {
      margin: 24px 0;

      &:last-child {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  #modal-bank-account {
    width: 100%;

    .message-container {
      width: fit-content;
    }
  }
}
