@import '../../../../themes/fonts.scss';
@import '../../../../themes/colors.scss';
@import '../../../../themes/general.scss';

#multiple-shippings__excel-errors{
  height: 100%;

  .content-top{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-bottom{
    height: calc(100% - 80px);
    width: 85%;
    margin-left: 7.5%;

    &.full-height{
      height: 100%;
    }

    .container-list__excel-errors{
      height: calc(100% - 100px);
      overflow-y: auto;

      .item{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 10px 0;

        .field{
          font-weight: 500;
          margin-right: 5px;
          font-size: 14px !important;
          width: max-content !important;
        }

        .value{
          font-weight: 500;
          margin-right: 5px;
          font-size: 14px !important;
          max-width: calc(100% - 180px);
        }

        .dark{
          color: $color-dark-gray !important;
        }

        &:first-child{
          margin-top: 30px;
        }
      }

      hr{
        border: none;
        border-bottom: 1px solid $color-primary;
        width: 100%;
        margin: 30px 0;
      }
    }

    .container-buttons__content-bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100px;

      button{
        max-width: 250px;
      }
    }
  }
}
