@import "../../../themes/colors.scss";

#print_format_settings {
  margin-bottom: 32px;

  .headers {
    margin-top: 56px;

    .sub_title {
      margin-top: 0px;
    }

    .print-title {
      margin-top: 10px;
      display: block;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 360px));

    .company {
      margin-top: 24px;

      .title {
        margin-bottom: 8px;
        display: block;
      }

      .print-format-and-example {
        display: flex;

        .print-format-radio-button {
          width: fit-content;
        }

        .radio-button input {
          margin-left: 0px;
        }

        .image-container {
          display: flex;
          align-items: center;
          border-radius: 50%;
          padding: 5px;
          margin-left: 10px;

          .exmaple-image {
            width: 20px;
            height: 20px;
            mask: url(../../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;
            -webkit-mask: url(../../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;
            background-color: $color-accent-varian-gray;
          }

          &:hover {
            background-color: $color-blue-variant;
            cursor: pointer;

            .exmaple-image {
              background-color: $color-accent;
            }
          }
        }

      }

    }


  }
}

@media only screen and (max-width: 540px) {
  #print_format_settings {
    .wrapper {
      grid-template-columns: repeat(auto-fill, minmax(200px, 240px));
    }
  }
}
