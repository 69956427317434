@import '../../../../themes/fonts.scss';
@import '../../../../themes/colors.scss';
@import '../../../../themes/general.scss';

#multiple-shippings__upload-template{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-top{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;

    img{
      cursor: pointer;
    }
  }

  h2{
    text-align: center;
  }

  .content-bottom{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button{
      max-width: 350px;
    }
  }

  &.mobile-mode{
    .content-bottom{
      width: 85%;
    }
  }
}
