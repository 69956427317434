@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.createDirection {
  width: 494px;
  height: 534px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .centerTitleModal {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    .italic {
      font-size: 20px;
      font-family: "Ubuntu-MediumItalic", serif;
    }
  }

  .center {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .input-top {
      width: 280px;
      height: 80px;
      padding: 8px 0;
    }

    .input-mid {
      width: 280px;
      height: 80px;
      padding: 8px 0;
    }

    .input-bottom {
      width: 280px;
      height: 80px;
      padding: 8px 0;
    }
  }

  .buttonModal {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 72px;

    #cancelModal {
      width: 152px;
    }

    #saveModal {
      width: 206px;
    }

    #saveModalDelete {
      width: 196px;
      height: 40px;
      border: none;
      font-family: 'Ubuntu-MediumItalic', serif;
    }

    #cancelModalDelete {
      width: 212px;
      height: 34px;
    }
  }

  label {
    margin-top: 16px;
  }

  .iconClose {
    width: 13.18px;
    height: 13.18px;
    position: relative;
    mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
    cursor: pointer;
    background-color: $color-primary-variant;
    margin-left: 440px;
    margin-top: -10px;
  }

  #confirmSaveDirection {
    display: flex;
    height: 36px;
    width: 500px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

}

.confirmSaveDirectionCreate {
  display: flex;
  height: 391px;
  width: 563px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .iconClose2 {
    width: 13.18px;
    height: 13.18px;
    position: absolute;
    mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
    cursor: pointer;
    background-color: $color-primary-variant;
    margin-left: 498px;
    margin-top: -335px;
  }

  .contentConfirmAddresses {
    height: 391px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;

    .confirmAddressTitle {
      font-size: 20px;
      color: $color-dark;
      font-family: 'Ubuntu-BoldItalic', serif;
    }

    .confirmAddressParagraph {
      width: 440px;
      margin-bottom: -21px;

      p {
        font-size: 15px;
        color: $color-header-gray;
        font-family: 'Heebo-Regular', serif;

        span {
          font-size: 15px;
          font-family: 'Heebo-Bold', serif;
        }
      }
    }

    .confirmAddressImgContent {
      width: 440px;

      .inProcessContent {
        margin: 0 0 10px 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .inProcessImg {
          width: 102px;
          height: 21px;
          content: url("../../../../assets/images/tagInterInProcess.png");
        }

        .inProcessText {
          font-size: 15px;
          color: $color-header-gray;
          font-family: 'Heebo-Regular', serif;

          span {
            font-size: 15px;
            font-family: 'Heebo-Bold', serif;
          }
        }
      }

      .enabledContent {
        margin: 15px 0 0 5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .enabledImg {
          width: 102px;
          height: 21px;
          content: url("../../../../assets/images/tagInterEnabled.png");
        }

        .enabledText {
          margin-left: 25px;
          font-size: 15px;
          color: $color-header-gray;
          font-family: 'Heebo-Regular', serif;

          span {
            font-size: 15px;
            font-family: 'Heebo-Bold', serif;
          }
        }
      }
    }

    .confirmAddressButton {
      width: 216px;
    }
  }
}

.confirmation-created-address-modal {
  padding: 24px 80px;
}

@media only screen and (max-width: 800px) {
  .createDirection {
    width: 303.73px;
    height: 555px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .centerTitleModal {
      margin: 0;
      display: flex;
      justify-content: center;

      .italic {
        width: 220px;
        text-align: center;
        font-size: 20px;
        font-family: "Ubuntu-MediumItalic", serif;
      }
    }

    .center {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .input-top {
        width: 240.5px;
        height: 85px;
        padding: 4px 0;
      }

      .input-mid {
        width: 240.5px;
        height: 85px;
        padding: 4px 0;
      }

      .input-bottom {
        width: 240.5px;
        height: 85px;
        padding: 4px 0;
      }
    }

    .buttonModal {
      margin-top: 50px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      align-items: center;

      #cancelModal {
        margin-top: 20px;
        width: 206px;
        height: 42px;
      }

      #saveModal {
        width: 206px;
      }
    }

    label {
      margin-top: 16px;
    }

    .iconClose {
      width: 13.18px;
      height: 13.18px;
      position: absolute;
      mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
      cursor: pointer;
      margin-left: 270px;
      margin-bottom: 480px;
    }

  }

  .confirmSaveDirectionCreate {
    display: flex;
    height: 523.12px;
    width: 322.73px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .iconClose2 {
      margin-left: 264px;
      margin-top: -465px;
    }

    .contentConfirmAddresses {
      height: 523.12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;

      .confirmAddressTitle {
        font-size: 18px;
        margin-bottom: -15px;
        font-family: 'Ubuntu-MediumItalic', serif;
      }

      .confirmAddressParagraph {
        width: 260px;
        margin-bottom: -17px;

        p {
          font-size: 15px;
          color: $color-header-gray;
          font-family: 'Heebo-Regular', serif;

          span {
            font-size: 15px;
            font-family: 'Heebo-Bold', serif;
          }
        }
      }

      .confirmAddressImgContent {
        width: 260px;

        .inProcessContent {
          margin: 0 0 10px 5px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          .inProcessImg {
            width: 102px;
            height: 21px;
            content: url("../../../../assets/images/tagInterInProcess.png");
          }

          .inProcessTextMobile {
            margin: 10px 0 0 0;
            font-size: 15px;
            color: $color-header-gray;
            font-family: 'Heebo-Regular', serif;

            span {
              font-size: 15px;
              font-family: 'Heebo-Bold', serif;
            }
          }
        }

        .enabledContent {
          margin: 20px 0 0 5px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          .enabledImg {
            width: 102px;
            height: 21px;
            content: url("../../../../assets/images/tagInterEnabled.png");
          }

          .enabledTextMobile {
            margin: 10px 0 0 0;
            font-size: 15px;
            color: $color-header-gray;
            font-family: 'Heebo-Regular', serif;

            span {
              font-size: 15px;
              font-family: 'Heebo-Bold', serif;
            }
          }
        }
      }

      .confirmAddressButton {
        width: 216px;
      }
    }
  }

  .confirmation-created-address-modal {
    padding: 24px;
  }
}
