@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.sender-collection-service-discount {
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .title {
      color: $color-dark;
    }

    .information-prices {
      display: flex;
      flex-direction: column;

      .discount-price {
        display: flex;
        gap: 5px;

        &__without-amount {
          font-family: "Heebo-Medium", serif;
          color: $color-light-gray;
        }

        &__amount {
          color: #058B09;
          font-size: 14px;
          font-family: "Heebo-Medium", serif;
        }
      }

      .real-value {
        font-family: "Heebo-Regular", serif;
        text-decoration: line-through;
        color: $color-light-gray;
        font-size: 12px;
        text-align: right;
      }
    }
  }

  &__details-data {
    height: 100%;

    .data-list-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      .text, .value {
        color: $color-light-gray;
        font-size: 13px;
        font-family: "Heebo-Regular", serif;
      }

      .discount {
        font-size: 12px;
        color: #058B09;
        font-family: "Heebo-Regular", serif;
      }
    }

    .data-list-items:last-child {
      margin-bottom: 15px;
    }
  }
}
