@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

#create-account {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-content-modal {
  height: auto !important;
  flex-direction: column;

  .modal-header-main {
    text-align: center;
    font-size: 18px;
    font-family: "Ubuntu-MediumItalic", sans-serif;
  }

  .container-content-header {
    display: flex;
    margin: 0 auto;
    .modal-header { display: block}
  }

  .modal-register-email {
    max-width: 424px;
    font-family: "Heebo-Regular", sans-serif;

    .modal-text {
      display: block;
      &.email{ margin-bottom: 20px }
      b { color: $color-empty-state-text }
    }
  }
}
