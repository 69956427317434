@import '../../themes/colors.scss';
@import '../../themes/fonts.scss';

#loading-screen{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, .5);

  img{
    height: 120px;
    max-width: 80%;
    animation: float 2s ease-in-out infinite;
  }

  div {
    margin-top: 20px;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: 3px solid #eee;
    border-top: 3px solid #666;
    border-radius: 50%;
    animation: girar 2s ease-in-out infinite;
  }
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
