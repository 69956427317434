@import '../../../../themes/fonts.scss';
@import '../../../../themes/colors.scss';
@import '../../../../themes/general.scss';

.title-auto-save{
  font-family: "Ubuntu-MediumItalic", sans-serif;
  font-size: 12px;
  color: $color-blue;
  font-weight: 500;
  text-align: right;
}

.title-shipping-details{
  width: max-content;
}

.title-conflicts{
  font-family: "Heebo-Medium", sans-serif;
  font-size: 14px;
  color: $color-error;
}
