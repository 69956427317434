@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.sender-amount-container {
  border: 1px solid $color-general-placeholder;
  border-radius: 7px;
  padding: 7px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__text {
    font-family: "Heebo-Regular", sans-serif;
    font-size: 15px;
    color: $color-dark;
  }

  &__price {
    display: flex;
  }
}
