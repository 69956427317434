@import "../../../../themes/colors.scss";

#shipment-list-information {
  display: grid;
  grid-template-columns: 100%;
  height: 100%;

  .primary {
    overflow: hidden;
    position: relative;

    .title {
      margin-bottom: 30px;
    }

    .table-shipping-list {
      display: flex;
      flex-direction: column;
      z-index: 1;
      height: calc(100% - 4px);

      .header {
        padding: 13px 40px; //hacer una clase
        display: grid;
        grid-template-columns:
          0.5fr
          2fr
          2fr
          2fr
          2fr
          2fr
          2fr
          2fr;
        align-items: center;
        text-align: center;
        .right-side{
          text-align: right;
        }
      }

      .body {
        padding: 0px 40px;
        overflow: auto;
        height: calc(100% - 50px);

        &__content {
          display: grid;
          grid-template-columns:
          0.5fr
          2fr
          2fr
          2fr
          2fr
          2fr
          2fr
          2fr;
          align-items: center;
          justify-content: center;
          padding: 5px 0 5px 0px;
          overflow-y: visible;
          text-align: center;
          cursor: pointer;

          .right-side{
            text-align: right;
          }
        }

        &__content:hover {
          background-color: $color-background-hover-menu;
          border-radius: 5px;
        }

        img {
          width: 34px;
          height: 34px;
          min-width: 34px;
          max-width: 34px;
          min-height: 34px;
          max-height: 34px;
          border-radius: 4px;
        }
        .blue{
          color: $color-blue;
        }
        .red{
          color: $color-error
        }

      }
    }

    .table-shipping-list-mobile {
      display: none;
    }
  }

  .secondary {
    position: fixed;
    top: 80%;
    right: 5%;
    z-index: 1;

    .button-footer-list {
      width: 200px;
      margin-top: 14%;
    }
  }
}

// .my-collections-information__primary {
//   overflow: hidden !important;
// }

@media (max-width: 1023px) {
  #shipment-list-information {
    .primary {
      .table-shipping-list {
        .body {
          padding: 0px 24px;
          margin-top: 8px;
          height: calc(100% - 0px);

          &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
          }

          .body-content-mobile {
            padding: 14px 0;

            .row {
              display:  flex;
              align-items: center;
              padding: 2px 0;

              &.first {
                justify-content: space-between;
              }

              .separate {
                margin-left: 4px;
              }
            }
          }
        }
      }
    }

    .secondary {
      .button-footer-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20%;
        margin-left: 4%;
      }
    }
  }
}
