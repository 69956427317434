@import "../../themes/colors.scss";
@import '../../themes/fonts.scss';

$toggle-width: 34px;
$toggle-height: 14px;
$toggle-gutter: 5px;
$toggle-radius: 50%;
$toggle-control-speed: .25s;
$toggle-control-ease: ease-in;

// These are our computed variables
// change at your own risk.
$toggle-radius: calc(#{$toggle-height} / 2);
$toggle-control-size: calc(#{$toggle-height} + (#{$toggle-gutter} * 2));

.bodyChecBox {
  cursor: pointer;
}

.toggle-control {
  display: block;
  position: relative;
  padding-left: $toggle-width;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .control {
    background-color: $sw-color-background;

    &:after {
      background-color:$color-accent;
      left: 20px;
    }
  }

  .control {
    position: absolute;
    top: 5px;
    left: 0;
    height: $toggle-height;
    width: $toggle-width;
    border-radius: $toggle-radius;
    background-color: $sw-color-background;
    transition: background-color $toggle-control-speed $toggle-control-ease;

    &:after {
      content: "";
      position: absolute;
      left: -10px;
      top: -5px;
      width: $toggle-control-size;
      height: $toggle-control-size;
      border-radius: 50%;
      background: $color-surface;
      transition: left $toggle-control-speed $toggle-control-ease;
      box-shadow: #00000025 0px 0px 6px 2px;
    }
  }
}

.bodyChecBox {
 display: flex;

 .spanOperator {
  color: $colr-ligth-gray;
  font-size: 16px;
  font-family: Heebo-Regular;
  font-weight: normal;
  margin-right: 25px;
 }

 .chekecdText {
   color: $color-primary-variant;
 }
}
