@import '../../../themes/colors.scss';
@import '../../../themes/fonts.scss';

#validate-email{
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: auto;
  box-shadow: 0px 4px 12px -4px rgba(0,0,0,0.5);

  .title-validate-email{
    margin: 20px 0;
  }

  .container-paragraph__validate-email{
    margin: 10px 0;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button{
    margin: 20px 0;
    width: 85%;
  }

  .form__validate-email{
    max-width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    button{
      width: 100%;
    }
  }

  a{
    font-family: "Heebo-Regular", sans-serif;
    font-size: 18px;
    color: $color-accent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-anchor{
      cursor: initial;
      font-weight: initial;
    }
  }

  &.desktop-mode{
    .container-paragraph__validate-email{
      max-width: 350px;
      text-align: center;
    }

    .form__validate-email{
      max-width: 350px;
    }

    button{
      max-width: 300px;
    }
  }

  &.mobile-mode{
    .container-paragraph__validate-email{
      width: 65%;
      text-align: center;
    }

    .form__validate-email{
      width: 85%;

      button{
        width: 95%;
      }
    }
  }
}

.validate-email__modal-notification{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;

  .text-notification__validate-email{
    max-width: 350px;
    text-align: center;
    margin: 20px 0;

    &.email{
      color: $color-accent;
      margin: 0;
    }
  }

  button{
    max-width: 250px;
  }
}
