@import '../../themes/colors.scss';
@import '../../themes/fonts.scss';

.container-reset-password__desktop-mode{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;

  #reset-password{
    width: 400px !important;
    height: auto;
  }
}
