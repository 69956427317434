.pickup-tag-container {
  padding: 10px;
  width: fit-content;
  background: rgba(162, 170, 172, 0.3);
  height: .6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin: .6rem 0;

  span {
    font-size: .8rem;
    font-family: "Ubuntu-Regular", serif;
  }

  img {
    color: #5A6064;
    height: .8rem;
    width: .8rem;
    margin-right: 5px;
  }
}
