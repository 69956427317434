@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

.my-transporters-container {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  height: 90%;
  margin: 20px;
  box-sizing: border-box;
  overflow: auto;

  &.isMobile {
    width: calc(100% - 20px);
  }

  .my-transporter-switch-container {

    .container-title-transporters {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      height: 80px;
      margin: 0.5rem 6rem;

      &.isMobileDevice {
        margin: 1.5rem 1rem 3rem 1rem;
      }

      .paragraph-transporters-settings {
        font-size: 1em;
        color: #535A5F;
        text-align: left;
        margin-top: 1em;
      }
    }

    .switch-container-transporters {
      margin: 0 5rem;

      &.isMobile {
        height: 85%;
        margin: 1rem;
      }
    }
  }
}
