@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

* {
  transition: all 200ms ease;
}

#home-mobile {
  padding: 120px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cards {
    width: 300px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    div {
      margin: 5px 5px;

      span {
        font-size: 14px;
        color: $color-empty-state-text;
        font-family: "Heebo-Regular", sans-serif;
      }
    }
  }

  #section-list__home-mobile {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .count-unread {
      font-family: "Heebo-Medium";
      font-weight: unset;
      font-size: 15px;
      background-color: $color-error;
      color: $color-light;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 20px;
    }
  }

  .container-button-fixed {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.quote-shipping-options__modal {
  background-color: transparent !important;
  max-height: 50vh;
  height: 200px;
  min-height: 200px;
  width: 350px;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.express-shipping {
  background-color: transparent !important;
  max-height: 50vh;
  height: 300px;
  min-height: 200px;
  width: 350px;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#container-sections-mobile {
  position: absolute;
  width: 100%;
  min-height: 100%;
  padding: 120px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0.25))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
  z-index: 60;

  #content__sections-mobile {
    width: calc(100% - 40px);
    padding: 16px;
    box-sizing: border-box;
    margin: 0 20px 40px;
    background: $color-light;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Ubuntu-MediumItalic";
    font-size: 16px;
    color: $color-dark;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
