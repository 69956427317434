@import "../../../../../themes/fonts.scss";
@import "../../../../../themes/colors.scss";
@import "../../../../../themes/general.scss";

.container-arrow-back {
  padding: 10px;

  &:hover {
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(255, 156, 25, 0.3);
    transform: scale(1.05); /* Ajusta el tamaño en hover si deseas */

  }


}
#multiple-shippings__payment {
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  align-items: flex-start;
  padding: 40px 5% 0;
  box-sizing: border-box;
  background: transparent;

  .title-th {
    color: #353a3d;
    font-family: "Ubuntu-Medium", serif;
    font-size: 16px;
  }

  .delivery-companies-list-and-details-container {
    width: 60%;
    height: calc(100% - 40px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    table.delivery-companies-list-and-details {
      width: 90%;
      max-width: 600px;
      max-height: calc(100% - 200px);
      height: auto;
      overflow-y: auto;
      margin-left: 5%;
      margin-bottom: 20px;

      tbody {
        tr {
          &:hover,
          &.hover {
            background-color: initial;
            cursor: initial;
          }

          td {
            .delivery-company {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 50px;
                margin: 10px 0;
                border-radius: 5px;
              }

              span {
                text-align: left;
                margin-left: 10px;
                width: min-content;
              }
            }
          }

          .prices {
            display: flex;
            flex-direction: column;

            .value {
              font-size: 16px;
              color: $color-success-payments;
            }

            .real-cost {
              font-size: 14px;
              text-decoration: line-through;
              color: $color-header-gray;
            }
          }
        }
      }
    }

    .container__content-data-to-payment {

      .shipping-details__payment {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0 10px 5%;

        :first-child {
          margin-right: 16px;
          font-weight: bold;
          font-family: "Ubuntu-Medium", serif;
          color: #353a3d;
        }

        :nth-child(2) {
          font-weight: bold;
          font-family: "Ubuntu-Regular", serif;
          color: #353a3d;
        }
      }

      .notifications-settings {
        margin-top: 50px;
      }
    }
  }

  .cash {
    padding-top: 16px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .info {
      width: 100%;
      max-width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .label {
        font-family: "Heebo-Regular", serif;
        font-size: 14px;
        color: $color-dark;
      }

      .value {
        font-family: "Heebo-Medium", serif;
        font-size: 14px;

        &.blue {
          color: $color-blue;
        }

        &.red {
          color: $color-error;
        }
      }
    }

    button {
      max-width: 250px;
    }
  }

  &.mobile-mode {
    max-height: 100%;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    padding-bottom: 80px;
    box-sizing: border-box;

    .delivery-companies-list-and-details-container {
      width: 100%;
      overflow-y: unset;
      display: block;

      table.delivery-companies-list-and-details {
        width: 90%;
        height: auto;
      }

      .container__content-data-to-payment {
        .shipping-details__payment {
          justify-content: center;
        }
      }
    }

    .cash {
      width: 80%;
      margin-left: 0;
      margin-bottom: 20px;
    }

    .flotant-button__payment-multiple-shippings {
      position: fixed;
      right: auto;
      bottom: 40px;
      max-width: 250px;
    }
  }
}
