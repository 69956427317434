@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

.stepCreateAccount-lastStep {
  border-radius: 12px;
  background: $color-light;
  height: 463px;
  width: 428px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;

  .top {
    width: max-content;
    min-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }

  .bottom {
    min-width: 280px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container__input-all {
      width: 280px;
      margin-bottom: 12px;
    }

    .separator {
      margin-top: 6px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      hr {
        margin-top: 4px;
        width: 50%;
        border: 0;
        border-top: 1px solid $color-general-disabled;
      }

      span {
        font-family: "Ubuntu-MediumItalic", sans-serif;
        font-size: 18px;
        margin: 12px 12px;
      }
    }

    button {
      width: 264px;
      margin-top: 20px;
    }
  }
}
.stepCreateAccount {
  border-radius: 12px;
  background: $color-light;
  height: auto;
  width: 428px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;

  .top {
    width: max-content;
    min-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;

    .arrowBack,
    div {
      width: 30px;
      height: 30px;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 50%;

      &.arrowBack {
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 156, 25, 0.3);
        }
      }
    }
  }

  .containter-select-all .container-value-selected__select-general {
    width: 280px;
  }

  .bottom {
    min-width: 280px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container__input-all {
      width: 300px;
    }

    .selectUserToCreate {
      width: 280px;
      margin: 25px 0 18px 0;

      .container-options__select-general {
        width: 100%;
      }
    }

    .separator {
      margin-top: 6px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      hr {
        margin-top: 4px;
        width: 50%;
        border: 0;
        border-top: 1px solid $color-general-disabled;
      }

      span {
        font-family: "Ubuntu-MediumItalic", sans-serif;
        font-size: 18px;
        margin: 12px 12px;
      }
    }

    button {
      width: 250px;
      margin-top: 20px;
    }

    .redirectToLogin {
      margin: 12px 0;
      display: flex;
      font-size: 14px;
      align-items: center;
      color: $color-text-title-dark-gray;
      font-family: 'Heebo-Regular', sans-serif;

      .linkButton {
        margin: 0;
        width: 140px;
        font-family: "Heebo-Medium", sans-serif;
        color: $color-accent;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}

.termsAndConditionsCreateAccount {
  margin-top: 10px;
  width: 280px;

  .text-checkbox {
    margin-left: 8px;

    .terms {
      font-size: 14px;
      span {
        font-size: 14px;
        color: $color-accent;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .stepCreateAccount {
    width: calc(100% - 40px);

    .top {
      width: 100%;
      min-width: unset;
      padding: 24px 16px;
      box-sizing: border-box;
    }

    .bottom {
      width: 100%;
      min-width: unset;

      .container__input-all,
      .selectUserToCreate,
      .separator,
      button,
      .redirectToLogin {
        max-width: 90%;
      }
    }
  }

  .termsAndConditionsCreateAccount {
    max-width: 90%;
  }
}

@media only screen and (max-width: 340px) {
.stepCreateAccount {
      .containter-select-all .container-value-selected__select-general {
      width: 220px;
    }
}
}
