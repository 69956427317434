@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

.desktop-modal {
  max-width: 580px;
  max-height: 548px;
  width: 40%;
  height: auto;

  .onboarding-modal-children {
    .modal-children-body {
      margin: 0;
    }

    .btnNext {
      width: 240px;
    }
  }

  .header-close {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div.close{
      width: 16px;
      height: 16px;
      cursor: pointer;
      mask: url(../../assets/images/close.svg) no-repeat center / contain;
      -webkit-mask: url(../../assets/images/close.svg) no-repeat center / contain;
      cursor: pointer;
      background-color: $color-primary-variant;
      margin-top: 10px;
    }

    h1 {
      font-weight: 600;
    }
  }

  .img {
    width: 100%;
    margin: 25px auto;

    img {
      width: 100%;
    }
  }

  .text-container {
    width: 90%;
    margin: 20px auto;
  }

  .titleCustom {
    color: $color-primary-variant;
    text-align: center;
    margin: 20px 0;
    display: block;
    font-size: 22px;
  }

  .btnJoin {
    width: 176px;
    height: 40px;
    min-height: 40px;
    margin: 30px auto;
    margin-bottom: 0;
    color: black;
    background: transparent linear-gradient(114deg, $color-primary 0%, $color-primary 100%) 0% 0%
      no-repeat padding-box;
  }

  .content-buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

    .btnNoShowMore {
      max-width: 250px;

      &.hidden{
        visibility: hidden;
      }
    }
  }

  .new-color-gray {
    color: $new-color-gray-label;

    &.organice-paragraph {
      font-family: "Heebo-Regular";
      font-size: 14px;
      text-align: center;
      display: block;
      padding: 0px 50px;
    }

    span {
      color: $color-blue;
      cursor: pointer;
    }
  }

  .points {
    max-width: 152px;
    width: 85%;
    height: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 60px;

    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-gray-disabled;

      &.currentStep {
        background-color: $color-primary-variant;
      }
    }
  }
}

@media (max-width: 1023px) {
  .desktop-modal {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;

    .onboarding-modal-children {
      .modal-children-body {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: space-evenly;
      }

      .btnNext {
        width: 240px;
      }
    }
    .img {
      width: 82%;
    }
    .new-color-gray {
      &.organice-paragraph {
        font-family: "Heebo-Regular";
        padding: 0px;
        margin-top: 10px;
      }
    }

    .content-buttons{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-start;

      .btnNoShowMore {
        margin-top: 20px;
        max-width: 95% !important;
      }
    }
  }
}
