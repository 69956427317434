@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';
@import '../../themes/general.scss';

.container-shipping-history__section-desktop{
  border-radius: 12px;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &.full-container{
    width: 100%;
  }
}
