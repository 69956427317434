@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.modal__collection-service {
  width: 592px;
  height: 280px;
  padding: 20px;
  box-shadow: 0px 33px 22px -3px rgba(105,105,105,1);


  .header__collection-service {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: "Ubuntu-MediumItalic", sans-serif;
    justify-content: flex-end;
    margin-top: -4px;


    .title__header__collection-service {
      width: 100%;
      text-align: center;
    }

    .content-close-modal {
      width: 36px;
      height: 36px;

      &:hover {
        box-sizing: border-box;
        border-radius: 100%;
        cursor: pointer;
        background-color: rgba(255, 156, 25, 0.3);
      }
    }

    .rigth {
      width: 16px;
      height: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-primary-variant;
      mask: url(../../assets/images/close.svg) no-repeat center / contain;
      -webkit-mask: url(../../assets/images/close.svg) no-repeat center / contain;
      margin-left: 10px;
      margin-right: 4px;
      margin-top: 4px;
    }

    .close-collection-service-modal {
      height: 15px;
      width: 15px;
      mask: url(../../assets/images/close.svg) no-repeat center / contain;
      -webkit-mask: url(../../assets/images/close.svg) no-repeat center / contain;
      background-color: $color-primary-variant;
      cursor: pointer;
    }
  }

  .container-type-shipment {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 36px;
    gap: 31px;

    .title__header__collection-service {
      width: 100%;
      text-align: center;
      font-family: 'Ubuntu-MediumItalic', sans-serif;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .subtitle__header__collection-service {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    color: $color-empty-state-text;
    font-family: "Heebo-Regular", sans-serif;
    font-size: 16px;
  }
  .footer__collection-service {
    display: flex;
    width: 208px;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .adjust-image-normal {
      margin-top: 24px;
    }
  }
  .background-primary {
    border: $color-primary solid 1px;
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;

    &:hover {
      background-color: #F087001A;
    }

    &:active {
      border: $color-primary solid 2px;
    }
  }
  .background-secondary {
    border: $color-accent solid 1px;
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;

    &:hover {
      background-color: #009AF01A;
    }

    &:active {
      border: $color-accent solid 2px;
    }
  }
  .or {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .or-bar {
      width: 42%;
      height: 1px;
      margin: 8px 22px 0 22px;
      background-color: $color-header-gray;
    }

    .or-center {
      height: 10px;
      width: 10px;
      font-family: 'Ubuntu-MediumItalic', sans-serif;
      font-size: 16px;
    }
  }

  &.mobile-mode {
    width: 326px;
    height: 594px;
    min-height: auto;

    .header__collection-service {
      .title__header__collection-service {
        font-size: 20px;
        max-width: 100%;
        margin-left: 10px;
        text-align: center;
        font-family: "Ubuntu-BoldItalic", sans-serif;
      }
    }

    .subtitle__header__collection-service {
      width: 100%;
      max-width: 230px;
      padding: 2px;
    }

    .container-type-shipment {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      margin-top: 0;

      .title__header__collection-service {
        width: 100%;
        text-align: center;
        font-family: 'Ubuntu-MediumItalic', sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .footer__collection-service {
      flex-direction: column;
      max-width: 90%;
      margin: 0 auto;
    }
  }
}
