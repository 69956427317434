@import '../../themes/colors.scss';

.container-section-mobile{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 80px);
  top: 80px;
  left: 0;
}
