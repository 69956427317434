@import "../../../../../themes/fonts.scss";
@import "../../../../../themes/colors.scss";
@import "../../../../../themes/general.scss";

.history_row {
  cursor: pointer;
  width: 99%;
  margin: 0 auto;

  &.none_curso {
    cursor: default;
  }
}

.desktop-table__shipping-history {
  width: 100%;
  display: grid;
  grid-template-columns: 0fr 0fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr 0.1fr;
  background-color: white;
  padding-bottom: 0px;

  &.mx {
    grid-template-columns: 0fr 0fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr;
  }

  &:hover {
    background-color: $color-background;
  }

  .container-checkbox-and-label {
    width: fit-content;
  }

  .head-cell__shipping-history {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Heebo-Medium", sans-serif;
    font-size: 16px;
    color: $color-dark;
    background-color: white;

    &:first-child {
      margin-left: 6px;
    }

    &.two {
      width: 40px;
    }

    &.eight {
      justify-content: center;
      text-align: center;
    }
  }

  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Heebo-Regular", sans-serif;
    font-size: 14px;
    color: $color-text-label;
    height: 40px;
    margin: 0;
    text-align: center;
    padding: 0 8px;
    overflow: hidden;
    position: relative;

    .text-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.red {
      color: $color-error;
    }

    &.blue {
      color: $color-accent;
    }

    &.gray {
      color: $color-text-value;
    }

    &.two {
      width: 40px;
    }

    &.two > img {
      width: 28px;
      height: 28px;
      min-width: 28px;
      max-width: 28px;
      min-height: 28px;
      max-height: 28px;
      border-radius: 4px;
    }

    &.one {
      margin-left: 6px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      .error-checkbox {
        .container-checkbox {
          .checkmark {
            border-color: transparent;
          }

          &:hover {
            background-color: transparent;

            .checkmark {
              border-color: transparent;
            }
          }
        }
      }
    }

    &.three {
      font-family: "Heebo-Bold", sans-serif;
      color: $color-dark;

      &.red {
        color: $color-error;
      }
    }

    &.four,
    &.five,
    &.six {
      color: $color-header-gray;
    }

    &.eight {
      justify-content: center;
    }

    &.nine {
      overflow: visible;
      position: relative;

      span {
        visibility: hidden;
        position: absolute;
        bottom: 36px;
        right: -22px;
        z-index: 1;
        font-size: 12px;
        padding: 4px 0;
        width: 138px;
        background: white;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0px 3px 6px 2px $color-box-shadow-select;
        font-family: 'Heebo-Regular', sans-serif;
        color: $color-header-gray;

        &::after {
          content: "";
          position: absolute;
          top: 83%;
          left: 48%;
          margin-left: -1px;
          line-height: 10px;
          border-color: #fff transparent transparent;
          border-style: solid;
          border-width: 11px 5px 0px 7px;
        }
      }

      img {
        width: 22px;
        height: 20px;

        &:hover {
          &+span {
            visibility: visible;
          }
        }
      }

    }

    &.ten {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      .imagen_button {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-style: solid;
        border-width: 1px;
        border-color: $color-primary-hover-download-button;

        span {
          visibility: hidden;
          position: absolute;
          bottom: 41px;
          right: 0;
          z-index: 1;
          font-size: 12px;
          padding: 4px 0;
          width: 112px;
          background: white;
          border: 1px solid transparent;
          border-radius: 5px;
          box-shadow: 0px 6px 8px 1px $color-box-shadow-select;
          font-family: 'Heebo-Regular', sans-serif;
          color: $color-header-gray;

          &::after {
            content: "";
            position: absolute;
            top: 84%;
            left: 76%;
            margin-left: -1px;
            line-height: 10px;
            border-color: #fff transparent transparent;
            border-style: solid;
            border-width: 13px 1px 0px 18px;
          }
        }

        &:hover {
          background-color: $color-blue-variant;

          span {
            visibility: visible;
          }
        }

        &.disabled {
          border-color: $color-header-gray;
          cursor: default;

          &:hover {
            background-color: transparent;

            span {
              visibility: hidden;
            }

            &.load {
              span {
                min-width: 150px;
                visibility: visible;
                text-align: left;
                padding-left: 8px;
                padding-right: 8px;
                bottom: 32px;

                &::after {
                  left: 81%;
                }
              }
            }
          }

          .img {
            width: 10px;
            height: 10px;
            background-color: $color-header-gray;
            mask: url(../../../../../assets/images/Icon-feather-download.svg)
              no-repeat center / contain;
            -webkit-mask: url(../../../../../assets/images/Icon-feather-download.svg)
              no-repeat center / contain;
          }
        }

        &.load {
          border-style: none;

          .img {
            width: 20px;
            height: 20px;
            background-color: $color-load-icon;
            mask: url(../../../../../assets/images/load.svg) no-repeat center /
              contain;
            -webkit-mask: url(../../../../../assets/images/load.svg) no-repeat
              center / contain;
          }
        }

        .img {
          width: 10px;
          height: 10px;
          background-color: $color-primary-hover-download-button;
          mask: url(../../../../../assets/images/Icon-feather-download.svg)
            no-repeat center / contain;
          -webkit-mask: url(../../../../../assets/images/Icon-feather-download.svg)
            no-repeat center / contain;
        }
      }
    }

    &.unread {
      background-color: white;
      height: 52px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}
