@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';
@import '../../themes/general.scss';

.container-recharge-balance__section-desktop{
  border-radius: 12px;
  width: 46%;
  min-width: 460px;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  right: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.screen-extend {
  width: 90%;
  right: 0px;
}
