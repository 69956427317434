@import '../../../../themes/fonts.scss';
@import '../../../../themes/colors.scss';
@import '../../../../themes/general.scss';

#multiple-shippings__shipping-details{
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;

  .flotants-buttons{
    width: 100%;
    position: absolute;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > img{
      cursor: pointer;
    }

    button{
      width: auto;
      padding: 0 20px;
      border: 1px solid $color-error;
      color: $color-error;

      img{
        margin-left: 5px;
      }
    }
  }

  .delivery-companies-list__shipping-details{
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    overflow-x: hidden;
    margin-top: 50px;

    table{
      width: 98%;
      margin-left: 1%;

      tbody{
        tr{
          td{
            &.checkbox-select-delicery-company{
              .checkmark {
                height: 16px;
                width: 16px;

                &:after {
                  top: 0px;
                }
              }
            }

            &.blue{
              color: $color-blue;
            }

            &.observations{
              border-radius: 4px;
              &:hover{
                background-color: $color-accent-variant-light;
              }
            }

            .delivery-company{
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img{
                width: 40px;
                margin: 10px 0;
                border-radius: 5px;
              }

              span{
                text-align: left;
                margin-left: 10px;
                width: min-content;
              }
            }

            .delivery-company-rate{
              display: flex;
              align-items: center;
              justify-content: center;

              img{
                width: 18px;
              }

              span{
                text-align: center;
                margin-left: 5px;
              }
            }

            .container-select-payment-method{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: max-content;
              position: relative;
              border-radius: 4px;

              .container-selector{
                width: 100%;
                height: 32px;
                padding: 0 10px;
                box-sizing: border-box;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: white;

                .label{
                  font-family: "Heebo-Regular", serif;
                  font-size: 14px;
                  color: $color-accent-variant;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  max-width: 200px;
                }

                img{
                  width: 8px;
                  margin-left: 5px;
                }

                &:hover{
                  background-color: #D9F2FF;
                }
              }

              .container-options{
                position: absolute;
                width: max-content;
                top: 32px;
                left: 0;
                height: 0;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 4px;
                background-color: white;
                overflow: hidden;

                &.relative{
                  position: relative;
                  top: 0;
                  width: 20px;
                  overflow-x: hidden;
                  .options{
                    width: 600px;
                  }
                }

                .options{
                  padding: 5px 10px;
                  box-sizing: border-box;
                  text-align: left;

                  &:hover{
                    background-color: #D9F2FF;
                  }
                }
              }

              &.select{
                box-shadow: 0px 4px 12px -4px rgba(0,0,0,0.5);
                .container-selector{
                  background-color: #D9F2FF;
                }
                .container-options{
                  height: auto;
                  box-shadow: 0px 4px 12px -4px rgba(0,0,0,0.5);
                  z-index: 1;
                }
              }
            }
          }

          &:hover{
            background-color: white;
          }
        }
      }
    }
  }
}
