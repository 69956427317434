@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.content-modal {
  width: 511px;
  height: 68px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu-MediumItalic", sans-serif;
}

@media only screen and (max-width: 1023px) {
  .content-modal {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    max-width: 328px;
    width: auto;
    height: 92px;
  }
}
