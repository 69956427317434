@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

button, a{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-family: "Ubuntu-MediumItalic", serif;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;

  * {
    transition: all 200ms ease;
  }

  .right-image-button {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }

  .preloader-button {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: 3px solid #eee;
    border-top: 3px solid #666;
    border-radius: 50%;
    animation-name: girar;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes girar {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  &.button-none {
    color: $color-dark;
    height: 80px;
    min-height: 80px;
    border-radius: 14px;
    background: $color-light;
    box-shadow: 0 10px 14px -8px rgba(0, 0, 0, 0.25);

    &~:visited {
      color: inherit;
    }
  }

  &.button-link {
    color: $color-accent-variant;
    height: auto;
    border-radius: 14px;
  }

  &.button-primary {
    color: $color-light;
    height: 42px;
    min-height: 42px;
    border-radius: 25px;
    background: rgba(0, 154, 240, 1);
    background: -moz-linear-gradient(left, rgba(0, 154, 240, 1) 0, rgba(0, 105, 163, 1) 100%);
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(0, 154, 240, 1)),
      color-stop(100%, rgba(0, 105, 163, 1))
    );
    background: -webkit-linear-gradient(left, rgba(0, 154, 240, 1) 0, rgba(0, 105, 163, 1) 100%);
    background: -o-linear-gradient(left, rgba(0, 154, 240, 1) 0, rgba(0, 105, 163, 1) 100%);
    background: -ms-linear-gradient(left, rgba(0, 154, 240, 1) 0, rgba(0, 105, 163, 1) 100%);
    background: linear-gradient(to right, rgba(0, 154, 240, 1) 0, rgba(0, 105, 163, 1) 100%);
    // box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.25);

    &:active {
      color: $color-dark-light;
      transform: scale(0.9);
    }

    &~:visited {
      color: inherit;
    }
  }

  &.button-primary-mx {
    color: $color-dark;
    height: 42px;
    min-height: 42px;
    border-radius: 25px;
    background: $color-primary;
    background: -moz-linear-gradient(left, 	rgb(255, 156, 25) 0, 	rgb(255, 156, 25) 100%);
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(255, 156, 25, 1)),
      color-stop(100%, rgba(255, 156, 25, 1))
    );
    background: -webkit-linear-gradient(left, rgba(255, 156, 25, 1) 0, rgba(255, 156, 25, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 156, 25, 1) 0, rgba(255, 156, 25, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 156, 25, 1) 0, rgba(255, 156, 25, 1) 100%);
    background: linear-gradient(to right, rgba(255, 156, 25, 1) 0, rgba(255, 156, 25, 1) 100%);
    // box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.25);

    &:active {
      color: $color-dark-light;
      transform: scale(0.9);
    }

    &~:visited {
      color: inherit;
    }
  }

  &.button-secondary {
    height: 32px;
    min-height: 32px;
    border-radius: 20px;
    padding: 0 16px;
    color: $color-primary;
    border: 1px solid $color-primary;
    background: $color-light;

    &:hover {
      background-color: $color-secondary-hover-button;
    }

    &:active {
      transform: scale(0.9);
      color: $color-primary-variant;
    }

    &:disabled {
      background: $color-light !important;
      border: 2px solid $color-dark-light;
      color: $color-dark-light !important;
      box-shadow: none;
    }

    &.small {
      height: 32px;
    }

    &.normal {
      height: 32px;
    }

    &~:visited {
      color: inherit;
    }
  }

  &.button-tertiary {
    background: $color-light 0 0 no-repeat padding-box;
    border: 1px solid $color-accent;
    border-radius: 25px;
    padding: 0 16px;
    opacity: 1;
    color: $color-accent;
    height: 32px;

    &:active {
      transform: scale(0.9);
    }

    &.colorBlue {
      border: 1px solid $color-accent;
      color: $color-accent;
    }

    &:disabled {
      background: $color-light !important;
      border: 2px solid $color-dark-light;
      color: $color-dark-light !important;
      box-shadow: none;
    }

    &:hover {
      background-color: $color-primary-hover-button;
    }

    &.small {
      height: 32px;
    }

    &.normal {
      height: 40px;
    }

    &~:visited {
      color: inherit;
    }
  }

  &.button-quaternary {
    background: transparent linear-gradient(111deg, $color-bg-buton-quternary-gradiant1 0%,
    $color-bg-buton-quternary-gradiant2 100%) 0 0 no-repeat padding-box;
    border-radius: 25px;
    opacity: 1;
    color: $color-dark;
    height: 50px;
    font-weight: bold;

    &:active {
      transform: scale(0.9);
    }

    &~:visited {
      color: inherit;
    }
  }

  &.button-link {
    color: $color-accent-variant;

    &~:visited {
      color: inherit;
    }
  }

  &.button-primary-second {
    height: 34px;
    min-height: 34px;
    border-radius: 20px;
    color: $color-error;
    border: 1px solid $color-error;
    background: $color-light;

    &:hover {
      background-color: $color-background-error;
    }

    &:active {
      transform: scale(0.9);
      color: $color-error;
    }

    &:disabled {
      background: $color-light !important;
      border: 2px solid $color-dark-light;
      color: $color-dark-light !important;
      box-shadow: none;
    }

    &.small {
      height: 32px;
    }

    &.normal {
      height: 32px;
    }

    &~:visited {
      color: inherit;
    }
  }

  &:disabled {
    background: $color-light !important;
    border: 2px solid $color-dark-light;
    color: $color-dark-light;
    box-shadow: none;
    cursor: initial;
  }

  &.hidden {
    width: 0 !important;
    height: 0 !important;
    font-size: 0;
  }

  &.noBold {
    font-weight: normal;
  }

  &.sizeReports {
    width: 116%;

    &:disabled {
      height: 32px;
    }
  }

  &.spaceBetween {
    margin: 40px 0;
  }

  &.whach-all {
    width: 100px;
  }
}
