@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

#container-recharge-balance-wompy {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  transform: translateX(-10px);

  #header__container-recharge-balance {
    width: 90%;
    height: 70px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 2px solid $color-primary;

    .left,
    .rigth {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .left {
      justify-content: flex-start;
    }

    .center {
      display: flex;
      align-items: center;
    }
  }

  #body__container-recharge-balance {
    width: 100%;
    display: flex;
    align-items: center;

    .second-body__container-recharge-balance {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 300px;
      }

      .container-input-and-checkbox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 40px;

        .center {
          justify-content: flex-start;
        }

        .wompi-value {
          margin-bottom: 40px;
        }
      }

      .container-checkbox-and-label {
        justify-content: center;
        max-width: 300px;

        &.terms-and-condition-text {
          span {
            &.text-checkbox {
              font-weight: unset;
              color: $color-primary-hover-download-button;
              font-size: 14px;
            }

            &.second-text-checkbox {
              font-weight: unset;
              color: $color-primary-hover-download-button;
              font-size: 14px;
            }
          }
        }
      }

      .contaier-payment-methods {
        width: 100%;

        .title {
          margin: 0 auto;
          width: fit-content;
        }

        .banks-container {
          min-width: 300px;
          .card-image-button-component {
            margin-top: 4px;
          }
        }
      }

      .container-type-person {
        .field-dark-gray {
          margin-bottom: 8px;
          display: block;
        }

        .radio-button {
          input {
            margin-left: 0px;
          }
        }

        margin-bottom: 40px;
      }

      .new-color {
        color: $color-dark;
      }

      .container-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 80%;
        max-width: 400px;

        .text {
          font-family: "Heebo-Regular", serif;
          font-size: 16px;
          color: $color-text-value;
          margin: 20px 0;

          b {
            font-family: "Heebo-Medium", serif;
          }
        }
      }

      .container-terms {
        margin: 16px 0;
      }

      .container-buttons {
        width: 100%;
        height: 70px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        &.hidden {
          display: none;
        }
      }

      .center {
        text-align: center;

        .payment-method-title {
          font-family: "Heebo-Medium", serif;
          font-size: 16px;
          margin: 35px 0;
          margin-bottom: 8px;
        }
      }

      .payment-methods {
        padding: 0px 0;

        .each {
          width: 84%;
          padding: 8px 24px;
          margin: 16px 0;
          display: flex;
          align-items: center;
          border: 1px solid #d6dbdf;
          border-radius: 5px;
          cursor: pointer;

          .img {
            width: 24px;
            height: 24px;
            background-color: #009af0;
            mask: url(../../../assets/images/payment_black.svg) no-repeat center / contain;
            -webkit-mask: url(../../../assets/images/payment_black.svg) no-repeat center / contain;
          }

          .left-column {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .method {
              font-size: 14px;
              padding-left: 16px;
            }

            .arrow {
              width: 24px;
              height: 24px;
              background-color: #f08700;
              transform: rotate(180deg);
              mask: url(../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
              -webkit-mask: url(../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
            }
          }
        }
        .recharge-button {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .recharge-button-tutorial {
            width: 75%;
            margin: 18px 0;
          }

          .link-text {
            display: flex;
            font-family: "Heebo-Regular", sans-serif;
            font-weight: 100;
            font-size: 14px;
            color: #353a3d;

            a {
              font-size: 14px;
              color: #009af0;
              width: fit-content;
              margin-left: 4px;
              font-family: "Heebo-Regular", sans-serif;
            }
          }
        }
      }
    }
  }

  &.desktop-mode {
    #body__container-recharge-balance {
      .second-body__container-recharge-balance {
        .container-buttons {
          flex-direction: row-reverse;

          button,
          a,
          input {
            width: 100%;
            max-width: 300px;
          }
        }
      }
    }
  }

  &.mobile-mode {
    #header__container-recharge-balance {
      padding: 0 10px;

      .center {
        h1 {
          font-size: 18px;
        }
        .left-space {
          padding-left: 30px;
        }
      }

      .rigth {
        width: 0px;
      }

      .left {
        width: 30px;
      }
    }
    #body__container-recharge-balance {
      .second-body__container-recharge-balance {
        width: 88%;
        margin: 0 auto;

        form {
          width: 100%;
          min-width: unset;

          .contaier-payment-methods {
            .banks-container {
              min-width: unset;

              #card-image-button-component {
                .container-image-button-text {
                  min-width: unset;
                  margin-left: 4px;

                }
              }
            }
          }
        }

        .payment-methods {
          .each {
            padding: 8px 18px;
          }

          .link-text {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .container-buttons {
          flex-direction: column;

          button,
          a {
            width: 90%;
            max-width: 300px;
          }
        }
      }
    }
  }
}
