@import "../../../../../../themes/fonts.scss";
@import "../../../../../../themes/colors.scss";
@import "../../../../../../themes/general.scss";

.container-item-list-mobile {
  min-height: 122px;
  height: min-content;
  margin: 10px 5px;
  border-bottom: solid rgba(0, 0, 0, 0.404) 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5px;
  width: calc(100% - 15px);

  .content-check {
    width: 2rem;
    height: 100%;

    .error-checkbox {
      .container-checkbox {
        .checkmark {
          border-color: transparent;
        }

        &:hover {
          background-color: transparent;

          .checkmark {
            border-color: transparent;
          }
        }
      }
    }
  }

  .content-shipment-information {
    height: 100%;
    width: 100%;

    .content-text {
      margin: 10px 5px;
      box-sizing: border-box;

      .delivery-company-title {
        font-size: 18px;
      }

      .guideNumber-title {
        font-size: 17px;

        &.red {
          color: $color-error;
        }
      }

      .locate-title {
        font-size: 17px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name-title {
        font-size: 17px;
      }

      .state__data-shipping {
        font-size: 16px;
      }
    }
  }

  .content-date-and-download {
    width: 5rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .date-title {
      font-size: 17px;
    }

    .icon-hover {
      width: 100%;
      margin-top: 20px;
      margin-bottom: -10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      span {
        visibility: hidden;
        position: relative;
        bottom: 28px;
        right: 4px;
        z-index: 1;
        font-size: 12px;
        padding: 4px;
        width: 138px;
        background: white;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0 3px 6px 2px $color-box-shadow-select;
        font-family: "Heebo-Regular", sans-serif;
        color: $color-header-gray;

        &::after {
          content: "";
          position: absolute;
          top: 84%;
          right: 3%;
          margin-left: -1px;
          line-height: 10px;
          border-color: #fff transparent transparent;
          border-style: solid;
          border-width: 11px 5px 0 7px;
        }
      }

      img {
        height: 27px;
        margin: auto;

        &:hover {
          & + span {
            visibility: visible;
          }
        }
      }
    }

    .image_button-arrow {
      width: 2.1rem;
      height: 2.1rem;
      background-color: $color-primary-hover-download-button;
      margin: 10px 15px;
      cursor: pointer;
      mask: url("../../../../../../assets/images/Botón de descarga.svg")
        no-repeat center / contain;
      -webkit-mask: url("../../../../../../assets/images/Botón de descarga.svg")
        no-repeat center / contain;

      &.disabled {
        border-color: $color-header-gray;
        cursor: default;
        background-color: $color-header-gray;
        mask: url("../../../../../../assets/images/Botón de descarga.svg")
          no-repeat center / contain;
        -webkit-mask: url("../../../../../../assets/images/Botón de descarga.svg")
          no-repeat center / contain;

        &.load {
          width: 2.1rem;
          height: 2.1rem;
          border-style: none;
          background-color: $color-load-icon;
          mask: url(../../../../../../assets/images/load.svg) no-repeat center /
            contain;
          -webkit-mask: url(../../../../../../assets/images/load.svg) no-repeat
            center / contain;
        }
      }
    }
  }
}
