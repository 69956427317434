@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

.info-data{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;

  .preloader {
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    border: 3px solid transparent;
    border-top: 3px solid $color-primary;
    border-radius: 50%;
    animation-name: girar;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes girar {
      from {
          transform: rotate(0deg);
      }

      to {
          transform: rotate(360deg);
      }
  }

  img{
    width: 60%;
    max-width: 200px;
    margin-bottom: 20px;
    opacity: .2;
  }

  span{
    font-family: "Ubuntu-MediumItalic";
    font-size: 14px;
    color: $color-dark-light;
  }
}
