.container-modal-balance-error {
  width: 450px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  .title-modal-balance-error {
    margin-bottom: 30px;
  }

  .paragraph-modal-balance-error {
    margin: 0px 60px;
  }

  .container-bottom-modal-balance-error {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-balance-error {
      width: 45%;
      height: 40px;
    }
  }


}
