.socialLogin {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: space-between;

  .facebook, .google{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 133px;
    height: 34px;
    border-radius: 25px;
    cursor: pointer;

    &:hover { background-color: rgb(55 53 175 / 10%) }

    .icon {
      height: 24px;
      width: 24px;
    }

    span {
      display: flex;
      font-size: 16px;
      color: #3B5998;
      font-family: 'Heebo-Regular', sans-serif;
      margin-left: 8px;
    }&.google span{
      color: #4A90E2;
    }
  }
}
