@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";
@import "../../../../themes/general.scss";

.container-stepOne-quoteMultipleShippings {
  background-color: transparent;

  #multiple-shippings__template {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .content-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
      max-width: 90%;

      .arrow-hidden {
        display: none;
      }

      * {
        margin: 5px 0;
        text-align: center;
      }
    }

    .content-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      button {
        width: 258px;
        height: 40px;
      }
    }

    &.mobile-mode {
      .content-bottom {
        width: 85%;
      }

      .adjust-arrow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 50%;
      }

      .arrow {
        height: 15px;
        margin-left: -2.5em;
      }
    }

    .accent {
      color: $color-accent;
      cursor: pointer;
    }
  }
}


@media only screen and (max-width: 1023px) {
  .container-stepOne-quoteMultipleShippings {
    background: $color-light;
  }
}
