@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.quote-shipping__modal-annotations {
  width: 600px;
  padding: 20px 0;
  box-sizing: border-box;
  height: 76vh !important;
  height: auto;

  .header__modal-annotations {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .close-container {
      width: 30px;
      height: 30px;
      top: -10px;
      right: 10px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $color-background-hover-menu;
        border-radius: 50%;
      }

      .close-button {
        width: 16px;
        height: 19px;
        cursor: pointer;
      }
    }

    img {
      height: 100%;
      width: 70px;
      border-radius: 8px;
    }

    .title__header__modal-annotations {
      color: $color-primary-variant;
      max-width: 60%;
      margin-left: 10px;

      .deliveryTitle {
        font-size: 16px;
        font-family: "Ubuntu-Medium", sans-serif;

        span {
          font-family: "Ubuntu-BoldItalic", sans-serif;
        }
      }
    }
  }

  .body__modal-annotations {
    width: 100%;
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .paragraph__body__modal-annotations {
      width: 90%;
      padding: 10px 0;
      box-sizing: border-box;

      ul {
        height: max-content;
      }

      &.blue {
        color: $color-accent-variant;
      }
    }
  }

  &.mobile-mode {
    .header__modal-annotations {
      .close-container {
        width: 30px;
        height: 30px;
        top: -16px;
      }
    }
  }
}
