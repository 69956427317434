@import "./themes/fonts.scss";
@import "./themes/colors.scss";

* {
  margin: 0;
  padding: 0;
}

button {
  height: auto;
  outline: none;
  border: none;
  background-color: transparent;
}

.swal-modal {
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  border-spacing: 30px;

  .swal-title {
    font-family: "Ubuntu-MediumItalic";
    align-items: center;
    justify-content: center;
    margin-bottom: 21px;
    margin-left: -24px;
  }

  .swal-text {
    font-family: "Heebo-Regular";
    align-items: center;
    justify-content: center;
    padding: 0 28px;
  }

  .swal-button--confirm {
    background: linear-gradient(to right, #009af0 0%, #0069a3 100%);
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-family: "Ubuntu-MediumItalic";
    margin-right: 39%;
    font-weight: 100;
  }
}

.swal-inactive {
  max-width: 450px;
  img {
    height: 100px;
  }

  .swal-text {
    font-family: "Ubuntu-MediumItalic";
    font-size: 18px;
    text-align: center;
  }
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

*::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
  margin: 8px 0;
}

*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(187, 178, 178, 0.2);
}

*::-webkit-scrollbar-track:hover,
*::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.general-loading {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 4px solid #eee;
  border-top: 4px solid #666;
  border-radius: 50%;
  animation-name: girar;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.full-width{ width: 100% }
.cursor-pointer{ cursor: pointer }

.color-white{ color: $color-light !important }
.color-primary{ color: $color-primary-variant !important }
.color-accent{ color: $color-accent !important }
.color-red{ color: $color-error !important }

.flex{ display: flex }
.row{ flex-direction: row }
.column{ flex-direction: column }
.align-start{ align-items: flex-start }
.align-center{ align-items: center }
.align-end{ align-items: flex-end }
.justify-center{ justify-content: center }
.justify-start{ justify-content: flex-start }
.justify-end{ justify-content: flex-end }
.justify-around{ justify-content: space-around }
.justify-between{ justify-content: space-between }
.justify-evenly{ justify-content: space-evenly }
