@import "../../../themes/fonts";
@import "../../../themes/colors";
@import "../../../themes/general";

.header-shipping-details {
  display: flex;
  max-width: 100%;
  margin: 0 20px;
  height: 3rem;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: solid 2px $color-primary;

  .header-back-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    .back-arrow {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
    }
  }

  .header-guide-code-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    font-size: 1rem;

    span {
      font-family: "Ubuntu-Medium", serif;
      font-size: 1rem;
      color: #353A3D;
      margin: 5px 2px;
      text-align: center;


      &.color-ligth {
        color: #7D8488;
        margin: 0;
      }
    }
  }

  .rigth {
    min-width: 24px;
  }
}

.sender-shipping-details {
  width: 100%;
  margin: 1rem 0;

  .sender-data-container {
    display: flex;
    flex-direction: row;
    margin: .7rem 0;

    .text-sender-data {
      margin: 0 1rem;

      .text-sender-detail {
        margin: .2rem 0;
      }
    }
  }

}

.receiver-shipping-details {
  width: 100%;
  margin: 1.3rem 0;

  .text-receiver-data {
    margin: 1rem 0;

    .text-receiver-detail {
      margin: .2rem 0;
      font-size: 14px;
    }
  }
}

.product-shipping-details {
  width: 100%;
  margin: 1.3rem 0;

  .text-product-data {
    margin: 1rem 0;

    .container-text-product-detail {
      display: flex;
      flex-direction: row;

      .text-product-detail:first-child {
        margin-right: .3rem;
        font-family: "Heebo-Medium", sans-serif;
      }

      .text-product-detail {
        margin: .2rem 0;
      }
    }
  }
}

.delivery-company-details {
  height: 10rem;
  max-width: 100%;
  min-width: 20rem;

  .text-observations {
    margin-top: 12px;
    font-family: "Ubuntu-Regular", serif;
  }

  .conetent-image-delivary-company{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    justify-content: flex-start;

    img {
      height: 3rem;
      width: 3rem;
      border-radius: 4px;
      margin-right: .7rem;
    }

    .delivery-company-name {
      font-size: 14px;
      font-family: "Heebo-Medium", serif;
    }

    .content-curency-pay {
      display: flex;
      justify-self: flex-end;
      margin-right: 1rem;
      margin-left: auto;
    }

    .content-curency-pay:first-child {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .delivery-company-details {
    min-width: 0;
  .conetent-image-delivary-company{
    .content-curency-pay {
      margin-right: 0;
    }
  }
}
}
