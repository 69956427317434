@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

#profile {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  padding: 0 2%;
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: none;

  .header-profile {
    border-bottom: 1.5px solid $color-primary-variant;
    height: 62.5px;
    padding: 0 30px 0 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;

    .button-back {
      font-size: 20px;
      margin-left: 10px;
      color: #353a3d;
      font-weight: unset;
      letter-spacing: 0;
    }

    img {
      cursor: pointer;
      height: 16px;
      width: 16px;
      padding: 10px;
      border-radius: 50%;
      margin-right: 20px;

      &:hover {
        background-color: $color-primary-variant-light;
      }
    }
  }

  .profile {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    .profile-direction {
      height: 70%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .header-mobile {
    width: 100%;
  }

  .profile-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
    flex-direction: column;

    .profile-direction {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
