@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

.avatarIcon {
  h1 {
    text-transform: uppercase;
    font-weight: 500;
    background-color: $color-accent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Ubuntu-Medium";
    font-size: 24px;
    color: $color-light;
    margin-left: 10px;
  }
}

.avatarIconMX {
  h1 {
    text-transform: uppercase;
    font-weight: 500;
    background-color: $color-primary-variant;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Ubuntu-Medium", sans-serif;
    font-size: 24px;
    color: $color-light;
    margin-left: 10px;
  }
}
