@import '../../../../themes/fonts.scss';
@import '../../../../themes/colors.scss';
@import '../../../../themes/general.scss';

.shipping-request-quantity {
  font-family: "Heebo-Medium", serif;
  font-size: 16px;
  color: $color-dark;

  b {
    font-family: "Heebo-Regular", serif;
    font-weight: unset;
  }
}

#multiple-shippings__sending-list {
  height: 100%;
  width: 100%;

  .radio-text-massive span {
    font-family: "Heebo-Regular", serif;
    font-size: 16px;
  }

  .container-arrow-back {
    padding: 10px;

    &:hover {
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
      background-color: rgba(255, 156, 25, 0.3);
      transform: scale(1.05);
    }
  }

  .content-top {
    width: 100%;
    padding: 10px 20px;
    height: 140px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &.hidden {
      height: 0;
      padding: 0;
      overflow: hidden;
    }

    .container-column__column-top {
      width: 100%;
      display: flex;
      flex-direction: column;

      &.first {
        align-items: flex-start;
        justify-content: space-between;

        .checkmark {
          height: 16px;
          width: 16px;
          border-color: $color-ligther-gray;

          &:after {
            top: 0px;
          }
        }
      }

      &.second {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .title__second {
          font-family: "Ubuntu-Medium", serif;
          font-size: 16px;
          color: $color-dark;
          margin-right: 64px;
          margin-bottom: 16px;
        }

        .title__second-personalized {
          font-family: "Ubuntu-Medium", serif;
          font-size: 16px;
          color: $color-dark;
          margin-right: 78px;
          margin-bottom: 16px;
        }

        .content-second {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .column__content-second {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .select-by-filters__shipping-list {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                height: 18px;
                margin-right: 5px;
              }

              .text {
                font-family: "Heebo-Regular", sans-serif;
                font-size: 16px;
                color: $color-dark;
              }

            }

            .container-select-delivery-company {
              margin: 2px 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: max-content;
              position: relative;
              border-radius: 4px;
              cursor: pointer;
              border: #0069A3 solid 1px;

              .container-selector {
                max-width: 260px;
                height: 32px;
                padding: 0 10px;
                box-sizing: border-box;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .label {
                  font-family: "Heebo-Regular", serif;
                  font-size: 16px;
                  color: $color-accent-variant;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  max-width: 200px;
                }

                img {
                  width: 32px;
                  margin: 0 10px;
                }

                &:hover {
                  background-color: #D9F2FF;
                }
              }

              .container-options {
                position: absolute;
                width: 184px;
                top: 32px;
                left: 0;
                height: 0;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 4px;
                background-color: white;
                overflow: hidden;
                max-height: 200px;
                overflow-y: hidden;

                .options-delivery-company {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  padding: 10px;
                  font-family: "Heebo-Regular", sans-serif;
                  font-size: 16px;
                  color: $color-dark;

                  &:hover {
                    background-color: #009AF01A;
                    width: 100%;
                  }

                  img {
                    height: 28px;
                    border-radius: 4px;
                    margin-right: 5px;
                  }
                }
              }

              &.show {
                box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);

                .container-selector {
                  background-color: #D9F2FF;
                }

                .container-options {
                  height: auto;
                  box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);
                  z-index: 1;
                }
              }
            }

            .container-checkbox {
              width: 36px;
              height: 36px;
            }

            .checkmark {
              height: 16px;
              width: 16px;

              &:after {
                top: 0px;
              }
            }

            &:checked~.checkmark {
              border-color: transparent !important;
            }
          }
        }
      }

      &.third {
        align-items: center;
        justify-content: center;

        .container-buttons {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          button {
            max-width: 45%;
            margin: 10px 10px;

            &:last-child {
              max-width: 200px;
            }
          }
        }
      }
    }

    &.mobile-mode {
      height: 100%;
      overflow-y: auto;
      flex-direction: column;
      justify-content: flex-start;

      .container-column__column-top {
        margin: 20px 0;

        .container_mobile_payment {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-top: 8%;

          .personalized-class-button {
            max-width: 250px;
            width: 250px;
            margin-bottom: 8%;
          }
        }

        &.second {
          .title__second {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            text-align: center;
            margin-right: 0;
            margin-bottom: 14px;
          }

          .content-second {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .column__content-second {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }

          }

          .button__show-shipping-list {
            font-family: "Ubuntu-MediumItalic", sans-serif;
            font-size: 18px;
            color: $color-primary-hover-download-button;
          }
        }

        &.third {
          padding: 0 10px;
          box-sizing: border-box;
          max-width: 90%;
          align-items: center;
          justify-content: flex-start;

          .container-checkbox-and-label {
            width: max-content;
          }

          .container-buttons {
            flex-direction: column-reverse;
            justify-content: flex-start;

            button {
              max-width: 180px;
              margin: 10px 10px;

              &:last-child {
                max-width: 200px;
              }
            }
          }
        }
      }

      .align-mobile {
        margin-right: 20px;
      }
    }
  }

  .title-conflicts-annotation {
    font-family: "Heebo-Regular", sans-serif;
    font-size: 14px;
    color: $color-error;
    font-weight: 500;
    width: 40%;
    height: 40px;
    text-align: center;
    margin: 10px 30%;
    min-width: 350px;
    display: block;
    box-sizing: border-box;
  }

  .content-bottom {
    width: calc(100% - 60px);
    height: calc(94% - 180px);
    margin: 0 30px;
    border-top: 1px solid $color-primary-variant;
    box-sizing: border-box;
    padding: 10px 0;
    overflow-y: auto;

    .shipping-request-quantity-mobile {
      font-family: "Heebo-Medium", serif;
      font-size: 16px;
      color: $color-dark;
      margin: 16px;
    }


    .container-item-list-sendings {
      width: 100%;
      height: 100%;

      .item-list-sending {
        width: 95%;
        margin: 0 2.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #F08700;
        cursor: pointer;
        font-family: "Heebo-Regular", serif;

        &.selected {
          border-bottom: 1px solid transparent;
        }

        .name {
          margin-right: 60%;
          width: 100%;

          .field {
            font-size: 14px;
            color: $color-ligther-gray;
          }

          .value {
            font-size: 14px;
          }

          .conflicts {
            color: $color-error;
            margin-left: 4px;
          }
        }

        .options {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 16px;
            margin-right: 20px;
            transform: rotate(180deg);

            &.selected {
              transform: rotate(0);
            }
          }

          .delete-icon {
            width: 18px;
            transform: rotate(360deg);
          }
        }


      }

      .list-sendings__item-list-sending {
        width: 90%;
        margin: 0 5%;
        height: 0px;
        overflow: hidden;

        .shipping-conflict {
          color: $color-error;
          font-size: 12px;
          text-align: left;
          display: block;
        }

        &.heigth-with-padding-bottom {
          table {
            margin-bottom: 100px;
            box-sizing: border-box;
          }
        }

        &.selected {
          height: auto;
        }

        .container-select-payment-method,
        .container-select-delivery-company {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: max-content;
          position: relative;
          border-radius: 4px;

          .container-selector {
            width: 100%;
            height: 32px;
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: white;

            .label {
              font-family: "Heebo-Regular", sans-serif;
              font-size: 16px;
              color: $color-accent-variant;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 200px;
            }

            img {
              margin-left: 5px;
              width: 8px;
            }

            &:hover {
              background-color: #D9F2FF;
            }
          }

          .container-options {
            position: absolute;
            width: max-content;
            top: 32px;
            left: 0;
            height: 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background-color: white;
            overflow: hidden;

            &.relative {
              position: relative;
              top: 0;
              width: 20px;
              overflow-x: hidden;

              .options {
                width: 600px;
              }

              .options-delivery-company {
                width: 600px;
              }
            }

            .options {
              padding: 5px 10px;
              box-sizing: border-box;
              text-align: left;

              &:hover {
                background-color: #D9F2FF;
              }
            }

            .options-delivery-company {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 4px 10px;

              &:hover {
                background-color: #D9F2FF;
              }

              &:first-child {
                padding-top: 8px;
              }

              .container-details-delivery-company {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img {
                  height: 18px;

                  &.logo {
                    height: 28px;
                    border-radius: 4px;
                  }
                }

                span {
                  font-family: "Heebo-Regular", sans-serif;
                  font-size: 16px;
                  color: $color-dark;
                  max-width: 140px;
                  text-align: left;
                }

                &.first {
                  width: 180px;
                  overflow-x: hidden;

                }

                &.second {
                  width: 60px;
                }

                &.third {
                  width: fit-content;
                }
              }
            }
          }

          &.select {
            box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);

            .container-selector {
              background-color: #D9F2FF;
            }

            .container-options {
              height: auto;
              box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);
              z-index: 1;
            }
          }
        }

        table {
          width: 100%;
          text-align: center;
          overflow: hidden;
          border-collapse: collapse;

          thead {
            tr {
              border-bottom: 1px solid $color-primary-variant;

              th {
                font-family: "Ubuntu", sans-serif;
                font-size: 16px;
                padding: 5px 0;
                box-sizing: border-box;
                color: $color-primary;
              }
            }
          }

          tbody {
            tr {
              cursor: pointer;

              td {
                font-family: "Heebo-Regular", sans-serif;
                font-size: 16px;
                padding: 5px 0;
                box-sizing: border-box;
                color: $color-dark-gray;

                img {
                  margin: 0 10px;
                  cursor: pointer;
                  width: 18px;
                }

                .red {
                  color: $color-error;
                }
              }
            }
          }
        }

        .data-sending__mobile-mode {
          width: calc(100% - 10px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          padding-top: 10px;
          font-family: "Heebo-Regular", sans-serif;
          font-size: 14px;
          margin-left: 10px;

          .send-text__mobile-mode {
            font-family: "Heebo-Regular", sans-serif;
            font-size: 14px;

            span {
              font-family: "Heebo-Medium", sans-serif;
            }
          }

          .container-select-delivery-company {
            margin-bottom: 24px;

            .container-options {
              max-width: 240px;
              overflow-x: hidden;

              .options-delivery-company {
                .container-details-delivery-company {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  img {
                    height: 18px;

                    &.logo {
                      height: 28px;
                      border-radius: 4px;
                    }
                  }

                  span {
                    font-family: "Heebo-Regular", sans-serif;
                    font-size: 16px;
                    color: $color-dark;
                    max-width: 140px;
                    text-align: left;
                  }

                  &.first {
                    width: 180px;
                    overflow-x: hidden;

                  }

                  &.second {
                    width: 60px;
                  }

                  &.third {
                    width: fit-content;
                  }
                }
              }
            }
          }

          .value-sending__mobile-mode {
            position: absolute;
            bottom: 0px;
            right: 10px;
            font-family: "Heebo-Medium", sans-serif;
            font-size: 14px;
            margin-left: 10px;
            color: $color-primary-variant;

            .number {
              font-family: "Heebo-Regular", sans-serif;
              color: $color-dark;
            }
          }

          .delete-sending__mobile-mode {
            position: absolute;
            top: 12px;
            right: 2px;
          }
        }
      }

    }

    .button-continue-conflicts {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 10px;
      box-sizing: border-box;

      button {
        max-width: 250px;
      }
    }

    &.full-height {
      border-top: 0px solid $color-primary-variant;
      height: calc(100% - 70px);

      .container-item-list-sendings {
        height: calc(100% - 80px);
      }
    }

    &.mobile-mode {
      border-top: none;
      height: 100%;
      margin: 0;
      width: 100%;

      .container-item-list-sendings {
        width: 100%;
        height: 100%;


        .item-list-sending {

          .name {
            margin-right: 10%;
            width: 100%;
          }

          &.selected {
            border-bottom: 1px solid $color-primary-variant;
          }

          .options {
            display: flex;
            align-items: center;
            cursor: pointer;

            img:first-child {
              margin-right: 10px;
            }
          }
        }
      }

      .button-continue-conflicts,
      .button-continue__mobile-mode {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        box-sizing: border-box;
        position: absolute;
        bottom: 20px;
        left: 0;

        button {
          max-width: 250px;
        }
      }
    }
  }

  .container_acept_buttons_payment {
    width: 100%;
    float: right;
    display: flex;
    flex-direction: row;
    padding: 16px 0 16px 16px;

    .checkbox-prohibitedItems {
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .container-buttons {
      width: auto;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;

      .counter-container {
        position: relative;
      }

      .counter {
        margin: 0 10px;
        width: 108px;
        height: 40px;
        display: flex;
        border-radius: 25px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: $color-background-light;
        color: $color-primary-hover-download-button;
        font-family: "Ubuntu-MediumItalic", sans-serif;

        &:hover {
          &+span {
            visibility: visible;
          }
        }
      }

      span {
        visibility: hidden;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 55px;
        right: -77px;
        z-index: 1;
        font-size: 14px;
        padding: 4px 0;
        width: 273.1px;
        height: 30px;
        background: white;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0px 3px 6px 2px $color-box-shadow-select;
        font-family: 'Heebo-Regular', sans-serif;
        color: $color-header-gray;

        p {
          font-size: 14px;
          color: $color-header-gray;
          font-family: 'Heebo-Bold', sans-serif;
        }

        &::after {
          content: "";
          position: absolute;
          top: 95%;
          left: 48%;
          margin-left: -1px;
          line-height: 10px;
          border-color: #fff transparent transparent;
          border-style: solid;
          border-width: 11px 5px 0px 7px;
        }
      }

      .button-download-csv {
        width: 152px;
        height: 40px;

        .right-image-button {
          width: 12.19px;
          height: 12.19px;
        }
      }

      .button-payment {
        width: 188px;
        height: 40px;
        margin: 0 0 0 30px;
      }
    }

  }
}

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .delete-icon {
    margin-left: 64%;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1920px) {
  .delete-icon {
    margin-left: 74%;
  }
}
