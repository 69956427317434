@import "../../../../themes/fonts";

#container-info-receiver {
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%;

  .container-info-receiver-shipping {
    display: flex;
    width: 60%;
    max-width: 742px;
    flex-direction: column;

    .content-items{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 75px;
      row-gap: 25px;

      .label {
        font-family: "Heebo-Regular", sans-serif;
        font-size: 14px;
        color: #707B84;
      }
    }

    .field-container {
      position: relative;

      .containter-select-all .container-options__select-general {
        width: 100%;
        margin-top: 8px;
      }
    }

    .divider {
      position: absolute;
      height: 121px;
      width: 1px;
      background-color: #ccc;
      right: -10%;
      top: 0;
    }

    .button-container {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      max-width: 240px;
      width: 240px;
    }

    &.mobile-mode {
      width: 100%;

      .title {
        width: 85%;
        margin: 0 auto;

        span{
          margin-top: 8px;
          text-align: left;
        }
      }

      .content-items{
        width: 85%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .field-container {
          width: 100%;

          .containter-select-all .container-options__select-general {
            width: 100%;
            margin-top: 8px;
          }
        }
      }

      .divider {
        display: none;
      }
    }
  }
}
