@import "../../../themes/fonts";
@import "../../../themes/colors";
@import "../../../themes/general";

.stepCompletingData-mexico {
  overflow-y: auto;
  width: 512px;
  padding: 24px 25px;
  box-sizing: border-box;

  .title {
    margin-bottom: 24px;

    .value {
      font-size: 14px;
    }
  }

  .wrapper-button {
    display: flex;
    justify-content: center;
    padding-top: 27px;

    .button-footer {
      width: 240px;
    }
  }

  &__list-fields {
    padding: 0 67px;
    margin-bottom: 25px;
  }

  .continue-button {
    width: 80%;
  }

  .modal-children-body {

    .container-options__select-general.primary {
      width: 280px;
    }

    #container-info-receiver {
      overflow-x: hidden;
      width: 100%;

      .container-info-receiver-shipping {
        width: 90%;

        .input-nit {
          display: flex;

          .line {
            display: flex;
            align-items: center;
            padding: 20px 5px 0 25px;
          }
        }

        .input-nit .container__input-all:nth-child(3) {
          .container__input-general {
            margin-top: 22px;
          }
        }
      }

      .button-container {
        margin: 0 auto;
        padding: 42px 0 5px 0;
      }
    }

    .containter-select-all.top-left {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
    .inputUserToUpdate-mexico {
      max-width: 280px;
      width: 280px;
      min-width: 280px;
      margin: 10px 0;

      .container-value-selected__select-general-mexico {
        width: 100%;
      }
      .container-options__select-general-mexico {
        width: 100%;
      }
    }
  }

  .mobile-mode {
    .stepCompletingData-mexico__list-fields {
      padding: 0 10px;
      margin-bottom: 25px;
    }
  }
}

.mobile-mode .stepCompletingData-mexico {
  padding: 25px 16px;
  width: 100%;

  .container-content-header{
    padding: 0 32px;
  }

  #container-info-receiver {
    .container-info-receiver-shipping {
      width: 95%;

      .title {
        width: 90%;
        padding: 0;

        span {
          text-align: center;
          padding-bottom: 15px;
        }
      }
    }
  }
}
