@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0 20px 0 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 90;

  * {
    transition: all 200ms ease;
  }

  &.hidden {
    display: none;
  }

  &.mobile-mode {
    width: auto;

    .custom-dropdown {
      padding-left: 18px;
      margin-top: 30px;

      .dropdown-header {
        background-color: white;
      }
    }

    #logo {
      width: 0;
      max-height: 100%;
      margin-left: 20px;
      z-index: 91;
      opacity: 0;
      visibility: hidden;
      cursor: pointer;

      &.visible {
        opacity: 1;
        visibility: visible;
        width: 100px;
      }
    }
  }

  #content-section {
    display: flex;
    align-items: center;
    max-width: 50%;
    opacity: 0;
    visibility: hidden;

    img {
      height: 24px;
      margin-right: 10px;
    }

    h1 {
      font-size: 20px;
      font-family: "Ubuntu-MediumItalic", serif;
      color: $color-dark;
      font-weight: 600;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  .custom-dropdown {
    .custom-options {
      transform: translateY(15%);
    }
  }

  &.desktop-mode {
    &.desktop-mode {
      .custom-dropdown {
        margin-top: 40px;

        .dropdown-header {
          background-color: white;
        }
      }
      #background-logo {
        &.background-mex {
          border-bottom-right-radius: 30px;
          background-color: $color-primary;
          display: flex;
          height: 120px;
          position: relative;
          transform: rotate(5deg);
          width: 200px;
          margin-left: -10px;
          margin-right: 44px;
          top: -10px;
          z-index: -9999;
          padding: 0 12px;
        }

        &.background-col {
          border-bottom-right-radius: 30px;
          display: flex;
          height: 120px;
          position: relative;
          transform: rotate(5deg);
          width: 200px;
          margin-left: -10px;
          margin-right: 44px;
          top: -10px;
          z-index: -9999;
          padding: 0 12px;
        }

        #logo {
          max-width: 50%;
          max-height: 100%;
          margin-left: 48px;
          z-index: 9999;
          cursor: pointer;
          position: relative;
          margin-top: 22px;
          transform: rotate(-5deg);

          &.visible {
            opacity: 1;
            visibility: visible;
            width: 100px;
          }
        }
      }
    }

    .container {
      display: flex;
      width: 58%;
      justify-content: space-between;

      ul {
        list-style: none;
        display: flex;
        align-items: center;

        li {
          font-family: "Ubuntu-MediumItalic", serif;
          font-size: 18px;
          color: $color-dark;
          margin: 0 10px;
          cursor: pointer;
        }

        .cards {
          width: 100%;
          margin-left: 5%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .someIcon {
    img {
      margin-left: 160px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1160px) {
  .hidden-between-1024-1160 {
    display: none;
  }
}
