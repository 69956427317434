@import '../../../themes/fonts.scss';
@import '../../../themes/colors.scss';

.left-side-menu-card{
  margin: 20px 0;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover, &.shadding{ background-color: rgba(255, 255, 255, .3) }
  &.active{ background-color: $color-light }

  .container-img__left-side-menu-card{
    height: 40px;
    width: 40px;

    img{ height: 100%; width: 100% }
  }

  span{
    font-family: 'Ubuntu-MediumItalic', serif;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: $color-dark;
  }
}

.left-side-menu-card-hover {
  .container-img-hover {
    display: flex;
    align-items: center;
  }
}
