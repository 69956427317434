@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";

.contentStepQuoteShipping {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content.stepOne {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .magnifying-glass {
      height: 20px !important;
      background-color: #b0b9c1;
      mask: url(../../../../assets/images/search.svg) no-repeat center / contain;
      -webkit-mask: url(../../../../assets/images/search.svg) no-repeat center / contain;

      &.focusOrange {
        background-color: $color-primary-variant;
      }
    }
  }

  .content.stepTwo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    height: 100vh;

    .containerMeasurementArticle {
      width: 40%;
      display: flex;
      align-items: center;
      margin: 10px 2.5%;

      img.box {
        height: 50px;
        width: 50px;
        margin-right: 10px;
      }

      .containter-select-all {
        margin-left: 10px;
      }
      &.articleWeight {
        .labelMeasurementArticle {
          font-size: 14px;
          font-family: "Heebo-Regular", sans-serif;
          color: $color-text-label-input;
        }

        .containerInputAndSelect {
          display: flex;
          align-items: center;

          .containter-select-all {
            .container-value-selected__select-general span {
              color: $color-primary-variant;
            }
            .container-options__select-general {
              top: 0;
            }
          }
        }
      }
    }
  }

  .content.stepThree {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 28px;

    span {
      margin-top: 10px;
    }

    .containerData {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;

      p {
        font-family: "Heebo-Medium", sans-serif;
        &:last-child {
          font-family: "Heebo-Regular", sans-serif;
          color: #535A5F;
        }
      }
    }

    .containerMainData {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;

      p {
        font-family: "Heebo-Medium", sans-serif;

        &:last-child {
          font-family: "Heebo-Regular", sans-serif;
        }
      }
    }
  }

  .containerButtonsStepsQuoteShipping {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    button {
      margin: 10px;
    }
  }
}

@media only screen and (min-width: 1024px) {

  .container-list__deliveries-list-quote-shipping {
    overflow-x: hidden;
    height: 100%;
  }
  .contentStepQuoteShipping-mobile {
    width: 45%;
    padding-bottom: 15px;
      .content.stepThree-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    border-right: 1px solid $color-primary;

    span {
      margin-top: 15px;
      display: flex;
      width: 80%;
      align-items: flex-start;
    }

  .adjust-title {
    margin: 4% 0 2% 0;
  }

    .containerData {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0 0;
      padding: 5px 0 0;
      border-bottom: 0.5px solid #00000029;

      .collectioIcon {
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          img {
            padding: 0 10px 5px 0;
          }
        }

      p {
        font-size: 14px;
        font-family: "Heebo-Medium", sans-serif;

        &:last-child {
          font-size: 16px;
          font-family: "Heebo-Regular", sans-serif;
          color: #535A5F;
        }
      }
    }

    .containerMainData {
      width: 80%;
      margin: 0;
      padding: 5px 0;

      p {
        font-size: 14px;
        font-family: "Heebo-Medium", sans-serif;
        border-bottom: 0.5px solid #00000029;

        &:last-child {
          border: none;
          font-size: 16px;
          margin-top: 10px;
          font-family: "Heebo-Regular", sans-serif;
          color: #535A5F;
        }
      }
    }
  }
  }
  .contentStepQuoteShipping {
      width: 100%;

    .content.stepOne {
      width: 60%;
      max-width: 400px;
    }

    .containerButtonsStepsQuoteShipping {
      flex-direction: row;
      padding-top: 0;

      button {
        max-width: 300px;
        &.widthless {
          height: 38px;
          min-height: 38px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .contentStepQuoteShipping {
    max-height: max-content;

    &.stepTwo {
      top: 20%;
      position: relative;
    }

    @media only screen and (max-height: 700px) {
      &.stepTwo {
        margin-top: 90px;
      }
    }

    .content.stepOne {
      width: 80%;
    }
    .content.stepTwo {
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .containerMeasurementArticle {
        width: 80%;
      }
    }
    .content.stepThree .containerData {
      width: 65%;
    }

    .content.stepThree .containerMainData {
      width: 65%;
    }

    .containerButtonsStepsQuoteShipping {
      flex-direction: column-reverse !important;
      padding: 18px;
      overflow: hidden;
      button {
        width: 90%;
        max-width: 240px;
      }
    }
  }
}

/* ----------------------------------------------------------------------------- */
#step-four-quote-shipping {
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .container-top__step-four-quote-shipping {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
  }

  &.mobile-mode {
    .container-top__step-four-quote-shipping {
      width: 80%;
    }
  }

  &.desktop-mode {
    .container-top__step-four-quote-shipping {
      width: 60%;
      max-width: 400px;
    }
  }
}

#collection-service-quote-shipping {
  border-radius: 12px;
  background: $color-light;
  height: calc(100% - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: calc(100% - 40px);
  // margin: 20px;
  // overflow: auto;
  // box-shadow: 0px 4px 14px -4px rgba(0, 0, 0, 0.5);
  // overflow: auto;

  .container-top__collection-service-quote-shipping {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title__collection-service__modal {
      margin: 10px 0 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;

      .back__collection-service-quote-shipping {
        height: 24px;
        width: 24px;

        &img {
          cursor: pointer;
        }
      }

      .center {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 10px;
          width: 40px;
        }
      }
    }

    .body__collection-service__modal {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;

      .strict-width {
        width: 100%;
        max-width: 290px;
      }

      .attach_money {
        width: 29px;
        height: 22px;
        background-color: #b0b9c1;
        mask: url(../../../../assets/images/attach_money.svg) no-repeat center / contain;
        -webkit-mask: url(../../../../assets/images/attach_money.svg) no-repeat center / contain;

        &.focusOrange {
          background-color: $color-primary-variant;
        }
      }
    }
  }

  &.mobile-mode {
    .title__collection-service__modal {
      width: 100%;
      padding: 0;
      justify-content: space-between;

      .left {
        width: 25%;

        &.icon-row-left {
          width: 29px;
          height: 22px;
          background-color: $color-primary-variant;
          mask: url(../../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
          -webkit-mask: url(../../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
        }
      }
      .center {
        max-width: 64%;
      }
    }

    .body__collection-service__modal {
      width: 80%;

      .body__collection-service__check-box {
        margin-top: 20px;

        .container-checkbox-and-label {
          .text-checkbox {
            font-size: 14px;
            color: $color-text-label-gray;
          }
        }
      }

      .body__collection-service__check-box {
        .container-checkbox-and-label {
          .container-checkbox {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &.desktop-mode {
    .title__collection-service__modal {
      width: 100%;
    }

    .body__collection-service__modal {
      max-width: 400px;

      .body__collection-service__check-box {
        margin-top: 50px;
        margin-bottom: 100px;
      }
    }
  }
}

#deliveries-list-quote-shipping {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: transparent !important;

  .container-list__deliveries-list-quote-shipping {
    background: $color-light;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .checkbox-add-shipping-to-collection-value {
    background-color: white;
    box-sizing: border-box;
    padding: 10px 20px;
    padding-right: 22px;
    border-radius: 12px;
    margin: 0 auto;

    .container-checkbox-and-label {
      justify-content: center;
    }
  }

  .container-message-irp-delivery-list {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 14px;
    margin-bottom: 12px;

    div {
      margin-left: 5px;
    }
  }

  .color-blue {
    color: $color-accent-variant;
  }

  &.desktop-mode {
    .container-list__deliveries-list-quote-shipping {
      padding: 19px 10px;

      &::-webkit-scrollbar {
        width: 12px;
      }
    }
  }

  &.mobile-mode {
    .container-list__deliveries-list-quote-shipping {
      padding: 18px 8px;
    }

    &.list-with-checkbox-bottom {
      .container-list__deliveries-list-quote-shipping {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        height: calc(100% - 90px) !important;
      }
      .checkbox-add-shipping-to-collection-value {
        width: calc(100% - 40px);
        position: fixed;
        bottom: 20px;
        left: 20px;

        .container-checkbox-and-label {
          justify-content: center;
        }
      }
    }
    .container-message-irp-delivery-list {
      margin-left: 4px;
      padding-right: 8px;
    }
  }
}

#deliveries-list-quote-shipping-mobile {
  width: 55%;
  height: 100%;
  overflow: hidden;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent !important;

    .checkbox-add-shipping-to-collection-value {
        box-sizing: border-box;
        padding: 10px 0px;
        padding-right: 22px;
        border-radius: 12px;
        margin: 0 auto;

        .container-checkbox-and-label {
          justify-content: center;
        }
      }

    .container-message-irp-delivery-list {
        display: flex;
        align-items: center;
        margin: 11px;

      .enter-here {
        cursor: pointer;
        color: $color-accent-variant;
        text-decoration: underline $color-accent-variant;
      }

        div {
          margin-left: 5px;
        }
      }
}

.body__payment-shipping {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .second-body__payment-shipping {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;

    .container__second-body__payment-shipping {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding: 20px 0;

      .content-top-bottom-or-side__container__second-body__payment-shipping {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 350px;

        .delivery-company-info__payment-shipping {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 40px;
          border-bottom: 1px solid $color-primary;

          .flexbox-delivery-data {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
              width: 75px;
              height: 75px;
              margin-right: 10px;
              border-radius: 8px;
            }

            .text-delivery-data {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;

              .delivery-name__delivery-data {
                font-size: 18px;
              }

              .shipping-time__delivery-data {
                font-size: 16px;
                color: $color-accent;
              }
            }
          }

          .flexbox-column-delivery-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
        }

        .balance-info__payment-shipping {
          width: 100%;
          padding-top: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .current-balance {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .current-balance-value {
              color: $color-accent;

              &.warning-cash {
                color: $color-error;
              }
            }
          }

          button {
            margin: 20px 0;
            width: 100%;
            max-width: 250px;
          }

          .min-recharge-info {
            color: $color-accent-variant;
          }
        }

        .container-payment-method__sender-details {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            margin-top: 20px;
            width: 100%;
            max-width: 250px;
          }
        }
      }
    }
  }

  &.mobile-mode {
    .second-body__payment-shipping {
      .container__second-body__payment-shipping {
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 0;

        .content-top-bottom-or-side__container__second-body__payment-shipping {
          .delivery-company-info__payment-shipping {
            padding: 20px 20px;
            box-sizing: border-box;
          }

          .balance-info__payment-shipping {
            padding: 20px 20px;
            box-sizing: border-box;
            border-bottom: 1px solid $color-primary;
          }

          .container-payment-method__sender-details {
            padding: 20px 20px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.container-buttons__steps-quote-shipping {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  button {
    margin: 10px;

    &.custom-width {
      height: 38px;
      min-height: 38px;
    }
  }

  &.desktop-mode {
    flex-direction: row-reverse;
    padding-top: 0;

    button {
      max-width: 300px;
    }
  }

  &.mobile-mode {
    padding: 18px;
    button {
      &.custom-width {
        width: 90%;
        max-width: 240px;
        height: 50px;
        min-height: 50px;
      }

      &:last-child {
        &.custom-width {
          height: 40px;
          min-height: 40px;
        }
      }
    }
  }
}
