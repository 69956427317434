@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.updateDirection {
  width: 494px;
  height: 534px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .centerTitleModal {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .italic {
      font-size: 20px;
      font-family: "Ubuntu-MediumItalic", serif;
      margin-bottom: 10px;
    }

    .paragraph {
      width: 311px;
      font-size: 14px;
      font-family: "Heebo-Regular", serif;
    }
  }

  .edit-center {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .edit-input-top {
      width: 280px;
      height: 90px;
      padding: 8px 0;
    }

    .edit-input-mid {
      width: 280px;
      height: 80px;
      padding: 8px 0;
    }

    .edit-input-bottom {
      width: 280px;
      height: 85px;
      padding: 8px 0;
    }
  }

  .edit-buttonModal {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 30px;

    #cancelModal {
      width: 152px;
    }

    #saveModal {
      width: 206px;
    }
  }

  label {
    margin-top: 16px;
  }

  .iconClose {
    width: 13.18px;
    height: 13.18px;
    position: relative;
    mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
    cursor: pointer;
    background-color: $color-primary-variant;
    margin-left: 460px;
    margin-bottom: 0px;
  }

}

.confirmSaveDirectionUpdate {
  display: flex;
  height: 68px;
  width: 500px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .italic {
    font-family: "Ubuntu-MediumItalic", serif;
  }
}

@media only screen and (max-width: 800px) {
  .updateDirection {
    width: 303.73px;
    height: 555px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .centerTitleModal {
      margin: 0;
      display: flex;
      justify-content: center;

      .italic {
        width: 220px;
        text-align: center;
        font-size: 20px;
        font-family: "Ubuntu-MediumItalic", serif;
      }

      .paragraph {
        width: 250px;
        font-size: 14px;
        font-family: "Heebo-Regular", serif;
      }
    }

    .edit-center {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .edit-input-top {
        width: 240.5px;
        height: 90px;
        padding: 4px 0;
      }

      .edit-input-mid {
        width: 240.5px;
        height: 80px;
        padding: 4px 0;
      }

      .edit-input-bottom {
        width: 240.5px;
        height: 85px;
        padding: 4px 0;
      }
    }

    .edit-buttonModal {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      align-items: center;
      margin-top: 38px;

      #cancelModal {
        margin-top: 10px;
        width: 206px;
        height: 42px;
      }

      #saveModal {
        width: 206px;
      }
    }

    label {
      margin-top: 16px;
    }

    .iconClose {
      width: 13.18px;
      height: 13.18px;
      position: absolute;
      mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
      cursor: pointer;
      margin-left: 270px;
      margin-bottom: 500px;
    }

  }

  .confirmSaveDirectionUpdate {
    height: 68px;
    width: 316px;
    text-align: center;
  }
}
