@import "src/themes/fonts";
@import "src/themes/colors";

.delivery-card-to-list {
  position: relative;
  background-color: $color-light;
  cursor: pointer;
  box-sizing: content-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1.01);
    z-index: 3;
  }

  .container__content-top-and-content-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-bottom: 18px;
  }

  .details-collection-service {
    display: none;
  }

  .content-top__delivery-card-to-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 6px 0;
    width: calc(100% - 20px);

    .container-delivery-info {
      display: flex;
      align-items: flex-start;

      .delivery-img {
        height: 51px;
        width: 51px;
        margin-right: 16px;
        margin-top: 4px;
        border-radius: 8px;
        position: relative;
      }

      .content-delivery-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: normal;
        padding-right: 10px;
        box-sizing: border-box;

        .delivery-name {
          font-family: "Heebo-Medium", serif;
          color: $color-dark;
          font-size: 16px;
        }

        .delivery-time {
          font-family: "Heebo-Regular", serif;
          color: $color-header-gray;
          font-size: 14px;
          margin: 4px 0;
          text-align: left;

          .blue {
            font-weight: bold;
            color: #009af0;
          }

          .days-delivered {
            font-family: "Heebo-Medium", serif;
          }
        }

        .delivery-score {
          font-family: "Heebo-Regular", serif;
          color: $color-text-value;
          font-size: 14px;
          display: flex;
          align-items: center;

          .routeType {
            width: auto;
            font-size: 12px;
            padding: 4px 18px;
            color: $color-dark;
            border-radius: 25px;
            justify-content: center;
            font-family: "Ubuntu-Regular", sans-serif;
            background-color: rgba(162, 170, 172, 0.3);
            margin-top: 6px;
          }
          .routeType-express {
            width: auto;
            font-size: 12px;
            padding: 4px 18px;
            color: $color-dark;
            border-radius: 25px;
            justify-content: center;
            font-family: "Ubuntu-Regular", sans-serif;
            background-color: $color-primary;
            margin-top: 6px;
          }

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;

            & ~ div {
              height: 21px;
              display: flex;
              align-items: center;

              p {
                padding-top: 2px;
              }
            }
          }
        }
      }

      .content-collection-service {
        height: 112px;
        position: relative;

        .delivery-score {
          gap: 7px;
          position: absolute;
          bottom: 0;
        }
      }
    }

    .shipping-value {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 6px;

      .score {
        display: flex;
        gap: 5px;
      }
      .container-amount-without-discount {
        display: flex;
        flex-direction: column;
        margin-top: 4px;

        &__without-real-value {
          display: flex;
          justify-content: end;

          .amount {
            text-decoration: none;
            font-size: 12px;
            color: $color-header-gray;
            font-family: "Heebo-Regular", serif;
          }
        }
      }

      &__delivery {
        .discount {
          color: $color-success-payments;
        }
        .real {
          font-size: 14px;
          color: $color-header-gray;
          display: flex;
          justify-content: flex-end;
          text-decoration: line-through;
        }
      }

      &__mount-score {
        display: flex;
        align-items: end;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        min-height: 82px;

        .score {
          display: flex;
          gap: 6px;

          p {
            font-family: "Heebo-Regular", serif;
            color: #7B8287;
          }
        }
      }

      .container-amount-discount {
        display: flex;
        flex-direction: column;
        text-align: end;
        align-items: end;

        &__values {
          display: flex;
          gap: 8px;
          width: max-content;

          .amount, .without-discount {
            font-family: "Heebo-Regular", serif;
            text-decoration: none;
            font-size: 12px;
            color: $color-header-gray;
          }

          .amount-discount {
            font-size: 12px;
            color: #058B09;
          }

          .previous-amount {
            text-decoration: line-through;
            font-size: 12px;
            color: $color-header-gray;
          }
        }

        &__text {
          font-size: 12px;
          color: $color-header-gray;
        }
      }
    }
  }

  .content-bottom__delivery-card-to-list {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;

    .collection-value {
      font-family: "Heebo-Regular", serif;
      color: $color-text-label;
      font-size: 14px;
    }
  }

  hr {
    position: absolute;
    bottom: 0;
    left: 18%;
    width: 64%;
    border: none;
    border-top: 1px solid $color-dark-light;
  }

  &.desktop-mode {
    margin: 0.5em;

    .content-top__delivery-card-to-list {
      width: calc(100% - 8px);
    }
  }

  &.mobile-mode {

    &:hover {
      background-color: transparent;
      z-index: 0;
    }

    .container__content-top-and-content-bottom {
      width: calc(100% - 10px);

      hr {
        position: static;
        width: 75%;
        left: auto;
      }

     .content-top__delivery-card-to-list {
      flex-direction: column;
       width: 100%;

       .delivery-time {
         font-family: "Heebo-Regular", serif;
         color: $color-header-gray;
         font-size: 14px;
         margin: 4px 0;

         .blue {
           font-weight: bold;
           color: #009af0;
         }

         .days-delivered {
           font-family: "Heebo-Medium", serif;
         }
       }

      .delivery-score {
        display: flex;
        margin: 0 auto;
        gap: 10px;

        .routeType {
          width: auto;
          font-size: 12px;
          padding: 4px 10px;
          color: $color-dark;
          border-radius: 25px;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: rgba(162, 170, 172, 0.3);
        }
        .routeType-express {
          width: auto;
          font-size: 12px;
          padding: 4px 10px;
          color: $color-dark;
          border-radius: 25px;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: $color-primary;
        }
      }

      .content-delivery-info {
        margin-top: 5px;
        align-items: normal;

          &__value-collection {
            font-family: "Heebo-Medium", serif;
            color: $color-dark;
            font-size: 16px;
            display: flex;
            align-items: flex-start;
          }

          &__name-value {
            display: flex;
            gap: 10px;
            margin-top: 8px;
          }

          .shipping-value__mount-score {
            min-height: 0;
          }

          .delivery-time {
            text-align: left;
            display: revert;
          }
        }
      }
    }
  }
}

.delivery-card-to-list:hover .details-collection-service {
  z-index: 10;
  padding: 0 20px 10px 20px;
  width: 235px;
  height: auto;
  display: block;
  position: absolute;
  right: 6%;
  top: 60%;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  background: #FFFFFF 0 0 no-repeat padding-box;
}
