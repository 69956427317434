@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";

.toggleFilter {
  height: 13rem;
  width: 20rem;
  background: #fff;
  border: #f08700 solid 2px;
  border-radius: 7px;
  position: absolute;
  right: 2.5rem;
  bottom: 3.5rem;
  transition-duration: 0.5s;
  z-index: 99;

  .conten-herader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30px;
    width: 100%;

    button {
      height: 40px;
      width: 40px;
      position: absolute;
      right: 0;
      top: 0;
      color: #f08700;
    }

    .FilterText {
      margin: 0;
      padding: 0;
      margin-top: 10px;
      text-align: center;
      color: #f08700;
    }
  }
}

.toggleFilterMobile {
  height: 13rem;
  width: auto !important;
  min-width: 300px;
  background: #fff;
  border: #f08700 solid 2px;
  border-radius: 7px;
  position: absolute;
  right: 2rem;
  top: 9rem;
  transition-duration: 0.5s;
  z-index: 99;

  .conten-herader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30px;
    width: 100%;

    button {
      height: 40px;
      width: 40px;
      position: absolute;
      right: 0;
      top: 0;
      color: #f08700;
    }

    .FilterText {
      margin: 0;
      padding-left: 100px;
      width: 100px;
      margin-top: 10px;
      text-align: center;
      color: #f08700;
    }
  }
}

.noToggleFilter {
  height: 0px;
  width: 0px;
  background: #fff;
  border: #f08700 solid 0px;
  border-radius: 7px;
  position: absolute;
  right: 140px;
  bottom: 60px;
  transition-duration: 0.5s;

  button {
    display: none;
  }

  h1 {
    display: none;
  }
}

.toggleFilterButton {
  padding: 12px;
  width: 190px !important;
  height: 40px;
  border: #f08700 solid 1px;
  border-radius: 50px;
  box-sizing: border-box;

  .toggleFilterText {
    text-align: center;
    font-size: 16px;
    color: #f08700;
  }

  .iconFilter {
    margin: 10px;
  }
}

.toggleFilterButtonMobile {
  padding: 12px;
  width: 34px !important;
  height: 34px;
  border: #f08700 solid 1px;
  border-radius: 50px;
  box-sizing: border-box;

  .toggleFilterText {
    text-align: center;
    font-size: 16px;
    color: #f08700;
  }

  .iconFilter {
    margin: 10px;
  }
}

.noContainer {
  display: none;
}

.contenFilter {
  margin: 30px;
  height: 65%;

  .calendar {
    display: flex;
    height: 34px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 200ms ease;

    h2 {
      padding-left: 16px;
    }

    &.cursorP {
      cursor: pointer;
    }

    .closeImage {
      background: transparent url(../../../../assets/images/cancel.svg)
        no-repeat center / contain;
      width: 24px;
      height: 24px;
      margin-left: 8px;
      cursor: pointer;
    }

    .selectedDates {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .dateTextClass {
      color: #afb4b8;
      font-weight: 700;
      font-size: 16px;
    }
  }
}
