@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

#shipping-details {
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;
    height: 50px;
    width: 60%;
    margin-top: 20px;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-family: "Ubuntu-Medium", serif;
        font-size: 20px;
        color: $color-primary-variant;
        margin: 5px 0;
        text-align: center;

        &.color-ligth {
          color: $colr-ligth-gray;
          margin: 0;
        }
      }
    }

    .rigth {
      min-width: 24px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 120px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100%;

    .side-top-or-bottom-container {
      width: 90%;
      margin: 0 5%;
      max-width: unset;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      box-sizing: border-box;

      &.left {
        justify-content: flex-start;
        padding: 0 12%;

        & > div {
          width: 100%;

        }
      }

    }
  }
}

@media only screen and (max-width: 1023px) {
  .content-section__header {
    .content-section__content-header {
      .header-center {
        &.left {
          width: calc(100% - 30px);
          h1 {
            font-size: 16px;
          }
        }
      }
    }
  }
  #shipping-details {
    .body {
      .header.public {
        width: unset;
      }
      .side-top-or-bottom-container {
        flex-direction: column;

      }
    }
  }
}
