@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

$height-and-width: 36px;
$height-and-width-content: 14px;

.container-checkbox-and-label{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;

  .container-checkbox{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $height-and-width;
    width: $height-and-width;

    .background-checkbox{
      position: absolute;
      border-radius: 50%;
      opacity: .2;
      background-color: transparent;
      width: $height-and-width-content;
      height: $height-and-width-content;

      &.checked{
        animation-name: checked;
        animation-duration: 400ms;
      }

      &.no-checked{
        animation-name: no-checked;
        animation-duration: 400ms;
      }

    }

    &:hover {
      background-color: $color-primary-variant-light;
      border-radius: 50%;

      .checkmark {
        border-color: $color-primary;
      }
    }

    input{
      position: absolute;
      cursor: pointer;
      display: none;
    }

    .checkmark {
      position: absolute;
      height: $height-and-width-content;
      width: $height-and-width-content;
      border-width: 2px;
      border-style: solid;
      border-color: $color-text-label-input-fiel;
      background-color: transparent;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        position: absolute;
        content: "";
        display: none;
        width: 2px;
        top: -1px;
        height: 5px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.blue{
        border-color: $color-accent-variant-register;
      }
    }

    input{
      &:checked ~ .checkmark{
        background-color: $color-primary;
        border-color: transparent;

        &:after {
          display: block;
        }
      }

      &:checked ~ .checkmark.blue{
        background-color: $color-accent-variant-register;
        border-color: $color-accent-variant-register;

        &:after {
          display: block;
        }
      }
    }
  }

  span.text-checkbox, span.second-text-checkbox{
    font-family: "Heebo-Regular";
    font-size: 14px;
    color: $color-general-text;

    &.color-blue{
      color: $color-accent;
    }

    &.color-gray {
      color: $colr-ligth-gray;
    }
  }

  span.second-text-checkbox {
    margin-left: 4px;
  }
}

@keyframes checked {
  0% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
  50%{
    background-color: $color-primary-variant;
    width: $height-and-width;
    height: $height-and-width;
  }
  100% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
}

@keyframes no-checked {
  0% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
  50%{
    background-color: $color-dark-light;
    width: $height-and-width;
    height: $height-and-width;
  }
  100% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
}

.annotation__checkbox-general {
  width: 0;
  height: auto;
  font-size: 12px;
  font-family: 'Heebo-Regular';
  color: $color-dark;
  margin-top: 2px;

  &.focus{
    color: $color-accent-variant;
  }

  &.error{
    font-size: 0;
    color: $color-error;

    &.visible{
      width: 100%;
      font-size: 14px;
    }
  }
}
