@import "../../../../themes/fonts";
@import "../../../../themes/colors";
@import "../../../../themes/general";

.alertIncompleteData-mexico{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  visibility: hidden;
  opacity: 0;
  width: 640px;
  left: calc(50vw - 400px);
  background: white;
  box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 10px 14px;
  z-index: 500;

  &.visible{
    top: 120px;
    visibility: visible;
    opacity: 1;
  }

  div{
    display: flex;
    align-items: center;

    button{
      width: 180px;
      min-height: 34px;
      height: 34px;
      max-height: 34px;
    }
  }

  .iconClose-mexico{
    height: 14px;
    width: 14px;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;

    &:hover:not(.disabled){ background-color: rgba(255, 156, 25, .3) }
    &.disabled{ cursor: initial; filter: grayscale(1) }
  }
}

.modalIncompleteData-mexico .modal-children-footer button{
  width: 40% !important;
}

.incompleteDataText-mexico{
  font-family: 'Heebo-Regular', sans-serif;
  font-size: 16px;
  margin-right: 12px;

  span{
    margin-right: 4px;
    &:last-child{
      color: $color-dark-title;
      cursor: pointer;
    }
  }
}
