@import "../../themes/colors.scss";

$min-height-header: 34px;
$measure-image-close-modal-children: 14px;

.modal-children{
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;

  .modal-children-header{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .padding-left{
      width: 30px;
    }

    .container-content-header{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: $min-height-header;
      margin: 0 auto;

      .title-modal-children-header{
        width: 100%;
        text-align: center;
      }
    }

    button{
      width: fit-content;
      height: fit-content;
      padding: 8px;
      border-radius: 50%;
      margin-top: calc((#{$min-height-header} - #{$measure-image-close-modal-children}) / 2 - 8px);

      svg{
        .st0{ opacity: 1 }
        width: $measure-image-close-modal-children;
        height: $measure-image-close-modal-children;
        cursor: pointer;
      }

      &:hover{ background-color: rgba(255, 156, 25, 30%) }
      &.disabled{ cursor: initial; opacity: .25 }
    }
  }

  .modal-children-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .modal-children-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    .container-buttons-footer{
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-evenly;

      button{
        min-width: 250px;
        max-width: 300px;
        margin: 0 20px;
      }
    }
  }

  &.mobile-mode{
    .modal-children-header{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &.padding-left{
        box-sizing: border-box;
        padding-left: 34px;
      }

      .container-content-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: $min-height-header;

        .title-modal-children-header{
          width: 100%;
          text-align: center;
        }
      }

      img{
        width: $measure-image-close-modal-children;
        margin-left: 20px;
        margin-top: calc((#{$min-height-header} - #{$measure-image-close-modal-children}) / 2);
        cursor: pointer;

        &.disabled{
          cursor: initial;
          opacity: .25;
        }
      }
    }

    .modal-children-footer{
      .container-buttons-footer{
        flex-direction: column;

        button{
          width: 95%;
          min-width: none;
          max-width: none;

          &:nth-child(2){
            width: 85%;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
