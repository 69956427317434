@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";

.delivery-company {
  width: 45%;
  max-width: 310px;

  .center-text {
    text-align: center;
    font-size: 16px;
  }

  .delivery-company-info {
    display: flex;
    margin: 16px 0;
    justify-content: center;

    .delivery-company-image {
      width: 60px;
      height: 60px;
      margin-right: 8px;

      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .content-delivery-info-detail {
      .delivery-time {
        display: flex;

        .value {
          color: $color-header-gray;
        }

        .size {
          font-size: 14px;
        }

        .shipping-time {
          margin-left: 2px;
          font-family: "Heebo-Medium", serif;
        }
      }
    }
  }

  .shipping-value-info {
    display: flex;
    justify-content: space-between;

    &__discount {
      display: flex;
      flex-direction: column;

      .discount {
        color: $color-success-payments;
      }

      .real {
        color: $color-header-gray;
        font-size: 14px;
        text-decoration: line-through;
      }
    }

    span {
      color: $color-dark;
      font-family: "Heebo-Medium";
    }
  }

  .show-shipping-info-detail {
    margin: 10px 0;
    display: flex;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: "Heebo-Medium", serif;
      font-size: 16px;
      color: #0072C3;

      .image {
        mask: url(../../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
        -webkit-mask: url(../../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
        width: 25px;
        height: 25px;
        background: $color-accent 0% 0% no-repeat padding-box;
      }
    }
  }

  .shipping-values-detail {
    margin-top: 15px;

    .freight-value {
      display: flex;
      justify-content: space-between;
      margin: 4px 0;

      span {
        font-size: 14px;
        color: $color-header-gray;
      }
    }
  }

  .show-collection-service-info {
    text-align: center;

    span {
      color: $color-primary-variant;
      text-align: center;
      font-family: "Heebo-Medium", serif;
    }
  }

  .container-checkbox-and-label {
    justify-content: center;
    margin: 26px 0;

    span {
      font-size: 14px !important;
      color: $color-text-title-gray !important;
    }
  }

  .totals-information {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;

    div {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        color: $color-header-gray;
      }

      &.shipping-value {
        margin: 8px 0;
      }

      &.totals {
        h2 {
          font-size: 16px;
          font-family: "Heebo-Medium", serif;
        }
      }
    }
  }

  &.mobile-mode {
    width: 100%;
    max-width: 100%;

    .delivery-company-info {
      .delivery-company-image {
        margin-right: 28px;
      }

      .content-delivery-info-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.shipping-time {
          margin-left: 4px;
        }
      }

      .shipping-value-info {
        justify-content: space-around;
      }
    }

    .show-shipping-info-detail {
      margin: 16px 0;
      margin-bottom: 24px;
    }

    .container-checkbox-and-label {
      justify-content: center;
      margin: 26px auto;
      width: 90%;

      span {
        font-size: 14px;
        color: $color-text-title-gray;
      }
    }

    .totals-information {
      .toals-box {
        width: 92%;
        max-width: 300px;
        border-radius: 5px;
        border: 1px solid $color-primary-variant;
        padding: 16px 11px;
        display: block;
        margin-bottom: 15px;

        .container-values {
          display: none;
          margin-top: 14px;

          &.show {
            display: block;
          }

          .shipping-value {
            margin-bottom: 0;
          }
        }
      }
    }

    .show-collection-service-info {
      span {
        font-size: 14px;
      }
    }

    .container-checkbox-and-label {
      .container-checkbox {
        margin-right: 10px;
      }
    }
  }

  &.desktop-mode {
    .shipping-value-info {
      &__discount {
        .real {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.sender-info-general {
  width: 45%;
  max-width: 370px;

  .label-document {
    font-family: "Heebo-Regular", sans-serif;
    font-size: 14px;
    color: #353A3D;
  }

  .sender-info {

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      h2 {
        font-family: "Heebo-Medium", serif;
        font-size: 16px;
      }

      .change-button {
        span {
          color: $color-accent;
          cursor: pointer;
        }
      }

    }

    .subtitle2 {
      span {
        color: $color-accent;

      }
    }

    .custom-size {
      max-width: 240px;
      margin: 23px auto;
    }

    .direction-select-container {
      display: flex;
      justify-content: center;
      position: relative;
    }
  }

  .product-description {
    margin-top: 33px;
    position: relative;

    .fake-tap-button {
      position: absolute;
      height: 60px;
    }

    .container__input-all {
      .span__input-general {
        &.disabledFirstSpan {
          color: $color-dark;
        }
      }

      .content-top__input-general {
        input:disabled {
          color: $color-header-gray;
        }

        img {
          margin: 0;
          padding: 0 2px;
          height: 24px;
          width: 24px;
        }

        img.cursor-pointer:hover {
          background-color: transparent;
        }

        .search-icon {
          width: 29px;
          height: 22px;
          mask: url(../../../assets/images/search.svg) no-repeat center / contain;
          -webkit-mask: url(../../../assets/images/search.svg) no-repeat center / contain;
          background-color: #B7BCC1;
        }

        .search-icon {
          width: 29px;
          height: 22px;
          mask: url(../../../assets/images/search.svg) no-repeat center / contain;
          -webkit-mask: url(../../../assets/images/search.svg) no-repeat center / contain;
          background-color: #B7BCC1;
        }
      }
    }
  }

  .office-address-info {
    display: flex;
    align-items: center;
    background-color: #F0870033;
    border-radius: 10px;
    padding: 10px;
    gap: 8px;

    img {
      width: 24px;
      height: 24px;
    }

    &__text {
      font-family: "Heebo-regular", serif;
      font-size: 14px;
      color: $color-dark;
    }
  }

  .cursor-pointer {
    label {
      cursor: pointer;
    }

    .value {
      width: 50%;
    }
  }

  .address {
    width: 100%;
    margin-bottom: 15px;

    .text-end2 {
      margin-left: -15px;
    }

    input {
      &:disabled {
        background-color: transparent;
      }
    }
  }

  &.mobile-mode {
    width: 100%;
    max-width: 100%;

    .cursor-pointer {
      .value {
        width: 70%;
      }
    }

    .recommendations {
      margin: 24px 0;
    }
  }

  .agency-input {
    margin-top: 18px;
  }
}
