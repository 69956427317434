.modal-ads {

  .modal-children {
    padding: 44px;
    padding-top: 20px;
  }

  .modal-ads-wrapper {
    img {
      max-height: 429px;
      max-width: 642px;
      object-fit: contain;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 590px) {
    .modal-ads-wrapper {
      max-width: 290px;
    }
  }

  @media only screen and (max-width: 460px) {
    .modal-children {
      padding: 22px 24px 40px 24px;

      min-width: 300px;
      min-height: 400px;

      max-width: 95%;
      max-height: 90%;
    }

    .modal-ads-wrapper {
      img {
        max-width: 280px;
        max-height: 460px;
        object-fit: contain;
      }
    }
  }

}
