@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";
@import "../../../../themes/general.scss";

.receipt-container {
  height: 100%;
  width: 90%;
  margin: 0 auto;
}

#receipt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  justify-content: space-around;

  #header__receipt-container {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 2px solid $color-primary;
  }

  #body__receipt-container {
    width: 100%;

    .bank-info {
      margin: 20px 0;

      .row {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 12px 0;

        .inner-row {
          display: flex;
          width: 44%;

          &.rigth {
            justify-content: flex-end;
          }

          &.column {
            flex-direction: column;
            .rigth {
              margin: 0;
            }
          }

          .left,
          .rigth {
            font-size: 14px;
          }

          .left {
            font-family: "Heebo-Medium", sans-serif;
            color: #000000;
          }

          .rigth {
            font-family: "Heebo-Regular", sans-serif;
            color: $color-header-gray;
          }

          .m4 {
            margin-left: 4px;
          }
        }

      }
    }

    .price-info {
      margin-top: 46px;
      font-size: 16px;

      &:last-child {
        margin-top: 0;
      }

      .row {
        .inner-row {
          .left,
          .rigth {
            font-size: 16px;
          }
        }
      }

      .green {
        color: $color-success-payments !important;
      }

      .red {
        color: $color-error !important;
      }
    }
  }

  #body__receipt-button {
    width: 100%;

    .button {
      width: 100%;
      max-width: 240px;
      margin: 0 auto;

      a{
        color: $color-light;
        height: 42px;
        min-height: 42px;
        border-radius: 25px;
        background: rgba(0, 154, 240, 1);
        background: -moz-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
        background: -webkit-gradient(
          left top,
          right top,
          color-stop(0%, rgba(0, 154, 240, 1)),
          color-stop(100%, rgba(0, 105, 163, 1))
        );
        background: -webkit-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
        background: -o-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
        background: -ms-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
        background: linear-gradient(to right, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
        box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
