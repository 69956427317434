@import "../../../../themes/fonts"; // Importa las fuentes del tema
@import "../../../../themes/colors"; // Importa los colores del tema

.delivery-card-to-list {
  position: relative;
  background-color: $color-light;
  cursor: pointer;
  box-sizing: content-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  &:hover,
  &.focused {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1.01);
  }

  &:active {
    background-color: $color-dark-light;
    overflow-x: hidden;
  }

  .container__content-top-and-content-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-top__delivery-card-to-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 6px 0;
    width: calc(100% - 20px);

    .container-delivery-info {
      display: flex;
      align-items: flex-start;
      z-index: 1;

      .delivery-img {
        position: relative;
        height: 51px;
        width: 51px;
        margin-right: 16px;
        margin-top: 4px;
        border-radius: 8px;
        overflow: hidden;
      }

      .delivery-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .delivery-img .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(0 0 0 / 51%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        opacity: 0;
        transition: opacity 0.3s;
        backdrop-filter: blur(5px);
      }

      .delivery-img:hover .overlay {
        opacity: 1;
      }

      .delivery-img .overlay span {
        color: $color-surface;
        font-family: "Ubuntu-MediumItalic", serif;
        font-size: 11px;
        filter: blur(0);
      }

      .content-delivery-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-right: 10px;
        box-sizing: border-box;

        .delivery-name {
          font-family: "Heebo-Medium", serif;
          color: $color-dark;
          font-size: 16px;
        }

        .delivery-time {
          font-family: "Heebo-Regular", serif;
          color: $color-header-gray;
          font-size: 14px;
          margin: 4px 0;

          .blue {
            font-weight: bold;
            color: #009af0;
          }

          .days-delivered {
            font-family: "Heebo-Medium", serif;
          }
        }

        .delivery-score {
          font-family: "Heebo-Regular", serif;
          color: $color-text-value;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 7px;

          .routeType {
            width: auto;
            font-size: 12px;
            padding: 4px 18px;
            color: $color-dark;
            border-radius: 25px;
            justify-content: center;
            font-family: "Ubuntu-Regular", sans-serif;
            background-color: rgba(162, 170, 172, 0.3);
            margin-top: 6px;
          }

          .routeType-express {
            width: auto;
            font-size: 12px;
            padding: 4px 18px;
            color: $color-dark;
            border-radius: 25px;
            justify-content: center;
            font-family: "Ubuntu-Regular", sans-serif;
            background-color: $color-primary;
            margin-top: 6px;
          }

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;

            & ~ div {
              height: 21px;
              display: flex;
              align-items: center;

              p {
                padding-top: 2px;
              }
            }
          }
        }
      }
    }

    .shipping-value {
      display: flex;
      flex-direction: column;

      &__delivery {
        .discount {
          color: $color-success-payments;
        }
        .real {
          font-size: 14px;
          color: $color-header-gray;
          display: flex;
          justify-content: flex-end;
          text-decoration: line-through;
        }
      }

      &__mount-score {
        display: flex;
        align-items: end;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        min-height: 82px;

        .score {
          display: flex;
          gap: 6px;

          p {
            font-family: "Heebo-Regular", serif;
            color: #7B8287;
          }
        }
      }
      span {
        font-family: "Heebo-Medium", serif;
        color: $color-dark;
        font-size: 16px;
        display: flex;
        align-items: flex-start;

        &.see-more {
          border: 2px solid transparent;
          border-radius: 4px;
          padding: 2px;
          white-space: nowrap;
          color: $color-accent-variant;

          img {
            height: 8px;
            margin-left: 5px;

            &.up {
              transform: rotate(0deg);
            }

            &.down {
              transform: rotate(180deg);
            }
          }

          &:hover {
            border: 2px solid $color-primary;
          }
        }
      }
    }
  }

  .content-bottom__delivery-card-to-list {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;

    .collection-value {
      font-family: "Heebo-Regular", serif;
      color: $color-text-label;
      font-size: 14px;
    }
  }

  hr {
    position: absolute;
    bottom: 0;
    left: 18%;
    width: 64%;
    border: none;
    border-top: 1px solid $color-dark-light;
  }

  &.desktop-mode {
    margin: 0.5em;

    .content-top__delivery-card-to-list {
      width: calc(100% - 8px);

      .shipping-value {
        span {
          &.see-more {
            display: flex;
            align-items: center;
            img {
              &.up {
                transform: rotate(270deg);
              }

              &.down {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }

  &.mobile-mode {
    .container__content-top-and-content-bottom {
      width: calc(100% - 10px);

      hr {
        position: static;
        width: 75%;
        left: auto;
      }

     .content-top__delivery-card-to-list {
      flex-direction: column;
       width: 100%;

      .delivery-score {
        display: flex;
        margin: 0 auto;
        gap: 10px;

        .routeType {
          width: auto;
          font-size: 12px;
          padding: 4px 10px;
          color: $color-dark;
          border-radius: 25px;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: rgba(162, 170, 172, 0.3);
        }
        .routeType-express {
          width: auto;
          font-size: 12px;
          padding: 4px 10px;
          color: $color-dark;
          border-radius: 25px;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: $color-primary;
        }
      }

      .content-delivery-info {
        margin-top: 5px;
        align-items: normal;

          &__value-collection {
            font-family: "Heebo-Medium", serif;
            color: $color-dark;
            font-size: 16px;
            display: flex;
            align-items: flex-start;
          }

          &__name-value {
            display: flex;
            gap: 10px;
            margin-top: 8px;
          }

          .shipping-value__mount-score {
            min-height: 0;
          }

          .delivery-time {
            display: revert;
          }
        }
      }
    }
  }
}
