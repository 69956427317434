@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.main-container-profile-cards {
  max-width: 400px;
  padding: 5px 0;
  border-bottom: 1px solid $color-gray-disabled;

  &.border-top {
    border-top: 1px solid $color-gray-disabled;
  }

  .card-container {
    width: 100%;
    display: flex;
    height: 60px;
    padding: 10px 10px;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:hover {
      border-radius: 8px;
      background-color: $color-secondary-hover-button;
    }

    .data-info {
      display: flex;
      flex-direction: row;
      margin-left: 10px;

      .iconLeft {
        margin-right: 15px;
        width: 22.09px;
      }

      div {
        display: flex;
        flex-direction: column;
        text-align: left;

        .title-info {
          font-size: 15px;
          color: $color-dark;
          font-family: "Heebo-Medium", sans-serif;
        }
        .subtitle-info {
          font-size: 14px;
          color: $color-text-title-dark-gray;
          font-family: "Heebo-Regular", sans-serif;
        }
      }
    }

    .icons-info {
      display: flex;
      flex-direction: row;
      margin-right: 10px;

      .iconLeft {
        width: 20px;

        &:hover {
          + .tooltip {
            visibility: visible;
          }
        }
      }

      .tooltip {
        visibility: hidden;
        position: absolute;
        margin-top: -50px;
        margin-left: -136.5px;
        z-index: 1;
        padding: 4px 0;
        width: 145.02px;
        height: 30.19px;
        font-size: 14px;
        background: white;
        border: 1px solid transparent;
        border-radius: 8px 8px 0 8px;
        box-shadow: 0px 3px 46px 2px $color-box-shadow-select;
        font-family: "Heebo-Regular", sans-serif;
        color: $color-empty-state-text;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          position: absolute;
          top: 85%;
          right: -5px;
          margin-left: -1px;
          line-height: 10px;
          border-color: #fff transparent transparent;
          border-style: solid;
          border-width: 11px 5px 0px 7px;
          transform: rotate(215deg);
        }
      }

      .icon-drop-down {
        margin-left: 15px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .main-container-profile-cards {
    max-width: 400px;
    width: 100%;

    &.border-top {
      border-top: 1px solid $color-gray-disabled;
    }

    .card-container {
      max-width: 400px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}
