@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-sizing: border-box;
  height: 100px;

  button {
    &.left {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      width: 0;

      img {
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-family: "Ubuntu-Medium", sans-serif;
      font-size: 20px;
      color: $color-dark;
      margin: 5px 0;
      text-align: center;

      &.color-ligth {
        color: $colr-ligth-gray;
        margin: 0;
      }
    }
  }

  .rigth {
    min-width: 24px;
  }

  &.public {
    height: 73px;
    background-color: #ffe9ce;
    margin-bottom: 30px;
    border-radius: 5px;

    .center {
      span {
        color: $color-primary-variant;
      }
    }
  }
}

.container-public-shipping-tracking {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);

  .header_public-shipping-tracking {
    width: 100%;
    height: 60px;
    border-bottom: 2px solid $color-primary;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-public-shipping-tracking {
      color: $color-primary-variant;
      font-weight: 500;
    }
  }

  .body_public-shipping-tracking {
    width: 100%;
    height: calc(100% - 60px);
    padding-left: 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-input-and-button {
      width: 90%;
      max-width: 550px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .container-select-input {
        display: flex;
        width: 500px;
        justify-content: space-evenly;

        .containter-select-all {
          min-width: 158px;
          margin: 16px 0;

          .container-value-selected__select-general {
            padding: 13px 0 13px 6px;
          }
        }

        .container-input {
          width: 90%;
          max-width: 300px;
        }
      }

      .buttons {
        display: flex;
        width: 500px;
        justify-content: space-evenly;

        .no-buttons {
          min-width: 158px;
        }

        button {
          width: 90%;
          max-width: 300px;
          margin-top: 20px;
        }
      }

      &.container-image {
        margin: 0 auto;
      }
    }

    &.container-input {
      padding-top: 0;
    }

    .not-found-shipping {
      position: relative;
      top: -20px;
    }
  }

  &.mobile-mode {
    .body_public-shipping-tracking {
      width: 100%;
      padding: 10px 10px;
      justify-content: flex-start;
      flex-direction: column;

      button {
        &.left {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          width: 0;

          img {
            height: 24px;
            width: 24px;
            cursor: pointer;
          }
        }
      }

      .container-input-and-button {
        .container-select-input {
          flex-direction: column;
          align-items: center;
          width: 100%;
        }

        .buttons {
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
      }

      .not-found-shipping {
        position: unset;
        padding-top: 20px;
      }
    }
  }
}
