@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.container-details-prices-discounts {
  padding: 5px 0 16px 0;

  &__title {
    text-align: center;
    font-size: 13px;
    font-family: "Heebo-Regular", serif;
    padding-top: 16px;
  }

  &__final-price {
    font-size: 13px;
    text-align: end;
    padding-top: 10px;
    color: $color-green-discount;
    font-family: "Heebo-Medium", serif;
  }

  &__list {
    font-size: 13px;
    padding: 10px 0;
    gap: 4px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction: column;
    border-bottom: 1px dotted $color-general-placeholder;

    .amount {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 13px;
        color: $color-dark;
      }

      .value {
        color: $color-header-gray;
      }

      .value-discount {
        color: $color-green-discount;
      }
    }

  }
}

.total-saved-amount {
  padding-bottom: 5px;
  padding-top: 8px;

  .span {
    font-size: 13px;
    color: $color-dark;
  }

  .amount {
    font-family: "Heebo-Medium", serif;
    color: $color-green-discount;
    background: #daf7db 0 no-repeat padding-box;
    border-radius: 12px;
    border: 1px solid #c2ecc3;
    padding: 0 5px;
  }
}

.total-saved-amount, .total-to-pay-amount, .only-pay-amount {
  text-align: left;
  display: flex;
  justify-content: space-between;

  .text .amount {
    font-size: 13px;
  }
}

.total-to-pay-amount {
  padding-top: 6px;

  .amount {
    font-family: "Heebo-Medium", serif;
  }
}

.only-pay-amount {
  border-top: 1px dotted $color-general-placeholder;
  border-bottom: 1px dotted $color-general-placeholder;
  padding: 10px 0;
}

.only-pay-amount .amount {
  font-family: Heebo-Medium, serif;
}
