@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

*{
  transition: all 200ms ease;
}

.container-step-progress {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  #container-points__step-progress{
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .step-point {
      list-style: none;
      position: relative;
      content: '';
      display: block;
      border-radius: 50%;
      background-color: $color-dark-light;
      overflow: hidden;

      .content_step-point{
        width: 0;
        height: 100%;
        background-color: $color-primary-variant;
        transition: all 80ms ease;

        &.success {
          width: 100%;
          transition-delay: 200ms;
        }

        &.error {
          background-color: $color-error;
        }
      }
    }
  }

  #container-lines__step-progress{
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .step-line{
      width: 100%;
      display: inline-flex;
      height: 2px;
      border-radius: 1px;
      background-color: transparent;
      overflow: hidden;

      .content_step-line{
        width: 0;
        height: 100%;
        background-color: $color-dark-light;

        &.success {
          width: 100%;
          background-color: $color-primary-variant;
        }

        &.error {
          background-color: $color-error;
        }
      }
    }
  }

  &.vertical{
    width: auto;
    height: 100%;
    flex-direction: column;

    #container-points__step-progress{
      height: 100%;
      width: auto;
      flex-direction: column;

      .step-point {
        .content_step-point{
          height: 0;
          width: 100%;

          &.success {
            height: 100%;
          }
        }
      }
    }

    #container-lines__step-progress{
      width: auto;
      height: 100%;
      flex-direction: column;

      .step-line {
        width: 4px;
        height: 100%;

        .content_step-line{
          height: 0;
          width: 100%;

          &.success {
            height: 100%;
          }
        }
      }
    }
  }

  &.no-automatic{
    justify-content: initial;

    #container-points__step-progress{
      justify-content: initial;
    }

    #container-lines__step-progress{
      justify-content: initial;
    }
  }
}
