@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.container-bargainings {
  border-radius: 12px;
  background-color: $color-light;
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 12px -10px rgba(108, 152, 181, 0.5);
  width: 100%;
  height: 100%;
}

.enable-negotiation-modal {
  padding: 11px 6px;
  height: 217px;
  width: 515px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title-header {
      text-align: center;
      width: 100%;
    }

    .close-wrapper {
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;

      .close {
        background-color: $color-primary-variant;
        mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
        -webkit-mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
        width: 12px;
        height: 12px;
      }

      &:hover {
        border-radius: 50%;
        background-color: $color-primary-variant-light;
        cursor: pointer;
      }
    }
  }

  .description {
    width: 60%;
    margin: 0 auto;
  }

  .container-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;

    Button {
      width: 226px;
    }
  }
}

.principal-header {
  padding: 14px 25px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-primary-variant;

  .arrow-back {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    padding: 8px;

    &:hover {
      border-radius: 50%;
      background-color: $color-primary-variant-light;
      cursor: pointer;
    }
  }
}

#bargainings {
  .content-bargainings {
    overflow: auto;
    height: 100%;

    &.empty {
      height: 100%;
    }
    .container-buttons {
      max-width: 264px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
}

.container-bargainings .wrapper {
  // height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0px 0px 0px;
  overflow: auto;

  .custom-empty-state-title {
    width: fit-content;
    color: $color-empty-state-text;
  }

  &.one {
    min-height: 70%;
    height: fit-content;

    &.empty {
      height: 65%;
      margin-bottom: 20px;
    }

    .custom-empty-state-title {
      font-family: "Heebo-Regular", sans-serif;
    }

    .empty-image {
      width: 164px;
      height: 164px;
    }

    .delivery-companies-list {
      width: 64%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.mobile {
        display: none;
      }

      .bargainings-list {
        margin: 48px 0;
        width: 100%;

        ul {
          display: flex;
          li {
            list-style: none;
            display: flex;
            align-items: center;

            &.one {
              width: 32%;
              word-break: break-word;
            }
            &.second,
            &.third,
            &.quarter {
              width: 22%;
              justify-content: center;
            }
          }
        }

        .header-list {
          padding: 0 8px;
          padding-bottom: 20px;
        }

        .body-list {
          padding: 8px 8px;

          &:hover {
            background-color: $color-background-hover-menu;
            cursor: pointer;
          }

          .company-image {
            width: 48px;
            height: 48px;
            border-radius: 5px;
            margin-right: 24px;
          }

          .size-link-button {
            // width: 116px;
            height: 30px;
            min-height: 30px;
          }
        }
      }
    }
  }

  &.two {
    justify-content: unset;

    .custom-empty-state-title {
      font-size: 18px;
    }

    .list-of-companies-to-add {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 331px;
      width: 100%;
      margin-top: 48px;
      flex-direction: column;

      .company-row {
        padding: 8px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 5px 0;

        .next-arrow {
          width: 9px;
          height: 15px;
          margin-right: 15px;
          margin-left: auto;
          background: url("../../../../assets/images/Dropup - Gray.png") no-repeat;
        }

        &:hover {
          background-color: $color-background-hover-menu;
          cursor: pointer;

          .next-arrow {
            background: url("../../../../assets/images/Dropup - Blue.png") no-repeat;
          }
        }

        img {
          width: 48px;
          height: 48px;
          border-radius: 5px;
          margin-right: 24px;
        }

        .company-title {
          text-transform: uppercase;
          width: 74%;
        }
      }
    }
  }

  &.three {
    height: 100%;
  }

  &.four {
    .body {
      width: 85%;
      display: flex;
      align-items: flex-start;

      .container-detail {
        width: 100%;
      }

      .detail-arrow {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 24px;

        .detail-column {
          width: 26%;
          word-break: break-all;
          display: flex;
          flex-direction: column;

          .title-detail {
            padding-bottom: 16px;
            text-transform: capitalize;
            font-family: "Heebo-Medium", sans-serif;
            color: $color-empty-state-text;
          }

          .value-detail {
            color: $color-empty-state-text;
          }
        }
      }

      .button-container-detail {
        width: 268px;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .container-bargainings {
    margin: 0;
    height: 100%;
    overflow: auto;
    max-height: max-content;
  }

  .principal-header {
    padding: 14px 10px;

    h1 {
      width: 65%;
      text-align: center;
    }
  }

  .enable-negotiation-modal {
    width: 94%;
    height: fit-content;

    .header {
      width: 100%;
      margin-bottom: 24px;

      .title-header {
        text-align: left;
        width: 80%;
        margin-left: auto;
      }
    }

    .description {
      width: 80%;
    }

    .container-buttons {
      flex-direction: column-reverse;
      margin-top: 24px;

      Button {
        margin: 8px 0;
      }
    }
  }

  .container-bargainings .wrapper {
    // margin: 0 16px;
    padding: 30px 16px 40px 16px;
    height: fit-content;

    &.one {
      height: fit-content;
      .delivery-companies-list {
        width: 100%;

        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }

        .bargainings-list {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .negotiation-container {
            margin-bottom: 20px;
          }

          .image-and-title {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .company-image {
              width: 40px;
              height: 40px;
              border-radius: 5px;
              margin-right: 24px;
            }
          }

          .messager-and-package-container {
            display: flex;
            justify-content: center;

            .messager-and-package {
              width: 50%;
              display: flex;
              align-items: center;
              flex-direction: column;
              margin-bottom: 24px;

              .switches-title {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }

    &.three {
      height: fit-content;
    }

    &.four {
      height: 100%;

      .body {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        .detail-arrow {
          flex-direction: column;
          margin-bottom: 0px;

          .detail-column {
            width: 100%;
            margin: 12px 0;
          }
        }

        .button-container-detail {
          width: 228px;
          margin: 0 auto;
          padding-bottom: 20px;
        }
      }
    }
  }

  .container-button {
    margin-bottom: 40px;
  }
}
