@import "../../../../../themes/fonts";
@import "../../../../../themes/colors";

#container-info-receiver {
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%;

  .container-info-receiver-shipping-stepTwo {
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 742px;

    .title {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      h2 {
        font-family: "Heebo-Medium", sans-serif;
        font-size: 16px;
        margin-bottom: 15px;
      }
    }

    .div {
      display: grid;
      grid-template-columns: repeat(2, 340px);
      justify-content: center;

      .container__input-all .container-lines__input-general {
        width: 271px;
      }

      .container__input-all .container__input-general hr.help-to-line {
        width: 270px;
      }

      .container__input-general {
        max-width: 343px;

        .content-top__input-general {
          max-width: 278px;

          .menor-width-input {
            width: calc(100% - 40px);
          }

          .tooltip {
            .tooltip__content-overlay {
              white-space: normal;
              overflow: hidden;
              max-height: 8em;
              line-height: 1.5em;
              height: 8em;
              -webkit-line-clamp: 3;
              word-wrap: break-word;
            }
          }

          .input-general.adressInfo {
            width: 220px;
          }
        }

        .adressInfo {
          display: grid;
        }
      }

      .containerInputAndSelect {
        margin-top: 15px;
        align-items: center;
        display: inline-flex;

        .tooltip .tooltip-area .icon-hover:hover {
          background-image: url('../../../../../assets/images/Tooltip.svg');
        }
      }

      .radio-button {
        margin-top: 8px !important;
        margin-left: -5px !important;
        margin-bottom: -5px;

        span {
          display: flex;
          align-items: center;

          .tooltip {
            margin-left: -68px;
          }
        }

        .container__input-all .container-lines__input-general {
          overflow: visible !important;

          .line-focused {
            left: -28px;
          }
        }

        .checked {
          margin-top: 0 !important;
          display: flex;
          align-items: center;
          font-family: "Heebo-Regular", serif;

          .container__input-all {
            display: flex !important;
            flex-wrap: nowrap !important;
            white-space: nowrap !important;
            min-width: 300px;
            margin-bottom: 30px;

            .span__input-general {
              margin-left: -34px !important;
              display: flex !important;
              white-space: nowrap !important;
            }

            .span__input-general:nth-child(2) {
              color: #929292 !important;
              margin-top: -21px;
              margin-left: 106px !important;
            }
          }
        }

        input {
          .address-selected {
            .adressInfo {
              min-width: 300px;

              label {
                margin-left: -20px !important;
              }
            }
          }
        }

        .office-address {
          span {
            &:last-child {
              margin-left: 4px;
            }
          }
        }
      }
    }

    .div:nth-child(6) {
      display: flex;
    }

    .officeType {
      font-family: "Heebo-Medium", serif;
      color: #535A5F;
      margin-left: 50px;
      font-size: 14px;
    }

    &.mobile-mode {
      width: 85%;
      margin-left: 0;
      margin-right: 0;

      .div {
        grid-template-columns: none;
        display: flex;
        flex-direction: column;


        .container__input-all {
          margin-bottom: 25px;
        }

        .container__input-all .container-lines__input-general {
          width: 100%;
        }

        .container__input-all .container__input-general hr.help-to-line {
          width: 100%;
        }

        .container__input-general {
          max-width: 100%;
        }

        .container__input-all .container__input-general .content-top__input-general .tooltip .tooltip__content-overlay {
          right: 0;
          bottom: 0;
        }

        .radio-button {
          width: 90%;
          max-width: 278px;
          margin: 0;
          display: flex;
          margin-left: -10px;

          input {
            margin-left: 0;
            width: auto;
            margin-top: 10px;
            margin-bottom: 0;
          }

          span {
            word-break: break-all;

            .tooltip {
              margin-left: -20px;
              margin-top: 20px;
            }
          }

          .checked {
            margin-top: 0 !important;
            display: flex;

            .container__input-all .container-lines__input-general {
              overflow: visible !important;

              .line-focused {
                left: -3px;
              }
            }

            .container__input-all {
              height: 100px;
              min-width: 200px;

              .span__input-general {
                margin-left: -30px !important;
                display: flex !important;
                white-space: nowrap !important;
              }

              .span__input-general:nth-child(2) {
                color: #929292 !important;
                white-space: nowrap !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
              }
            }

            .tooltip {
              margin-top: 12px;
              margin-left: -24px;
            }
          }
        }

        .radio-button:nth-child(1) {
          margin-top: 20px;
          margin-bottom: 0;
        }

        .address-selected {
          width: 90%;
          max-width: 278px;
          margin: 0;

          .containerInputAndSelect {
            margin-top: 0;
          }
        }
      }

      .officeType {
        font-family: "Heebo-Medium", serif;
        color: #535A5F;
        margin-left: 0;
        font-size: 14px;
      }

      .container-button-step-two {
        .strict-width {
          margin-left: 0;
        }
      }
    }
  }

  .container-checkbox-and-label {
    justify-content: center;

    .text-checkbox {
      font-size: 14px;
    }
  }

  .annotation__checkbox-general {
    &.error {
      text-align: center;
    }
  }

  .check-text {
    width: 257px;
    font-size: 14px;
    color: $color-text-label-gray;
    font-family: 'Heebo-Regular', sans-serif;

    span {
      font-family: 'Heebo-Medium', sans-serif;
      color: $color-primary-hover-download-button;
    }
  }

  .container-button-step-two {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: auto;
    max-width: 278px;
    row-gap: 10px;
    margin: 40px auto;

    .strict-width {
      margin-left: 12px;
    }
  }
}


@media screen and (max-width: 800px) {
  #container-info-receiver {
    .container-info-receiver-shipping-stepTwo {
      .div {
        .container__input-general {
          .content-top__input-general {
            .menor-width-input {
              width: calc(100% - 14px);
            }
          }
        }
      }
    }
  }
}
