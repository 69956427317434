@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

#wallet {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  height: 90%;
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f08700;
    box-sizing: border-box;
    & > *{ color: #f08700 }

    .wallet-title {
      font-size: 20px;
      color: $color-dark;
      font-family: 'Ubuntu-MediumItalic', sans-serif;
    }
  }

    .headerMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border-bottom: 1px solid #f08700;
    box-sizing: border-box;
    & > *{ color: #f08700 }

    div {
      width: 20px;
    }

    .wallet-title {
      font-size: 20px;
      color: $color-dark;
      font-family: 'Ubuntu-MediumItalic', sans-serif;
    }
  }

  .container-section-wallet {
    position: relative;
    height: calc(100% - 50px);
    overflow: hidden;
  }
}

@media only screen and (max-width: 450px) {
  #wallet {
    height: calc(100% - 180px);
  }
}
