@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

.orange-svg-search-edit-bank-data {
  background-color: $color-primary-variant !important;
}

#edit-bank-account {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 12px -10px rgba(108, 152, 181, 0.5);

  .header {
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: flex-start;
    border-bottom: 1.5px solid $color-primary-variant;

    img {
      padding: 5px;

      &:hover {
        background-color: $color-primary-variant-light;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .header-text {
      margin-left: 5px;
      font-size: 18px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    justify-content: space-between;
    padding: 0 20px;
    padding-top: 24px;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    .body-container {
      max-width: 700px;

      .custom-body-text {
        color: $color-empty-state-text;
        text-align: center;
      }

      .separete {
        padding-top: 40px;
      }

      .row {
        display: flex;
        justify-content: space-between;

        .container__input-all {
          max-width: 280px;
        }

        .container-radio-buttons__bank-data {
          height: 117px;
          width: 314px;

          .darker {
            color: $color-dark;
          }
        }

        .text-checkbox {
          margin-left: 10px;
        }

        .file-container {
          width: 314px !important;
          max-width: 314px !important;
        }

        .check-row {
          margin: 20px 0;
          max-width: 280px;

          .container-checkbox {
            height: 35px;
            width: 60px;
          }
        }

        .select-identification-type {
          width: 80%;
          max-width: 314px;

          .container-options__select-general {
            width: 100%;
          }
        }

        &.buttons-container {
          padding: 32px 0;
          width: 90%;
          margin: 0 auto;

          button {
            max-width: 224px;
          }
        }
      }
    }
  }
}

.update-bank-data-modal-container {
  width: 90%;
  max-width: 563px !important;
}

.edit-bank-data-success-modal {
  margin: 0 auto;


  .modal-children-header {
    .container-content-header {
      h1 {
        font-size: 18px;
      }
    }
  }

    .text-container {
      width: 80%;
    }

  .sucess-modal-text {
    color: $color-empty-state-text;
    font-size: 14px;

    .text {
      font-family: "Heebo-Regular", sans-serif;
    }

    .assert-text {
      margin-left: 2px;
      font-family: "Heebo-Bold", sans-serif;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 24px;

    .new-width {
      width: 100%;
      max-width: 222px;
    }
  }
}

@media (max-width: 900px) {
  #edit-bank-account {
    .body {
      width: calc(100% - 40px);

      .body-container {
        width: calc(100%);

        .custom-body-text {
          text-align: start;
        }

        .separete {
          padding-top: 24px;
        }

        .row {
          flex-direction: column;
          margin-top: 0px;

          .container__input-all {
            max-width: 100%;
          }

          .container-radio-buttons__bank-data {
            margin-top: 24px;
          }

          .select-identification-type {
            width: 100%;
          }

          &:last-child {
            margin-top: 0;
          }

          &.buttons-container {
            flex-direction: column-reverse;
            align-items: center;

            button {
              margin-top: 24px;
              margin-bottom: 24px;
              width: 85%;
              min-width: 85%;

              &:first-child {
                margin-top: 0px;
                width: 75%;
                min-width: 75%;
              }
            }
          }
        }
      }
    }
  }
}
