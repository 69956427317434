@import "../../../../themes/fonts";

#container-info-receiver {
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%;

  .container-info-receiver-shipping {
    display: flex;
    width: 60%;
    max-width: 742px;
    flex-direction: column;

    .title {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;

      h2 {
        font-family: "Heebo-Medium", sans-serif;
        font-size: 16px;
      }

      span {
        font-size: 14px;
        text-align: center;

        .bold {
          font-weight: bold;
        }
      }
    }

    .content-new-large-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 75px;
      row-gap: 25px;

      .label {
        font-family: "Heebo-Regular", sans-serif;
        font-size: 14px;
        color: #707B84;
      }
    }

    .content-large-list{
      flex-wrap: wrap;
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      max-width: 600px;
      width: 600px;
      max-height: 500px;
      column-gap: 45px;
      row-gap: 20px;

      .label {
        font-family: "Heebo-Regular", sans-serif;
        font-size: 14px;
        color: #707B84;
      }

      .field-container {
        max-width: 270px;
        width: 270px;
      }

      .field-container:first-child {
        .divider {
          height: 418px;
        }
      }

      .input-identification {
        margin-top: 26px;
      }

      .divider {
        height: 0;
      }

      .input-nit {
        display: flex;
        margin-top: 10px;

        .line {
          display: flex;
          align-items: center;
          padding: 20px 5px 0 25px;
          color: #535A5F;
        }
      }

      .input-nit {
        width: 215px;
        max-width: 215px;
        column-gap: 0;
      }

      .input-nit .container__input-all:first-child {
        width: 52%;
        .container__input-general {
          width: 130px;
          max-width: 130px;
        }
      }

      .input-nit .container__input-all:nth-child(3) {
        align-self: end;
        width: 52px;
        max-width: 47px;

        .container__input-general {
          width: 27px;
          margin-left: 10px;
        }
      }
    }

    .content-short-list {
      width: 84%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .label {
        font-family: "Heebo-Regular", sans-serif;
        font-size: 14px;
        color: #707B84;
      }

      .input-identification {
        margin-top: 26px;
      }
    }

    .last-inputs {
      margin-top: 24px;
    }

    .button-container {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      max-width: 240px;
      width: 240px;
    }

    &.mobile-mode {
      width: 100%;

      .title {
        width: 85%;
        margin: 0 auto;
        padding-bottom: 10px;

        span {
          margin-top: 8px;
          text-align: left;
        }
      }

      .content-short-list {
        width: 100%;
      }

      .content-new-large-list {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        max-height: none;
        width: 80%;
        margin: 0 auto;
      }

      .content-large-list {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        max-height: none;
        width: 80%;
        margin: 0 auto;

        .input-nit .container__input-all:nth-child(3) {
          .span__input-general {
            width: 140px;
            max-width: 140px;
          }
        }

        .input-nit .container__input-all:nth-child(3) .container-lines__input-general:focus {
          width: 20px;
        }

        .field-container {
          width: 100%;
        }
      }

      .content-items {
        width: 85%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .field-container {
          width: 100%;

          .containter-select-all .container-options__select-general {
            width: 100%;
            margin-top: 8px;
          }
        }
      }

      .divider, .second-divider {
        display: none;
      }
    }
  }
}
