@import "../../../../../themes/fonts.scss";
@import "../../../../../themes/colors.scss";
@import "../../../../../themes/general.scss";

.container-top__shipping-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 500;

  .header-quote-gray {
    margin: 0 1.5rem;
  }

  .container-bottom__options__shipping-history {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;

    &.closeness {
      width: 100%;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 60%;

      &.complete_header {
        width: 100%;
        justify-content: center;

        button {
          min-width: 280px;
          padding: 0;

          &:first-child {
            margin-right: 46px;
          }
        }
      }

      button {
        width: fit-content;
        margin: 0 5px;
      }
    }

    .rigth {
      display: flex;
      align-items: center;

      .containter-select-all {
        z-index: 1;
      }

      #order-deliveries-list {
        background-color: white;
      }

      .number-all-items {
        font-family: "Ubuntu-Medium", sans-serif;
        font-size: 16px;
        color: $color-text-label;
        margin: 0 10px;
      }

      .next-or-back-pages {
        display: flex;
        align-items: center;

        img {
          height: 24px;

          &:first-child {
            transform: rotate(-180deg);
          }

          &.enabled {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .container-top__shipping-history {
    min-height: 80px;
    padding: 10px;
    box-sizing: border-box;

    .container-bottom__options__shipping-history {
      width: 100%;
      justify-content: center;
      padding: 0;

      &.closeness {
        width: 100%;
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .container-top__shipping-history {
    height: 100px;
    padding: 24px;
    padding-bottom: 12px;
    text-align: center;
  }
}
