@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.user-avatar{
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .first-letter{
    height: 100%;
    width: 100%;
    background-color: #009AF0;
    font-family: "Ubuntu-Medium";
    color: $color-light;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.round{
    border-radius: 50%;
  }
}