@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

#left-side-menu {
  position: fixed;
  top: 80px;
  left: 0;
  width: 230px;
  height: calc(100% - 80px);
  z-index: 100;
  padding: 10px 20px 0;
  box-sizing: border-box;
  overflow-y: auto;

  * {
    transition: all 200ms ease
  }

  &.hidden {
    visibility: hidden;
    opacity: 0
  }

  .count-unread {
    font-family: "Heebo-Medium", serif;
    font-weight: unset;
    font-size: 15px;
    background-color: $color-error;
    color: $color-light;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#left-side-menu-hover {
  position: fixed;
  top: 110px;
  left: 30px;
  width: 52px;
  padding: 10px 10px 0 10px;
  height: calc(100% - 130px);
  box-sizing: border-box;
  border-radius: 5px;
  background-color: $color-menu-background;
  cursor: pointer;
  box-shadow: inset 0 0 4px #22262A29;
  transition: width 0.5s !important;
  overflow: hidden;
  z-index: 99;
}

#left-side-menu-hover:hover {
  width: 274px;
  z-index: 99;
}

.left-side-menu-card-hover {
  position: relative;
  background-color: $color-surface;
  border-radius: 5px;
  margin: 18px 0;
  padding: 0 4px;
  height: 35px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  .text {
    font-family: 'Heebo-Regular', serif;
    font-size: 16px;
    color: $color-dark;
    position: absolute;
    left: 41px;
    top: 5px;
    width: 200px;
  }
}

.container-help-contacts {
  width: 64px;
  height: 122px;  
  position: absolute;
  left: 22px;
  bottom: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 7px #C5DCEB;
  border-radius: 42px;
  opacity: 1;
}

.container-help-international {
  top: 8px;
  position: absolute;
  left: 10.5%;
}

@media only screen and (max-width: 1600px) {
  .container-help-international {
    top: 8px;
    position: absolute;
    left: 11.7%;
  }
  .container-help-contacts {
    width: 64px;
    height: 122px;    
    position: absolute;
    left: 22px;
    bottom: 22px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 7px #C5DCEB;
    border-radius: 42px;
    opacity: 1;
  }
}

@media only screen and (max-width: 1023px) {
  #left-side-menu {
    visibility: hidden;
    opacity: 0
  }
}

@media only screen and (min-width: 1024px) {
  #left-side-menu {
    visibility: visible;
    opacity: 1
  }
}