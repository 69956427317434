@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";


.card-container-desktop-shipping-details {
  background: #FFFFFF;
  margin-top: 2rem;
  width: calc(100% - 30px);
  height: calc(100% - 4rem);
  border-radius: 11px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  overflow-x: hidden;


  .container-information-shipping-desktop-top {
    display: flex;
    flex-direction: row;
    margin: 2rem 5rem;

    &.error {
      background-color: $colot-background-error;

      .container-title {
        display: flex;
        width: 100%;
        padding: 8px 40px;
        align-items: center;

        .title {
          h3 {
            text-align: center;
            font-family: "Heebo-Regular", sans-serif;
            color: $color-empty-state-text;
            font-weight: normal;
            font-size: 14px;
          }
        }

        .image {
          border-radius: 50%;
          border-color: $color-border-error;
          border-style: solid;
          border-width: 1px;
          min-width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;

          .ex {
            background-color: $color-error;
            mask: url(../../../assets/images/close.svg) no-repeat center / contain;
            -webkit-mask: url(../../../assets/images/close.svg) no-repeat center / contain;
            width: 7px;
            height: 7px;
          }
        }
      }
    }

    .content-product-detail-desktop {
      width: 100%;

      .button-and-tooltip {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0;
      }

      .secondary {
        width: 200px;

        .button-inner-text {
          margin-right: 10px;
        }

        .delivery-confirmation-normal-text {
          font-family: "Heebo-Regular", sans-serif;
          color: $color-header-gray;
        }

        .delivery-confirmation-word-tooltip {
          font-size: 14px;
          font-family: "Heebo-Medium", sans-serif;
          color: $color-header-gray;
        }
      }
    }
  }

  .container-information-shipping-desktop-bottom {
    display: flex;
    flex-direction: row;
    margin: 1rem 5rem;
    width: calc(100% - 5rem);
  }

}
