$fontSize: .9rem;

.container-state-switch {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  height: 50px;
  align-items: center;

  &.mobile-mode {
  height: 70px;
  }

  .container-price {

    &.desktop-mode {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .text-state-switch {
        font-size: $fontSize;
        color: #353A3D;
        font-weight: bold;
      }

      .container-cost {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 120px;

        .text-state-cost {
          font-size: $fontSize;
        }

      }
    }

    .text-state-switch {
      font-size: $fontSize;
      color: #353A3D;
      font-weight: bold;
    }

    .container-cost {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 115px;

      .text-state-cost {
        font-size: $fontSize;
      }

    }
  }

  input:checked~.control::after {
    background-color: #F08700;
  }
}
