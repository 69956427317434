@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.content-section__container {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;

  &.shadow {
    box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);
  }

  .content-section__header {
    width: 100%;

    .content-section__content-header {
      width: 100%;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      box-sizing: border-box;

      .header-left,
      .header-rigth {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: calc(100% - 100px);

        img {
          height: 16px;
          cursor: pointer;
        }
      }

      .header-center {
        width: calc(100% - 100px);
        display: flex;
        align-items: center;

        &.left {
          justify-content: flex-start;

          h1 {
            text-align: left;
          }
        }

        &.center {
          justify-content: center;

          h1 {
            text-align: center;
            font-family: 'Ubuntu-MediumItalic', sans-serif;
            font-size: 20px;
          }
        }

        &.rigth {
          justify-content: flex-end;

          h1 {
            text-align: center;
            font-size: 20px;
            font-family: 'Ubuntu-MediumItalic', sans-serif;
          }
        }
      }
    }

    hr {
      border: none;
      border-bottom: 2px solid;
      width: 100%;

      &.primary {
        border-bottom-color: $color-primary;
      }

      &.accent {
        border-bottom-color: $color-accent;
      }
    }
  }

  .content-section__body {
    width: 100%;

    &.with-header {
      height: calc(100% - 60px);
      // max-width: 284px;
      margin: 0;
    }

    &.without-header {
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .content-section__container {
    overflow-y: auto;
    overflow-x: hidden;

    .content-section__body {
      &.with-header {
        overflow-y: hidden;
      }
    }
  }
}
