@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";


.create-negotiation-title {
  color: $color-empty-state-text;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inputs-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin-top: 34px;
    min-height: 70%;
    flex-direction: column;

    .row-input {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      padding: 12px 0;

      .individual-input {
        width: 280px;

        .radio-button-title {
          color: $color-dark;
          font-size: 14px;
        }
      }
    }

  }

  .input-buttons {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-evenly;
    margin: 48px 0 28px 0;

    button {
      width: 240px;
    }
  }
}

.modal-wrapper {
  width: 563px;
  padding: 40px 66px;
  text-align: center;
  margin: 0 auto;
}

.error-modal-wrapper {
  padding: 40px 30px;

  .error-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    align-items: center;

    .error-header-text {
      font-size: 18px;
    }

    .close-wrapper {
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;

      .close {
        background-color: $color-primary-variant;
        mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
        -webkit-mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
        width: 12px;
        height: 12px;
      }

      &:hover {
        border-radius: 50%;
        background-color:  $color-primary-variant-light;
        cursor: pointer;
      }
    }

  }

}

@media only screen and (max-width: 1080px) {
  .inputs {

    .inputs-body {
      width: 100%;

      .row-input {
        padding: 0;
        flex-direction: column;

        .individual-input {
          width: 100%;
          padding: 12px 0;
        }
      }
    }

    .input-buttons {
      flex-direction: column-reverse;
      margin: 0;
      margin-top: 24px;

      button {
        margin: 12px 0;
      }
    }
  }

  .modal-wrapper {
    width: 90%;
    padding: 10px 0px;
    text-align: center;
    margin: 0 auto;
  }

  .error-modal-wrapper {
    padding: 14px 10px;

    .error-header {
      .error-header-text {
        width: 88%;
      }
    }
  }
}
