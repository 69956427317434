.container-button-fixed{
  position: fixed;
  bottom: 40px;
  width: auto;
  padding: 0 10%;
  box-sizing: border-box;
  z-index: 80;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button{
    margin: 10px 0;

    &.hidden{
      margin: 0;
    }
  }

  &.width75{
    width: 75%;
  }

  &.width50{
    width: 50%;
  }

  &.width25{
    width: 25%;
  }

  &.desktop-mode{
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 7%;

    button{
      margin: 10px 10px;

      &.hidden{
        margin: 0;
      }
    }
  }
}

.content-section-mobile{
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  overflow: hidden;
}

.general-table{
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-collapse: collapse;
  box-sizing: border-box;

  thead{
    width: 100%;
    box-sizing: border-box;

    tr{
      width: 100%;
      box-sizing: border-box;

      th{
        height: 50px;
        border: 0;
        font-family: "Ubuntu-Medium";
        font-size: 14px;
        color: $color-primary-variant;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }

  tbody{
    width: 100%;
    box-sizing: border-box;

    tr{
      width: 100%;
      box-sizing: border-box;

      &:hover, &.hover{
        background-color: #D9F2FF;
        cursor: pointer;
        box-sizing: border-box;
      }

      td{
        text-align: center;
        font-family: "Heebo-Regular";
        font-size: 14px;
        color: $color-text-label;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
  }
}

.loading-section{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-light;
    border-radius: 12px;
    box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.25);

    & > div {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border: 4px solid #eee;
      border-top: 4px solid #666;
      border-radius: 50%;
      animation: girar 2s ease-in-out infinite;
    }
  }
}

.arrowBack{
  width: 16px;
  height: 16px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;

  &:hover{
    background-color: rgba(255, 156, 25, .3);
  }
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
