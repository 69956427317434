@import "../../themes/colors.scss";

#background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: $color-background;
  z-index: -2;

  #figure__background {
    position: inherit;
    z-index: -1;

    &.top-left {
      top: 0;
      left: 0;
      border-bottom-right-radius: 50px;
      transform-origin: top right;
      transform: rotate(6deg);
    }&.top-right {
      top: 0;
      right: 0;
      border-bottom-left-radius: 50px;
      transform-origin: top left;
      transform: rotate(-6deg);
    }&.bottom-right {
      bottom: 0;
      right: 0;
      border-top-left-radius: 50px;
      transform-origin: bottom left;
      transform: rotate(6deg);
    }&.bottom-left {
      bottom: 0;
      left: 0;
      border-top-right-radius: 50px;
      transform-origin: bottom right;
      transform: rotate(-6deg);
    }

    &.primary {
      background-color: $color-primary;
    }&.accent {
      background:
        transparent
        linear-gradient(140deg, $color-accent 0%, $color-accent-variant 100%)
        0% 0% no-repeat padding-box;
    }&.white{
      background-color: $color-light;
    }&.mx{
      background-color: $color-background-mx;
    }
  }
}

@media only screen and (max-width: 1023px) {
  #figure__background {
    width: 100%;
    height: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  #figure__background {
    width: 65%;
    height: 75%;

    &.full-width {
      width: calc(100% - 210px);
    }
  }
}