@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

.last-details__button__drop-down {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid $color-primary;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.details-from-quote-multiple-shippings {
    max-width: 600px;
    margin-left: calc(50% - 300px);

    &.mobile-mode{
      margin-left: 0;
      width: 100%;
      max-width: none;
    }
  }

  span {
    font-family: "Ubuntu-Medium";
    font-size: 18px;
    color: $color-dark;
  }

  img {
    height: 10px;
    margin-left: 10px;
    transform: rotate(180deg);

    &.displayed {
      transform: rotate(0deg);
    }

    &.icon-back__last-details {
      height: 24px;
      cursor: pointer;
      margin-right: 10px;
      transform: rotate(0);
    }
  }
}



.modal__prohibited-items {
  width: 90%;
  height: min-content;
  padding: 20px;
  box-sizing: border-box;

  .header__prohibited-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left,
    .right {
      height: 20px;
      width: 20px;

      img {
        height: 80%;
        cursor: pointer;
      }
    }

    .center {
      span {
        font-family: "Ubuntu-Medium";
        font-size: 18px;
        color: $color-dark;
      }
    }
  }

  .body__prohibited-items {
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: $color-header-gray;

    .container-image-text {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .container-img {
        width: 48px;
        min-width: 48px;
        height: 48px;
      }

      .no-weapons {
        background-image: url(../../assets/images/no-weapons.svg);
        background-repeat: no-repeat;
      }

      .no-animals {
        background-image: url(../../assets/images/no-animals.svg);
        background-repeat: no-repeat;
      }

      .no-food {
        background-image: url(../../assets/images/no-food.svg);
        background-repeat: no-repeat;
      }

      .no-fire {
        background-image: url(../../assets/images/no-dangerous.svg);
        background-repeat: no-repeat;
      }

      .no-jewell {
        background-image: url(../../assets/images/No-jewell.svg);
        background-repeat: no-repeat;
      }

      .no-law {
        background-image: url(../../assets/images/Law.svg);
        background-repeat: no-repeat;
      }

      .broken-glass {
        background-image: url(../../assets/images/vidrio-roto.svg);
        background-repeat: no-repeat;
      }

      .No-liquid {
        background-image: url(../../assets/images/No-liquid.svg);
        background-repeat: no-repeat;
      }

      .no-tv {
        background-image: url(../../assets/images/No-tv.svg);
        background-repeat: no-repeat;
      }

      .strict-width {
        width: 80%;
      }
    }

    .paragraph {
      width: 100%;
      max-width: 790px;
      margin: 0 auto;
    }

    .container-items{
      width: 100%;
      max-height: 55vh;
      overflow-y: auto;
      display: flex;
      align-items: flex-start;

      .items {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 40px;
        padding-bottom: 40px;
        box-sizing: border-box;

        p {
          margin: 10px;
        }
      }
    }
  }

  &.mobile-mode {
    max-height: 80vh !important;
    width: 100%;
    padding: 0 !important;

    .header__prohibited-items {
      padding: 20px 5px 0;
      box-sizing: border-box;
    }

    .body__prohibited-items {
      padding: 20px;
      box-sizing: border-box;
      flex-direction: column;

      .paragraph {
        width: 100%;
      }

      .container-items{
        flex-direction: column;
        max-height: 40vh;

        .items {
          width: 100%;
          padding: 10px 0;

          p {
            margin: 10px 0;
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.body-accordion__last-details {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.h-50 {
    height: calc(100% - 50px);
  }

  &.h-100 {
    height: calc(100% - 100px);
  }

  &.h-150 {
    height: calc(100% - 150px);
  }

  .second-body-accordion__last-details {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;

    .container__second-body-accordion__last-details {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding: 20px 0;

      .content-top-bottom-or-side__container__second-body-accordion__last-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        max-width: 400px;
      }

      &.bottom {
        align-items: center;
        flex-direction: row-reverse;

        button {
          width: 100%;
          max-width: 250px;
        }
      }
    }
  }

  &.sending-details {
    .sending-details-article {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      .container-info__sending-details-article {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .active-drop-down-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;

      img {
        margin-left: 10px;

        &.top {
          transform: rotate(180deg);
        }

        &.bottom {
          transform: rotate(0);
        }
      }
    }

    .sending-details__drop-down-container {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      transition: all 400ms ease;
      height: auto;
      overflow: hidden;
      font-size: auto;

      &.hidden {
        height: 0;
        font-size: 0;
      }
    }

    .delivery-info__sending-details {
      width: 100%;

      .title-delivery {
        width: 100%;
        text-align: center;
      }

      .recolection-value-info {
        display: block;
        width: 100%;
        color: $color-primary;
        font-size: 14px;
        margin: 20px 0 0px;
      }

      .collection-value {
        font-family: "Heebo-Regular";
        color: $color-accent-variant;
        font-size: 14px;
        margin-bottom: 20px;
      }

      .delivery-data__delivery-info__sending-details {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .flexbox-delivery-data {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 75px;
            height: 75px;
            margin-right: 10px;
            border-radius: 8px;
          }

          .text-delivery-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .delivery-name__delivery-data {
              font-size: 18px;
            }

            .shipping-time__delivery-data {
              font-size: 16px;
              color: $color-accent;
            }
          }
        }
      }
    }
  }

  &.sender-details {
    .container-address__sender-details {
      width: 100%;

      .title-address__sender-details {
        margin: 10px 0;
      }

      .radio-button {
        margin: 0;
      }

      .address-info__sender-details {
        color: $color-accent-variant;
        margin: 20px 0 0;
        display: block;
      }
    }
  }

  &.receiver-details {
    .container-address__receiver-details {
      width: 100%;

      .title-address__sender-details {
        margin: 10px 0;
      }

      .radio-button {
        margin: 0;
      }

      .address-receiver {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .span {
          margin: 5px 0;
        }
      }
    }

    .document-info__receiver-details {
      color: $color-accent-variant;
      display: block;
    }
  }

  &.mobile-mode {
    .second-body-accordion__last-details {
      .container__second-body-accordion__last-details {
        flex-direction: column;
        justify-content: initial;
        align-items: center;
        padding: 20px 20px;
        box-sizing: border-box;

        &.bottom {
          align-items: center;
          flex-direction: column;

          button {
            margin: 10px;
            width: 90%;
            max-width: 400px;
          }
        }
      }
    }

    &.sending-details {
      .sending-details-article {
        padding: 20px 0 20px 10px;

        .container-info__sending-details-article {
          width: 100%;
          justify-content: flex-start;

          span {
            width: 50%;
          }
        }
      }
    }

    &.sender-details {
      .container-address__sender-details {
        width: 100%;

        .title-address__sender-details {
          margin: 10px 0;
        }

        .radio-button {
          margin: 0;
        }

        .address-info__sender-details {
          color: $color-accent-variant;
          margin: 20px 0 0;
          display: block;
        }
      }
    }

    &.receiver-details {
      .container-address__receiver-details {
        width: 100%;

        .title-address__sender-details {
          margin: 10px 0;
        }

        .radio-button {
          margin: 0;
        }
      }

      .document-info__receiver-details {
        color: $color-accent-variant;
        display: block;
      }
    }
  }
}
