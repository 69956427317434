@import "../../../../themes/fonts";
@import "../../../../themes/colors";

.back-ground-direction-select {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &.mobile-mode {
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }


  &.hiddenComponent {
    display: none;
  }
}

.container-address-list {
  background-color: $color-surface;
  position: absolute;
  width: 100%;
  max-width: 278px;
  height: 207px;
  max-height: 216px;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  padding: 7px 16px;
  z-index: 99999;

  &:focus-within {
    display: block;
  }

  .container-add-new-address {
    .add-address-button {
      font-family: "Heebo-Medium", serif;
      color: $color-accent;
      font-size: 16px;
      cursor: pointer;
      width: fit-content;
      margin: 0 auto;
    }

    hr {
      margin-top: 10px;

      &.help-to-line {
        margin: 0;
      }
    }
  }

  .address-list {
    margin-top: 10px;
    height: 60px;
    overflow: auto;

    .each-address {
      cursor: pointer;
      padding: 5px;
      border-radius: 3px;

      &:hover {
        background-color: $color-dark-light;
      }

      span.value {
        color: $color-header-gray;
      }
    }

    hr {
      margin-top: 0;
    }
  }

  .collection-or-take-to-office {
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      background-color: $color-dark-light;
    }

    h1 {
      font-family: "Heebo-Regular", serif;
      color: $color-header-gray;
      font-size: 16px;
      padding: 5px 0;
    }
  }
}

.container-modal-add-new-address {
  width: 495px;
  height: 394px;

  .container-close {
    display: flex;
    width: 92%;
    justify-content: flex-end;

    .close-button {
      mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
      -webkit-mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
      cursor: pointer;
      background-color: $color-primary-variant;
      width: 13px;
      height: 13px;
    }
  }

  .first-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;

    .first-title {
      margin-bottom: 24px;
      margin-top: -17.5px;
    }

    .subtitle {
      color: $color-header-gray;
      font-family: "Heebo-Regular", serif;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .container-inputs {
      width: 90%;
      max-width: 280px;
    }
  }

  .modal-buttons-add-address {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 10px;

    div {
      &:first-child {
        button {
          width: 152px;
          height: 32px;
          min-height: 32px;
        }
      }

      &:last-child {
        button {
          width: 206px;
          height: 42px;
          min-height: 42px;
        }
      }
    }
  }

  &.mobile-mode {
    max-width: 328px;
    height: 90%;
    max-height: 570px;
    margin: 0 auto;

    .first-row {
      .first-title {
        margin-left: -15px;
      }
    }

    .container-close {
      width: 84%;
    }

    .modal-buttons-add-address {
      flex-direction: column-reverse;

      div {
        margin: 14px 0;

        button {
          width: 264px;
        }

        &:first-child {
          margin-bottom: 25px;
        }
      }
    }
  }
}
