@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';
@import '../../themes/general.scss';

.container-quote-shipping__section-desktop{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;

  .primary-column{
    width: calc(100% - 40px);
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    opacity: 1;
  }

  .full-container{
    width: 100%;
  }

  .hidden{
    width: 0%;
    visibility: hidden;
    opacity: 0;
  }

  .size-change {
    width: calc(100% - 40px);
  }

  .min-change {
    width: 24%;
  }
}
