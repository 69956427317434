.content-wrapper {
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;

    h1,h2 {
      margin-bottom: 16px;
    }
  }
}
