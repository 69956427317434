@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.header__container-quote-shipping {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;

  .center {
    display: flex;
    align-items: center;
    margin: 0 auto;

    img {
      height: 20px;
      margin-right: 5px;
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      width: 35px;
      height: 18px;
      cursor: pointer;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      width: 32px;
      height: 35px;
      cursor: pointer;
    }
  }

  &.mobile-mode {

    .center {
      h1 {
        font-size: 18px;
      }
    }

    .z90 {
      .container-options__select-general.primary {
        border: none;

        img {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}
.progress-bar-shipping {
  width: 240px;
  height: 70px;
  display: flex;
  justify-content: center;
}

.header__border-quote-shipping {
  border-bottom: 2px solid $color-primary;
}
