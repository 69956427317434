@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";
@import "../../../../themes/general.scss";

.item-list {
  .detail-container {
    min-height: 50px;
    width: 100%;

    .title-notifications {
      font-size: 16px;
      text-align: left;
      margin-left: 20px;
    }

    .list-notifications {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 20px;

      .container-list-notification {
        display: flex;
        width: 90%;
        justify-content: space-between;
        margin: 5px 0px;

        .item-notifications {
          font-size: 16px;
        }
      }
    }
  }

  .container-item {
    width: 100%;
    min-height: 80px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .container-left {
      .info-item {
        color: $color-text-value;
        margin: 8px;
        font-weight: lighter;
        font-size: 16px;
      }
    }

    .container-right {
      display: flex;
      align-items: center;

      .image {
        mask: url(../../../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
        -webkit-mask: url(../../../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
        width: 34px;
        height: 34px;
        background: $color-primary 0 0 no-repeat padding-box;

        &.displayed {
          transform: rotate(180deg);
        }
      }

      .block {
        width: 34px;
        height: 34px;
      }

      .cash-value {
        margin: 35px 0;
        font-size: 16px;
        text-align: center;

        &.income {
          color: #0db519;
        }

        &.expense {
          color: #f00000;
        }
      }

      .currencyToPayText {
        margin-left: 5px;
        font-size: 16px;
        color: $color-gray;
        font-family: 'Heebo-Regular', sans-serif;
      }
    }
  }
}
