@import "../../themes/colors.scss";

.desktop-modal-screen {
  width: 900px;
  max-height: 90%;
  height: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .header-close {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: unset;

    img.back{
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    img.close {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    h1 {
      font-weight: 600;
    }
  }

  .paragraphModal {
    display: block;
    color: $color-text-value;
    font-size: 16px;
    text-align: center;
    width: 80%;
    margin: 32px 0;
    margin-bottom: 10px;
  }

  .container-gif-and-drop-rigth{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img{
      width: 24px;
      height: 34px;
      cursor: pointer;
      transform: rotate(180deg);

      &.next{
        transform: rotate(0);
      }
    }

    .columnGif {
      max-width: 600px;
      max-height: 300px;
      margin: 0 auto;
      width: 85%;
      height: 300px;
      border-radius: 8px;
      background-color: #e4eaee;

      div {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 8px;

        &.stepOne {
          background-image: url(../../assets/gif/mp_gif_2.gif);
        }

        &.stepTwo {
          background-image: url(../../assets/gif/mp_gif_3.gif);
        }

        &.stepThree {
          background-image: url(../../assets/gif/mp_gif_4.gif);
        }

        &.stepFour {
          background-image: url(../../assets/gif/mp_gif_5.gif);
        }

        &.stepFive {
          background-image: url(../../assets/gif/mp_gif_6.gif);
        }

        &.stepSix {
          background-image: url(../../assets/gif/mp_gif_7.gif);
        }

        &.noShow {
          display: none;
        }
      }
    }
  }

  .points {
    max-width: 152px;
    width: 85%;
    height: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0;

    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-gray-disabled;

      &.currentStep {
        background-color: $color-primary-variant;
      }
    }
  }

  .finishOrContinueButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      max-width: 200px;
    }
  }
}

@media (max-width: 500px) {
  .desktop-modal-screen {
    width: 90%;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px 5% 40px;

    .paragraphModal {
      font-size: 14px;
      width: 100%;
      text-align: left;
      height: auto;
      margin: 0;
      margin: 32px 0;
    }

    .columnGif {
      width: 100%;
      height: 200px;
    }

    .finishOrContinueButtons {
      margin: 0 auto;
      width: 100%;
      justify-content: center;
      
      button {
        max-width: none;
      }
    }
  }
}
