@import "src/themes/fonts";
@import "src/themes/colors";

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.container-data {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.4s ease-out forwards;
  z-index: 1000;
  border-radius: 14px;
  padding: 20px 45px 40px 45px;

  .title {
    font-family: "Ubuntu-Medium", serif;
    font-size: 15px;
    color: $color-dark;
    text-align: center;
  }

  &__commission-details-header {
    .close-icon {
      position: relative;

      img {
        width: 12px;
        height: 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -4%;
        bottom: 6%;
      }
    }

  }

  &__commission-details {
    padding: 20px;
  }
}

.container-data.show {
  display: block;
}

.container-data-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
