@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

// General styles
.toggle-modal {
  background-color: $color-light;
  width: 330px;
  height: 220px;
  position: absolute;
  top: 0;
  right: 0.8rem;
  z-index: 99;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  transform-origin: top;
  transition: all 0.8s cubic-bezier(0.4, -0.92, 0.64, 1.88) !important;
  z-index: 120;

  &.with-discount-module {
    height: fit-content;
  }

  .content-toggle-menu {
    width: 100%;

    &.visible {
      margin-top: 120px;
    }

    .content-body-tooggle-menu {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 4px;
    }

    .counter-mile-rigth-menu-desktop {
      width: calc(100% - 64px);
      left: 32px;
      bottom: 136px;
      cursor: pointer;
      margin: 0 auto;
      margin-bottom: 7px;
    }

    hr {
      bottom: 60px;
      width: 100%;
      border: 1px solid rgba(128, 128, 128, 0.267);
    }

    .menu-option {
      &:hover {
        background-color: $color-background-hover-menu;
      }
    }

    .profile {
      font-size: 16px;
      text-align: left;
      width: calc(100% - 64px);
      margin: 0 auto;
      padding: 7px 0;
      font-family: "Heebo-Regular", sans-serif;
      bottom: 93px;
      left: 32px;
      cursor: pointer;

      &:hover {
        font-family: "Heebo-Bold", sans-serif;
      }
    }
  }

  .content-logout-toggle-menu {
    width: 82%;
    margin: 0 auto;

    .logout {
      font-size: 16px;
      font-weight: 700;
      color: $color-dark;
      text-align: center;
      bottom: 24px;
      right: 32%;
      cursor: pointer;
      margin: 12px 0;
    }
  }

  &.toggle-modal-none {
    height: 0px;
    transition-duration: 0.8s !important;

    .profile,
    hr,
    .logout {
      display: none;
      transition-duration: 0.8s !important;
    }
  }
}

// Button and menu styles
#button__menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 70px;
  width: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 130;
  cursor: pointer;

  &.hidden {
    display: none;
  }

  #background__button__menu {
    position: absolute;
    width: 100%;
    height: calc(100% + 10px);
    top: -10px;
    border-bottom-left-radius: 14px;
    background-color: $color-light;
    transform-origin: top left;
    transform: rotate(-6deg);
    transition: all 0.8s cubic-bezier(0.4, -0.92, 0.64, 1.88) !important;
    z-index: 1;
  }

  #content__button__menu {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .container-user-data__content__button__menu {
      display: flex;
      align-items: center;
      padding: 5px;
      height: 50px;
      box-sizing: border-box;
      border-radius: 25px;

      .buttonToLoginOrRegister {
        height: 26px;
        min-height: 26px;
        max-height: 26px;
        width: 120px;
        margin-right: 4px;
        margin-left: 4px;
      }

      .icon__user-data__content__button__menu {
        height: 0px;
        width: 0px;
        overflow: hidden;

        &.visible {
          height: 28px;
          width: 28px;
        }
      }

      .white-logo {
        height: 0;
        width: 0;
        overflow: hidden;

        &.visible {
          height: 60px;
          width: auto;
        }
      }
    }

    span {
      font-family: "Heebo-Regular", sans-serif;
      font-size: 0px;
      color: $color-dark;
      max-width: 120px;
    }

    .icon__content__button__menu {
      height: 24px;
      width: 24px;

      &.hidden {
        height: 0px;
        width: 0px;
      }

      &.notifications {
        margin-right: 20px;
      }
    }

    .img-simulation {
      background-color: #009af0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Ubuntu-Medium", sans-serif;
      font-size: 24px;
      color: $color-light;
      margin-left: 10px;
    }
  }

  &.menu-displayed-mobile {
    height: 120px;
    width: calc(100% - 52px);

    #background__button__menu {
      height: calc(100% + 50px);
      top: -50px;
      border-bottom-left-radius: 20px;
      background-color: $color-primary-variant;

      &.mx {
        background-color: $color-background-mx;
      }
    }

    #content__button__menu {
      z-index: 2;

      span {
        font-size: 16px;
      }

      .icon__content__button__menu {
        height: 0px;
        width: 0px;
      }
    }
  }

  &.desktop-mode {
    height: 100px;
    width: 350px;

    &:hover #background__button__menu {
      background-color: $color-background-hover;
    }

    #background__button__menu {
      border-bottom-left-radius: 30px;
      height: 100%;
      top: 0;
      transform: rotate(-5deg);
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    #content__button__menu {
      height: 70%;
      position: relative;
      z-index: 2;

      span {
        font-size: 16px;
        margin-left: 20px;
      }
    }

    &.menu-displayed-desktop {
      #content__button__menu {
        z-index: 2;

        span {
          font-size: 16px;
        }

        .icon__content__button__menu {
          height: 0px;
          width: 0px;
        }
      }
    }
  }
}

#background__menu {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 110;

  &.menu-displayed-mobile {
    visibility: visible;
    opacity: 1;
  }

  &.hidden {
    display: none;
  }
}

// Menu content
#content__menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 65%;
  margin-right: -65%;
  height: 100%;
  box-sizing: border-box;
  background-color: $color-light;
  z-index: 120;

  &.menu-displayed-mobile {
    margin-right: 0;
    width: calc(100% - 70px);
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .logout {
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    hr {
      margin-bottom: 20px;
      height: 0px;
      width: 100%;
      border: 1px solid #d6dbdf;
    }

    span {
      font-family: "Ubuntu-MediumItalic", sans-serif;
      font-size: 16px;
      color: $color-dark;
      margin-bottom: 22px;
    }
  }

  .menu {
    margin-top: 170px;

    hr {
      margin-top: 3.5%;
      height: 0px;
      width: 100%;
      border: 1px solid #d6dbdf;
      margin-bottom: 12px;
    }

    .counter-mile-rigth-menu {
      width: calc(100% - 54px);
      padding-left: 36px;
      padding-bottom: 24px;

      &:hover {
        background-color: $color-background-hover-menu;
      }
    }

    .item-menu {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 12px 0;
      padding-left: 36px;
      margin: 2px 0;
      box-sizing: border-box;
      width: 100%;

      .buttons-for-login-or-register {
        width: calc(100% - 37px);
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          width: 80%;
          margin: 10px 0;
          font-size: 20px;
          height: 35px;
          min-height: 35px;

          &.border-none {
            border-color: transparent;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      img {
        height: 24px;
        width: 24px;
        margin-right: 5px;
      }

      span {
        font-family: "Heebo-Regular", sans-serif;
        font-size: 18px;
        color: $color-dark;
      }

      &:hover {
        background-color: $color-background-hover-menu;
        span {
          font-family: "Heebo-Bold", sans-serif;
        }
      }

      &.no-hover {
        &:hover {
          background-color: transparent;
        }
      }

      &.second-part {
        span {
          font-family: "Ubuntu-MediumItalic", sans-serif;
        }
      }
    }

    .content-help {
      padding: 10px 0 0 36px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;
      width: 90%;

      .imgBtnWhatsapp {
        padding-right: 10px;
      }

      .img-off {
        padding-right: 10px;
        content: url("../../assets/images/help-icon-gray-light.svg");
      }

      .img-on {
        padding-right: 10px;
        content: url("../../assets/images/help-small2.svg");
      }

      .help-text-off {
        font-size: 16px;
        color: $color-dark;
        padding-right: 153px;
        font-family: "Heebo-Regular", sans-serif;
      }

      .help-text-off-wa {
        font-size: 16px;
        color: $color-dark;
        padding-right: 10px;
        font-family: "Heebo-Regular", sans-serif;
        text-align: left;
      }

      .help-text-on {
        font-size: 16px;
        padding-right: 153px;
        color: $color-header-gray;
        font-family: "Heebo-Regular", sans-serif;
      }

      .help-text-on-wa {
        font-size: 16px;
        padding-right: 10px;
        color: $color-header-gray;
        font-family: "Heebo-Regular", sans-serif;
        text-align: left;
      }

      .img-down {
        transform: rotate(180deg);
        content: url("../../assets/images/Dropdown-Gray-help.svg");
      }

      .img-up {
        content: url("../../assets/images/Dropdown-Gray-help.svg");
      }

      .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .content-help-chat {
      padding: 10px 0 0 36px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;
      width: 100%;

      .help-text-off {
        font-size: 16px;
        color: $color-dark;
        font-family: "Heebo-Regular", sans-serif;
      }

      .help-text-on {
        font-size: 16px;
        color: $color-header-gray;
        font-family: "Heebo-Regular", sans-serif;
      }
    }

    .help-links {
      display: flex;
      flex-direction: column;
      padding: 15px 0 0 36px;
      width: 100%;

      div {
        width: auto;
        max-width: 200px;
        padding: 0.5rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .link-text {
          font-size: 16px;
          color: $color-dark;
          font-family: "Heebo-Regular", sans-serif !important;
        }
      }
      .help-links-contact {
        display: flex;
        justify-content: flex-start;
      }
      .link-text-contact-1 {
        font-size: 16px;
        padding-right: 67px;
        font-family: "Heebo-Regular", ui-sans-serif;
        color: #353A3D;
      }
      .link-text-contact-2 {
        font-size: 16px;
        padding-right: 100px;
        font-family: "Heebo-Regular", ui-sans-serif;
        color: #353A3D;
      }
    }
  }
}

// Logout modal styles
.logout-modal {
  width: 400px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  p {
    margin: 30px 0px 20px;
  }

  .buttons__logout-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    margin: 10px;
    font-size: 16px;
    font-family: "Ubuntu-MediumItalic", sans-serif;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    color: $color-light;
    height: 50px;
    border-radius: 25px;
    background: rgba(0, 154, 240, 1);
    background: linear-gradient(to right, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);

    &:active {
      color: $color-dark-light;
      transform: scale(0.9);
    }
  }
}

// Additional styles
span.to-login {
  font-size: 16px;

  &.login {
    font-family: "Ubuntu-MediumItalic", sans-serif !important;
    color: $color-primary-variant !important;
  }

  &.create {
    font-family: "Ubuntu-MediumItalic", sans-serif !important;
    color: $color-light !important;
    background: transparent linear-gradient(114deg, #009af0 0%, #1483c1 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 4px 10px;
    display: block;
    margin-left: 20px !important;
  }
}

.icon-home {
  position: relative;

  &__mobile {
    position: absolute;
    left: 62%;
    margin-top: 25px;
  }
}
