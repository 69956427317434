@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

.custom-dropdown {
  height: 80px;

  .custom-select {
    .custom-select-trigger {
      width: 209px;
      position: relative;
      height: 40px;
      display: flex;
      cursor: pointer;
      z-index: 9;
      flex-direction: row;
      align-items: center;
      border-radius: 25px;
      margin:10px 0 28px 0;
      justify-content: space-evenly;
      background-color: $color-surface;
      border: 1px solid $color-primary-variant;

      span {
        font-size: 16px;
        font-family: "Heebo-Regular", sans-serif;
      }

      .selectArrow {
        content: url("../../assets/images/selectIcons/arrow_drop_down-Orange.svg");
        transform: rotate(180deg);
      }
    }

    .custom-options {
      display: flex;
      position: absolute;
      top: 38%;
      transform: translateY(-40%);
      width: 210px;
      padding-top: 20px;
      white-space: nowrap;
      z-index: 1;
      flex-direction: column;
      align-items: flex-start;
      border-top: none;
      background-color: $color-surface;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

      .custom-option {
        width: 100%;
        height: 45px;
        margin: 5px 0;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        justify-content: left;
        flex-direction: row;
        align-items: center;
        color: $color-empty-state-text;
        font-family: "Heebo-Regular", sans-serif;

        &:hover {
          background-color: $color-background;
        }
      }

      img {
        width: 24px;
        margin: 0 15px;
      }
    }
  }

  .dropdown-header {
    height: 44px;
    width: 109px;
    display: flex;
    cursor: pointer;
    margin-bottom: 28px;
    border-radius: 25px;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid $color-text-label-input-fiel;

    .text-flag {
      font-size: 16px;
      font-family: "Heebo-Regular", sans-serif;
    }

    img {
      width: 32.4px;
      margin-right: 5px;
    }

    .dropdown {
      content: url("../../assets/images/arrow_drop_down_gray.svg");
    }

    .tooltip {
      visibility: hidden;
      position: absolute;
      margin-top: 128px;
      z-index: 1;
      padding: 4px 0;
      width: 90.05px;
      height: 45.19px;
      font-size: 16px;
      background: white;
      border: 1px solid transparent;
      border-radius: 10%;
      box-shadow: 0 3px 46px 2px $color-box-shadow-select;
      font-family: "Heebo-Regular", sans-serif;
      color: $color-header-gray;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        top: -20%;
        left: 47%;
        margin-left: -1px;
        line-height: 10px;
        border-color: #fff transparent transparent;
        border-style: solid;
        border-width: 11px 5px 0 7px;
        transform: rotate(180deg);
      }
    }

    &:hover {
      background-color: $color-ligth-orange;
      border: 1px solid $color-primary-variant;

      .tooltip {
        visibility: visible;
      }

      .dropdown {
        content: url("../../assets/images/arrow_orange.svg");
      }
    }
  }
}
