@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';
@import '../../themes/general.scss';

.filters-modal{
  width: 100%;
  height: 100%;
  max-height: 70% !important;
  max-width: 70% !important;
  padding: 20px;
  box-sizing: border-box;

  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 24px;

    .left, .right{
      height: 100%;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        height: 75%;
        cursor: pointer;

        &.icon-back{
          height: 120%;
        }
      }
    }

    .center{
      font-family: "Ubuntu-Medium";
      font-size: 20px;
      color: $color-primary;
      max-width: 350px;
      text-align: center;
    }
  }

  .body{
    height: calc(100% - 94px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    box-sizing: border-box;

    .text-search-by{
      margin: 20px 0;
    }

    .container-columns{
      width: 100%;
      height: calc(100% - 60px);
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;

      .column{
        width: 20%;
        min-width: 200px;
        height: 100%;

        .selector{
          height: 30px;
          width: 0;
          min-width: max-content;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Ubuntu-Medium";
          font-size: 18px;
          color: $color-primary;
          padding: 0 10px;
          box-sizing: border-box;
          cursor: pointer;
          border-radius: 4px;
          transition: all 100ms ease;

          img{
            width: 24px;
            height: 24px;
          }

          &:hover, &.selected{
            background-color: #e0e0e0;
            width: 100%;
            transition: all 100ms ease;
          }

          &.selected{
            img{
              transform: rotate(180deg);
            }
          }
        }

        .content-column{
          width: 100%;
          margin-top: 5px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          height: 0;
          overflow-y: hidden;
          transition: all 80ms ease;

          &.show{
            height: calc(100% - 50px);
            overflow-y: auto;
          }

          .search-by{
            margin: 10px;
            cursor: pointer;
          }

          .item-delivery-company, .item-state{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            transition: all 40ms ease;

            &:hover{
              background-color: #e7e7e7;
            }

            .delivery{
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img{
                width: 28px;
                height: 28px;
                border-radius: 4px;
                margin-right: 5px;
              }
            }

            .container-checkbox-and-label{
              width: auto;
            }
          }
        }
      }
    }

    .container__input-all{
      max-width: 350px;
    }
  }

  .footer{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;
    transition: all 40ms ease;

    &.content-align-center{
      justify-content: center;
    }

    button{
      max-width: 250px;
      transition: all 40ms ease;
    }

    .delete-filters{
      border: 2px solid $color-error;
      color: $color-error;
      width: auto;
      padding: 0 30px;
    }
  }

  &.mobile-mode{
    max-height: 80% !important;
    max-width: 90% !important;

    .body{
      width: 100%;
      justify-content: flex-start;
      overflow-y: auto;
      height: calc(100% - 120px);

      .text-search-by{
        margin: 20px 0;
        text-align: center;
      }

      .container-columns{
        width: 100%;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .column{
          width: 100%;
          min-width: 200px;
          max-height: 50vh;
          display: flex;
          flex-direction: column;
          align-items: center;

          .selector{
            height: 30px;
            width: 0;
            min-width: max-content;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "Ubuntu-Medium";
            font-size: 18px;
            color: $color-primary;
            padding: 0 10px;
            box-sizing: border-box;
            cursor: pointer;
            border-radius: 4px;
            transition: all 100ms ease;

            img{
              width: 24px;
              height: 24px;
            }

            &:hover, &.selected{
              background-color: #e0e0e0;
              width: 100%;
              transition: all 100ms ease;
            }

            &.selected{
              img{
                transform: rotate(180deg);
              }
            }
          }

          .content-column{
            width: 100%;
            margin-top: 5px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            height: 0;
            overflow-y: hidden;
            transition: all 80ms ease;

            &.show{
              height: calc(100% - 50px);
              overflow-y: auto;
            }

            .search-by{
              margin: 10px;
              cursor: pointer;
            }

            .item-delivery-company, .item-state{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              cursor: pointer;
              transition: all 40ms ease;

              &:hover{
                background-color: #e7e7e7;
              }

              .delivery{
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img{
                  width: 28px;
                  height: 28px;
                  border-radius: 4px;
                  margin-right: 5px;
                }
              }

              .container-checkbox-and-label{
                width: auto;
              }
            }
          }
        }
      }

      .container__input-all{
        max-width: 350px;
      }
    }

    .footer{
      height: 90px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-end;
      padding: 0 40px;
      box-sizing: border-box;
      transition: all 40ms ease;

      &.content-align-center{
        justify-content: center;
      }

      button{
        margin: 5px 0;
        max-width: 250px;
        transition: all 40ms ease;
      }
    }
  }
}

.filter-text{
  font-family: "Heebo-Regular";
  font-size: 14px;
  font-weight: 600;
  color: $color-text-label;
}
