@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

.radio-button{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  cursor: pointer;
  gap: 4px;

  img {
    width: 19px;
    height: 28px;
  }

  input{
    margin: 4px 8px 4px 15px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  input:checked{
    background-color: $color-primary-variant;
  }

  span{
    font-family: "Heebo-Regular", serif;
    font-size: 14px;
    color: $color-header-gray;
    width: 100%;

    &.checked {
        font-family: "Heebo-Medium", serif;
        color: $color-empty-state-text;
    }
  }
}

.annotation__radio-button-general {
  width: 100%;
  height: auto;
  font-size: 12px;
  font-family: 'Heebo-Regular', serif;
  color: $color-dark;
  margin-top: 2px;
  display: none;

  &.focus{
    color: $color-bg-buton-quternary-gradiant2;
  }

  &.error{
    font-size: 0;
    color: $color-error;

    &.visible{
      font-size: 12px;
      display: inline;
    }
  }
}
