@import "../../themes/colors.scss";

.background-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 500;

  .container-modal {
    background-color: $color-light;
    border-radius: 14px;
    max-width: calc(100% - 40px);
    max-height: 90%;
    overflow-y: auto;
  }

  &.desktop-mode {
    .container-modal {
      max-width: 60%;
    }
  }

  &.mobile-mode{
    .container-modal {
      max-width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}
