@import '../../../themes/fonts.scss';
@import '../../../themes/colors.scss';
#Company {
  margin: 20px 0;
  padding: 0 40px;
  box-sizing: border-box;
  
  p {
    color: $color-general-text;
    font-family: 'Heebo-Regular';
    font-size: 14px;
    text-align: left;
    padding-bottom: 14px;
  }

  ul {
    padding: 0 20px;
    box-sizing: border-box;
    color: $color-general-text;

    li {
      padding: 10px 0;
      box-sizing: border-box;
      font-family: 'Heebo-Regular';
      font-size: 14px;

      span {
        color: $color-accent;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

}
