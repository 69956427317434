@import '../../themes/colors.scss';
@import '../../themes/fonts.scss';

.container-validate-email__desktop-mode{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;

  #validate-email{
    width: 500px !important;
  }
}
