@import '../../../themes/fonts.scss';
@import '../../../themes/colors.scss';
@import '../../../themes/general.scss';

#multiple-shippings__template{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;

  .content-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;

    .adjust-text {
      margin-bottom: 10%;
      margin-top: -1%;
    }

    *{
      margin: 4px 0;
    }
  }

  .content-bottom{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    button{
      max-width: 350px;
      margin-top: -40px;

      &:first-child{
        margin-bottom: 10px;
      }
    }
  }
}

#multiple-shippings__upload-template{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-top{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
  }

  .content-bottom{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button{
      max-width: 350px;
    }
  }
}

.modal__sending-save{
  width: 350px;
  padding: 20px 0;
  box-sizing: border-box;
  max-width: 95%;

  .body__sending-save{
    width: 90%;
    text-align: center;
    margin: 20px 5%;

    span{
      font-family: "Heebo-Regular", sans-serif;
      font-size: 16px;
      color: $color-text-value;

      b{
        font-family: "Heebo-Medium", sans-serif;
      }
    }
  }

  .footer__sending-save{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button{
      margin: 10px 0;
      max-width: 250px;
    }
  }
}

.create-sendings{
  width: 380px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  box-sizing: border-box;

  p{
    font-size: 20px;
    color: $color-accent;
    text-align: center;
    font-family: 'Ubuntu-MediumItalic', sans-serif;
    width: 100%;

    &:last-child{
      font-size: 16px;
      color: $color-dark-gray;
      margin-top: 10px;
      font-family: 'Ubuntu-Medium', sans-serif;
    }
  }

  .general-loading{
    margin: 20px 0 0;
  }
}
