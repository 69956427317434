@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

.changes-pasword-mobile {
  width: 100%;
  height: 96%;
}

#changes-password-mobile {
  border-radius: 12px;
  background-color: #ffffff;
  width: calc(100% - 40px);
  height: calc(100% - 0px);
  margin: 0px auto;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 12px -10px rgba(108, 152, 181, 0.5);
  overflow-y: hidden;
  margin-bottom: 20px;

  .container-change-password-mobile {
    max-width: 300px;
    margin: 0 auto;
    height: 95%;
    display: flex;
    flex-direction: column;

    .center-title {
      padding-top: 24px;
      margin-bottom: 24px;
      text-align: center;
      font-weight: inherit;
      font-size: 21px;
    }

    .container-body {
      max-width: 270px;
      margin: 0 auto;

      .changeColor {
        font-size: 15px;
        color: $color-dark;
      }

      .orange {
        width: 29px;
        height: 22px;
        background-color: #b0b9c1;
        mask: url(../../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;
        -webkit-mask: url(../../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;

        &.focusOrange {
          background-color: $color-primary-variant;
        }
      }

      .orangeOff {
        width: 29px;
        height: 22px;
        background-color: #b0b9c1;
        mask: url(../../../assets/images/icon-visibility-off-color-gray.svg) no-repeat center / contain;
        -webkit-mask: url(../../../assets/images/icon-visibility-off-color-gray.svg) no-repeat center / contain;

        &.focusOrange {
          background-color: $color-primary-variant;
        }
      }

      label {
        margin-top: 28px;
      }

      .marginTop {
        margin: 30px 0;
        margin-bottom: 0;
      }

      .cancelBTN {
        width: 193px;
        height: 34px;
        margin: 20px auto;
        margin-bottom: 15px;
      }

      .cursor-pointer {
        cursor: pointer;
      }
    }
  }
}

.header-profile-mobile {
  height: 15px;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid $color-primary-variant;

  .content-back {
    display: flex;
    flex-direction: row;

    .button-back {
      font-size: 20px;
      margin-left: 10px;
      color: #353a3d;
      font-weight: inherit;
    }

    img {
      cursor: pointer;
      height: 23px;
      width: 23px;
    }
  }
}

@media (max-width: 330px) {
  #changes-password-mobile {
    .container-change-password-mobile {

      .container-body {
        max-width: 210px;
        .buttons {
          width: 193px;
        }

        .cancelBTN {
          width: 160px;
        }
      }
    }
  }
}

@media (max-width: 290px) {
  #changes-password-mobile {
    .container-change-password-mobile {

      .container-body {
        max-width: 180px;
        .buttons {
          width: 160px;
        }

        .cancelBTN {
          width: 140px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
