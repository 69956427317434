@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

// hide next and previous year buttons
//-------------------------------------------------
.react-calendar__navigation__arrow {
  &.react-calendar__navigation__prev2-button {
    display: none;
  }
}

.react-calendar__navigation__arrow {
  &.react-calendar__navigation__next2-button {
    display: none;
  }
}
//-------------------------------------------------

// calendar box
.calendarBox {
  width: 328px;
  height: 439px;
  box-shadow: 0px 6px 10px $color-dark-variant;
  border: 3px solid $color-primary-variant;
  border-radius: 14px;

  .header__modal-calendar-step {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 29px;
    padding-bottom: 29px;
    box-sizing: border-box;

    .left,
    .rigth {
      width: 13px;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-primary-variant;
      mask: url(../../assets/images/close.svg) no-repeat center / contain;
      -webkit-mask: url(../../assets/images/close.svg) no-repeat center / contain;
      cursor: pointer;

      &.backArrow {
        width: 19px;
        height: 19px;
        mask: url(../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
        -webkit-mask: url(../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
      }
    }

    .center {
      display: flex;
      align-items: center;
    }
  }

  .react-calendar {
    font-family: "Heebo-Medium";
    padding: 0 20px;

    .react-calendar__navigation {
      padding-bottom: 10px;

      button:disabled {
        border: 0;
      }
    }

    // next and prev arrow class
    .react-calendar__navigation__arrow {
      width: 34%;
      &:hover{
        transform: scale(1.2);
        border-bottom:  1px solid $color-primary;
      }
    }

    .react-calendar__navigation__label__labelText {
      font-size: 18px;
      color: $color-dark-gray;
      text-transform: capitalize;
    }

    button {
      font-family: "Heebo-Medium";
    }

    .react-calendar__month-view__weekdays {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      .react-calendar__month-view__weekdays__weekday {
        abbr {
          text-decoration: none;
          color: $color-dark-gray;
        }
      }
    }

    .react-calendar__month-view__days {
      button {
        font-family: "Heebo-Regular";
        color: $color-text-value;
        height: 30px;
      }
      // paint range
      .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd {
        abbr {
          background-color: $color-primary-variant;
          border-radius: 50%;
          height: 24px;
          width: 24px;
          padding-top: 1px;
          color: $color-dark;
        }
      }
      .react-calendar__tile--range {
        background-color: $color-primary-variant-alpha;
        color: $color-dark;
      }
    }
  }

  .react-calendar__viewContainer {
    button {
      font-family: "Heebo-Medium";
      color: $color-dark-gray;
    }
  }

  .selectionBox {
    padding: 16px 32px;

    .dates {
      display: grid;
    }
  }

  .confirmSelection {
    padding: 0px 95px;
    margin: 19px 0;

    button {
      height: 34px;
    }
  }
}
