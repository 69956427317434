@import "../../themes/colors.scss";
@import '../../themes/fonts.scss';

.content-toast {
  display: flex;
  flex-direction: row;
}

.Toastify__progress-bar-theme--light.progress-success {
  background: $color-success !important;
}

.Toastify__progress-bar-theme--light.progress-error {
  background: $color-error !important;
}

.Toastify__progress-bar-theme--light.progress-none {
  background: none !important;
}

.img-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 10px 36px 0;
}

.toast-content {
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.toast-title {
  font-size: 16px;
  color: $color-dark;
  margin-bottom: 7px;
  font-family: "Ubuntu-Medium", sans-serif;
}

.toast-message {
  font-size: 14px;
  margin-bottom: 10px;
  color: $color-header-gray;
  font-family: "Heebo-Regular", sans-serif;
}

.Toastify__toast-container {
  width: 436px !important;
  position: absolute !important;
}

.Toastify__toast {
  height: 78px;
  border-radius: 10px !important;
}

.Toastify__close-button {
  width: 30px;
  justify-content: flex-end;
}

.Toastify__toast-body {
  flex: 1 1 auto;
  padding: 6px 6px 6px 11px;
  display: flex;
  align-items: center;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
  opacity: 1;
  background-color: transparent;
}

.CustomCloseButton {
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  padding: 12px;
  margin: 10px 4px 0 0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 156, 25, 30%);
  }
}

.CustomCloseButton img {
  width: 12px;
  height: 12px;
  fill: $color-primary-variant;
}

@media only screen and (max-width: 1023px) {
  .CustomCloseButton {
    display: none;
  }

  .Toastify__toast-container {
    width: 312px !important;
    position: absolute !important;
  }

  .Toastify__toast {
    width: 312px !important;
    height: 100px !important;
  }

    .img-content {
      margin: 0 10px 41px 0;
    }
}

@media only screen and (max-width: 480px) {
.Toastify__toast {
    margin-bottom: 15px !important;
  }

.Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}
