@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

#card-image-button-component {
  display: flex;
  width: calc(100% - 16px);
  padding: 11px 8px;
  justify-content: space-between;
  align-items: center;

  div { &:first-child {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }}

  &:hover {
    cursor: pointer;
    background-color: $color-hover-image-button;
  }

  &.disabled {
    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }

  .img-right {
    transform: rotate(180Deg);
    width: 15px;
    height: 15px;
  }

  .container-image-button-text {
    display: flex;
    flex-direction: column;
    min-width: 218px;

    .field-gray {
      font-size: 14px;
    }

    .value {
      &.description {
        font-size: 13px;
      }
    }
  }

  .img-left {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
}
