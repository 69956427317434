@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

#points-module{
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  box-sizing: border-box;

  .container-top{
    display: flex;
    width: 100%;
    min-height: 300px;

    .container-text-points-module{
      margin: 20px;
      width: 43%;
      background-color: $color-light;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 12px;

      h1{
        &:nth-child(3){
          text-align: center;
          font-size: 18px;
        }
        .color-primary{
          color: $color-primary;
        }
      }

      p{
        width: 90%;
        text-align: left;
        margin: 10px 0;

        &:last-child{
          width: 100%;
          padding: 0 40px;
          box-sizing: border-box;
          text-align: left;

          b{
            color: $color-header-gray;
          }
        }
      }

      .saize-button {
        width: 58%;
        text-align: center;
      }
    }

    .container-video-points-module{
      margin: 20px;
      width: 80%;
      background-color: $color-light;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      overflow: hidden;
      border: 4px solid $color-light;
    }
  }

  .content-section-container-list-products-and-services{
    height: auto;
    overflow-y: unset !important;
    overflow-x: unset !important;

    .header-left{
      h1{ width: max-content }
    }

    .horizontal-option-list{
      width: 100%;
      padding: 10px 0;
      position: relative;
      display: flex;
      flex-direction: column;

      .title-and-action-horizontal-option-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 40px;
        box-sizing: border-box;

        &:first-child{
          font-family: 'Heebo-Medium', sans-serif;
          font-size: 16px;
          color: $color-dark-title;
        }

        .complete-button-size {
          width: 170px;
        }

        .show-more{
          cursor: pointer;
        }
      }

      img.image-carousel-option-list{
        width: 100px;
        height: 100px;
        border-radius: 8px;
        margin: 10px;
        background-color: $color-light;
        cursor: pointer;
        object-fit: cover;

        &:hover{ filter: brightness(90%) }
        &:first-child{ margin-left: 20px }
        &:last-child{ margin-right: 20px }
      }

      .button-recharge-balance-horizontal-option-list{
        max-width: 250px;
        margin: 20px 0 20px 40px;
      }
    }
  }

  .container-section-beneficts-list{
    height: auto;

    .header-left{
      h1{ width: max-content }
    }

    .content-section__body {
      &.with-header {
        overflow-y: unset;
      }
    }

    .carousel-beneficts-list{
      .container-content-carousel > div{ align-items: flex-start }
      .card-benefict{
        width: 300px;
        min-width: 300px;
        margin: 16px;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0px 0px 14px -8px rgba(0, 0, 0, 0.5);

        &:first-child{
          margin-left: 32px;
        }&:last-child{
          margin-right: 32px;
        }

        & > div{
          &:first-child{
            padding: 16px 12px;
            img{
              height: 28px;
            }
          }

          &:last-child{
            & > p{
              width: 100%;
              padding: 16px 28px 8px;
              text-align: center;
              box-sizing: border-box;
              text-transform: lowercase;
            }
            & > p::first-letter{
              text-transform: uppercase;
            }
          }
        }
        .buttons-container {
          width: 84%;
          margin: 12px auto;
        }
      }
    }
  }

  .container-bottom{
    margin: 20px 20px 40px;
    width: calc(100% - 40px);
    padding: 20px;
    box-sizing: border-box;
    background-color: $color-light;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    span{
      font-family: 'Heebo-Medium', sans-serif;
      font-size: 16px;
      cursor: pointer;
    }
    &>span{
      color: $color-accent;
    }
    .right{
      margin-right: 400px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  #points-module{
    .container-top{
      flex-direction: column;
      min-height: auto;

      .container-text-points-module{
        width: auto;
        padding: 20px 0;

        .saize-button {
          width: 92%;
        }
      }

      .container-video-points-module{
        width: auto;
        height: 100%;
      }
    }

    .content-section__container{
      .horizontal-option-list{
        align-items: center;

        .title-and-action-horizontal-option-list{
          padding: 0 20px;
        }

        .button-recharge-balance-horizontal-option-list{
          margin: 20px 0 20px;
        }
      }
    }

    .container-section-beneficts-list{
      height: auto;
      overflow-y: unset;
      overflow-x: unset;

      .header-left{
        h1{ width: max-content }
      }

      .carousel-beneficts-list{
        .card-benefict{
          width: 260px;
          min-width: 260px;

          &:first-child{
            margin-left: 16px;
          }&:last-child{
            margin-right: 16px;
          }
        }
      }
    }

    .container-bottom{
      flex-direction: column;
      &>span{
        margin-bottom: 30px;
        text-align: center;
      }
      .right{
        margin-right: 0;
      }
    }
  }
}
