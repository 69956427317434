@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

.card-notifications {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  height: 90%;
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .container-section-notifications {
    display: flex;
    height: calc(100% - 50px);
    overflow: auto;
    flex-direction: column;
    justify-content: space-between;


    .change-button{
      margin-top: 10px;
      margin-left: 80px;
      margin-bottom: 50px;

      &.isMobileDevice {
      margin-top: -40px;
      margin-left: 23px;
      margin-bottom: 50px;
      }

      span{
        color: $color-bg-buton-quternary-gradiant1;
        font-family: "Ubuntu-MediumItalic", serif;
        cursor: pointer;
      }


    }

    .container-title-notificaion {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 80px;
      margin: 2rem 5rem;

      &.isMobileDevice {
        margin: 1.5rem 1rem 1rem 1rem;
      }

      .paragraph-notification-settings {
        font-size: 1em;
        color: #535A5F;
      }


    }

    .settings {
      height: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 5rem;

      &.isMobileDevice {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 0 0.5rem;
      }

      .section {
        width: 40%;
        height: 100%;

        &.isMobileDevice {
          width: auto;
          height: 100%;
          padding: 1rem;
        }

        .title-section {
          text-align: center;
        }

      }

    }

    .footer {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 0 5rem;
      margin-right: 320px;

      &.isMobileDevice {
        display: flex;
        flex-direction: column;
        margin: 1rem 1rem;
        align-items: center;
      }

      .button-redirection {
        width: 260px;
        margin-bottom: 20px;
      }

      .paragraph-notification-settings {
        font-size: 1em;
        margin-bottom: 25px;
        color: #535A5F;
      }
    }
  }
}
