$fontSize: .9rem;

.container-state-switch {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  height: 50px;
  align-items: center;
  margin: 20px;

  &.mobile-mode {
    height: 70px;
  }

  .container-image {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    .delivery-company-image {
      height: 50px;
      width: 50px;
      margin-right: 20px;
      border-radius: 5px;
    }


    .text-state-switch {
      font-size: $fontSize;
      color: #353A3D;
      font-weight: bold;
    }
    .container-description {
      display: flex;
      flex-direction: column;
      margin-top: 72px;
      margin-left: -106px;
      font-family: 'Heebo-Medium', sans-serif;
      color: #535A5F;


      span {
        font-size: 0.74em;
        color: #353A3D;
        margin: 0;

        a {
          display:contents;
          color: #1483C1;
          font-family: 'Heebo-Medium', sans-serif;
          font-weight: 600;
          cursor: pointer;
          font-size: 1em;
        }
      }
    }

    .container-cost {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 115px;

      .text-state-cost {
        font-size: $fontSize;
      }

    }
  }

  input:checked~.control::after {
    background-color: #F08700;
  }
}
