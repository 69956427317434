@import "../../../../../themes/fonts";
@import "../../../../../themes/colors";

#payment-method-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  overflow: auto;

  .inner-container {
    display: flex;
    justify-content: space-between;
    width: 75%;
    height: 100%;

    .delivery-company {
      width: 50%;
      max-width: 500px;
      padding-bottom: 30px;
      overflow: auto;

      &.mobile-mode {
        overflow: hidden;
      }

      .class-notifications {
        padding: 0;

        &.mobile-mode {
          padding: 0 1rem;
        }
      }

      .change-button{
        margin-bottom: 55px;
        span{
          color: $color-bg-buton-quternary-gradiant1;
          font-family: "Ubuntu-MediumItalic", serif;
          cursor: pointer;
        }

        &.mobile-mode{
          margin-top: 0px;
          margin-left: 75px;
          margin-bottom: 15px;
        }
      }

      .center-text {
        text-align: center;
        font-size: 1.3rem;
        padding-bottom: 20px;
      }

      .delivery-company-info {
        display: flex;
        margin: 16px 0;
        justify-content: center;

        .delivery-company-image {
          width: 60px;
          height: 60px;
          margin-right: 1.5rem;

          img {
            width: 100%;
            border-radius: 8px;
          }
        }

        .content-delivery-info-detail {
          .delivery-time {
            display: flex;

            .size {
              font-size: 14px;
            }

            .shipping-time {
              margin-left: 2px;
              font-family: "Heebo-Medium", serif;
            }
          }
        }
      }

      .shipping-value-info {
        display: flex;
        justify-content: space-around;

        &__discount {
          .discount {
            color: $color-success-payments;
          }

          .real {
            color: $color-header-gray;
            font-size: 14px;
            text-decoration: line-through;
          }
        }

        span {
          color: $color-dark;
          font-family: "Heebo-Medium", serif;
        }
      }

      .show-shipping-info-detail {
        margin: 10px 0;
        display: flex;
        justify-content: center;

        label {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            font-family: "Heebo-Medium", serif;
            font-size: 16px;
            color: $color-accent;
          }

          .image {
            mask: url(../../../../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
            -webkit-mask: url(../../../../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
            width: 25px;
            height: 25px;
            background: $color-accent 0 0 no-repeat padding-box;
          }
        }

        &.mobile-mode {
          margin-top: 30px;
        }
      }

      .shipping-values-detail {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;

        .freight-value {
          display: flex;
          justify-content: space-between;
          margin: 4px 0;
          width: 80%;

          .label {
            font-family: "Heebo-Medium", serif;
            font-size: 14px;
          }

          .label-total {
            font-family: "Heebo-Medium", serif;
            font-size: 14px;
            font-weight: bold;
          }

          span {
            font-size: 14px;
          }
        }
      }

      .show-collection-service-info {
        text-align: center;
        margin: 10px 0;

        span {
          color: $color-primary-variant;
          text-align: center;
          font-family: "Heebo-Medium", serif;
        }
      }

      .container-checkbox-and-label {
        justify-content: center;
        margin: 26px 0;

        span {
          font-size: 14px;
          color: $color-text-title-gray;
        }
      }

      .totals-information {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;

        div {
          display: flex;
          justify-content: space-between;

          span {
            font-size: 14px;
          }

          &.shipping-value {
            margin: 8px 0;
          }

          &.totals {
            h2 {
              font-size: 16px;
              font-family: "Heebo-Medium", serif;
            }
          }
        }
      }
    }

    .payment-method {
      width: 50%;
      overflow: auto;
      max-width: 400px;
      max-height: 410px;

      .cash-and-credit-container {
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        .gray-value-color {
          color: $color-text-value;
        }
      }

      .radio-button-container {
        max-width: 300px;
        margin: 10px auto;
      }

      .button-message {
        font-size: 14px;
        text-align: center;
        display: block;
      }

      #deduct {
        input {
          width: 30px;
        }
      }

      .center-text {
        text-align: center;
        font-size: 16px;
      }

      .show-collection-service-info {
        text-align: center;

        span {
          color: $color-primary-variant;
          text-align: center;
          font-family: "Heebo-Medium", serif;
        }
      }

      .recharge-balance {
        margin: 8px auto;
        min-height: 40px;
        height: 40px;
        width: 272px;
      }

      .button-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        width: 100%;

        button {
          max-width: 240px;
          height: 40px;
          min-height: 40px;
        }
      }
    }

    &.mobile-mode {
      width: 90%;
      margin-top: 34px;
      flex-direction: column;
      justify-content: flex-start;
      height: max-content;
      padding-bottom: 40px;

      .delivery-company {
        width: 100%;

        .show-collection-service-info {
          margin-top: 16px;
          margin-bottom: 12px;

          .value {
            font-size: 14px;
          }
        }
      }

      .payment-method {
        .button-container {
          button {
            margin-bottom: 20px;
          }
        }
      }
    }

    &.desktop-mode {
      .delivery-company {
        .shipping-value-info {
          &__discount {
            .real {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}

.modal-recharge-balance {
  .close {
    position: absolute;
    top: 17%;
    left: 64.5%;
    width: 15px;
    height: 15px;
    z-index: 1;
    background-color: $color-primary-variant;
    mask: url(../../../../../assets/images/close.svg) no-repeat center / contain;
    -webkit-mask: url(../../../../../assets/images/close.svg) no-repeat center / contain;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1023px) {
  #payment-method-container {
    padding-top: 0;

    .inner-container {
      margin-top: 0px !important;

      .payment-method {
        width: 100%;
        justify-content: flex-start;
        max-height: fit-content;

        .center-text {
          margin-bottom: 24px;
        }

        .cash-and-credit-container {
          flex-direction: column;
          margin-bottom: 24px;
        }

        .radio-button {
          margin: 6px;
          max-width: 268px;
        }
      }
    }
  }
}
