@import "../../themes/colors.scss";
@import '../../themes/fonts.scss';

#toogle-menu {
  background-color: $color-surface;
  font-family: "Heebo-Regular", sans-serif;
  color: $color-empty-state-text;
  font-size: 12px;
  position: relative;

  .toogle-menu-image {
    width: 30px;
    height: 30px;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100px;
    border-radius: 4px;
    background-color: $color-light;
    padding: 8px 12px;
    display: none;
    box-shadow: 0px -1px 13px -7px $color-empty-state-text, 0px 2px 9px 0px $color-empty-state-text;

    .item {
      display: flex;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }

      .option {
        white-space: nowrap;
        padding: 8px 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  button {
    &:focus {
      .menu {
        display: block;
      }
    }
  }
}
