@import "../../../../../../themes/fonts.scss";
@import "../../../../../../themes/colors.scss";

.content-sender-collection-details {
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: 1px dotted $color-general-placeholder;
    padding-top: 12px;

    .total {
      display: flex;
      justify-content: space-between;

      .total-to-pay {
        font-size: 14px;
        font-family: "Heebo-Medium", serif;
      }

      .total-saved {
        color: #079B0C;
        background: #daf7db 0 no-repeat padding-box;
        border-radius: 12px;
        font-family: "Heebo-Medium", serif;
        font-size: 14px;
        padding: 2px 8px;
      }

      .text {
        font-family: "Heebo-Regular", serif;
        font-size: 14px;
        color: $color-dark;
      }
    }
  }
}

.content-sender-collection-details .content-sender-collection-details__bottom:first-child {
  background-color: red;
  margin-bottom: 10px;
}
