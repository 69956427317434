@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.level-counter-small-version{
  width: 100%;
  height: max-content;
  font-family: "Ubuntu-MediumItalic";
  font-size: 18px;
  color: $color-dark;

  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    img{
      transform: rotate(90deg);
      width: 16px;
    }
  }
}

.level-counter{
  .container-children-rigth-level-counter{
    width: max-content;
    span:last-child{
      color: $color-primary-variant !important;
    }
  }
  
  .header-left, .header-rigth{
    img{
      margin-right: 4px;
      height: 18px !important;
    }
    h1, span{
      width: max-content;
    }
    button{
      width: max-content;
      padding: 0 12px;
    }
    span{ margin-right: 4px }
  }

  .content-level-counter{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .content-level-counter-top{
      width: 100%;
      height: 100%;
      max-height: 260px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .title{
        margin-top: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .progress-in-level-container{
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .container-icon-progress{
          width: 100%;
          height: 70px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;

          .icon-progress{
            position: relative;
            height: 60px;
            border-right: 3px solid $color-primary-variant;
            box-sizing: border-box;
  
            img{
              background-color: $color-primary-variant;
              position: absolute;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              padding: 4px;
              box-sizing: border-box;
              top: 0;
              right: -22px;
            }
          }
        }

        p{
          font-family: "Heebo-Medium";

          span{
            color: $color-primary-variant;
          }
        }
      }
    }

    .text-recomendation-level-counter{
      margin-top: 10px;
      margin-bottom: 10px;
      width: 85%;
      height: min-content;
    }
  }
}

.container-progress-level{
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: $color-dark-light;
  overflow: hidden;

  &.bold{
    height: 6px;
    border-radius: 3px;
  }

  .progress-level{
    background: $color-primary-variant;
    height: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .hidden-in-mobile-mode{
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .visible-in-mobile-mode{
    display: none;
  }
}
