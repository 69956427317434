@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

#profile {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  padding: 0 2%;
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &.mobile-mode {
    padding: 0;
    .header-profile {
      .content-back {
        .button-back {
          margin: 0;
        }
        img {
          margin: 0 0 0 10px;
          width: 16px;
          height: 16px;
        }
      }
      .editButton {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 25px;
          border: 1px solid $color-primary;
          img {
            width: 16px;
            height: 16px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .header-profile {
    border-bottom: 1.5px solid $color-primary-variant;
    height: 62.5px;
    padding: 0 30px 0 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;

    .content-back {
      display: flex;
      flex-direction: row;
      align-items: center;

      .button-back {
        font-size: 20px;
        margin-left: 10px;
        color: #353a3d;
        font-weight: unset;
        letter-spacing: 0;
      }

      img {
        cursor: pointer;
        height: 16px;
        width: 16px;
        padding: 0;
        margin-right: 10px;
        border-radius: 50%;

        &:hover {
          background-color: $color-primary-variant-light;
        }
      }
    }
  }

  .profile {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    .profile-direction {
      height: 70%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
