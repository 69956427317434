@import "../../../themes/colors.scss";

#my-collections {
  width: calc(100% - 25px);
  height: calc(100% - 30px);
  background-color: $color-light;
  border-radius: 10px;
  margin-top: 15px;
  overflow: hidden;

  .top {
    min-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 18px;

    .left {
      display: flex;
      align-items: center;
    }

    .right-mobile {
      display: none;
    }

    .arrow-back {
      width: 30px;
      height: 30px;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 50%;

      &.arrow-back {
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 156, 25, 0.3);
        }
      }
    }

    .arrow-back-mobile {
      display: none;
    }

    .title {
      margin-left: 10px;
    }
  }

  .line {
    border: 1px solid $color-primary-variant;
  }

  .content {
    padding: 8px 30px;
    height: calc(100% - 66px);

    .buttons-filter {
      display: flex;
      max-width: 85%;
      justify-content: space-between;
      align-items: center;
      margin: 0 24px;
      position: relative;

      &__position {
        display: flex;
        align-items: center;
      }

      .select-content {
        display: flex;

        .select-button {
          margin-right: 15px;
        }

        .search-input {
          width: 130px;
          margin-left: 16px;
          margin-right: 24px;
        }

        .icon-right:hover {
          background-color: $color-background;
          border-radius: 30px;
        }
      }
    }

    .my-collections-information {
      margin-top: 13px;
      display: grid;
      grid-template-columns: 100% auto;
      height: calc(100% - 80px);

      &__primary {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: column;
        // height: calc(100% - 113px);
        overflow: auto;

        .less-height {
          height: calc(100% - 60px);
        }

        .show-more-button {
          width: 240px;
          margin: 8px auto;
        }

        .link-content {
          padding-right: 39%;

          p {
            font-family: "Heebo-Regular", serif;
            margin-top: 20px;
            margin-bottom: 30px;
            display: inline-block;
            width: 100%;
            text-align: center;
            color: $color-text-title-dark-gray;

            a {
              color: $color-accent;
              display: contents;
            }
          }
        }

        .table {
          display: flex;
          flex-direction: column;
          z-index: 1;
          width: 100%;
          overflow: hidden;

          .header {
            display: grid;
            grid-template-columns: 169px 105px 210px 145px;
            align-items: center;
            justify-content: unset;
            margin: 0 20px;
            padding: 0 10px;

            span:last-child {
              text-align: end;
            }

            span:nth-child(3) {
              text-align: center;
            }
          }

          .body {
            overflow: auto;
            max-height: 85%;

            &__content {
              display: grid;
              grid-template-columns: 57px 105px 210px 145px;
              align-items: center;
              justify-content: start;
              padding-right: 32px;
              padding-left: 10px;
              margin: 0 20px;
              width: fit-content;
            }

            &__content-text {
              display: grid;
              grid-template-columns: 169px 105px 210px 145px;
              align-items: center;
              justify-content: start;
              margin: 0px;
              margin: 12px 0px;
              padding-right: 32px;
              width: fit-content;
            }

            span:last-child {
              text-align: end;
            }

            span:nth-child(3) {
              text-align: center;
            }

            &__content:hover {
              background-color: $color-background-hover-menu;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }

        .table-mobile {
          display: none;
        }
      }

      &__secondary {
        position: fixed;
        top: 83%;
        right: 1%;
        z-index: 1;

        .button-footer {
          width: 200px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #my-collections {
    .top {
      min-width: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right {
        display: none;
      }

      .right {
        display: flex;
        align-items: center;

        .button-block {
          margin-left: -150px;
          width: 10px !important;
          padding-left: -50px !important;

          .filter-content__button {
            width: 10px !important;
            padding-left: -50px !important;
          }
        }
      }

      .right-mobile {
        display: flex;
        border-radius: 20px;
        border: 1px solid $color-accent;
        padding: 4px;

        img {
          width: 25px;
          height: 25px;
        }
      }

      .title {
        display: flex;
        margin: 0 auto;
      }

      .arrow-back-mobile {
        display: flex;
        width: 17px;
        height: 17px;
      }

      .arrow-back {
        display: none;
      }
    }

    .content {
      padding: 8px 2px;

      .buttons-filter {
        display: flex;
        max-width: 100%;

        &__position {
          display: none;
        }
      }

      .my-collections-information {
        display: flex;
        padding: 0;
        grid-template-columns: 540px auto;

        &__primary {
          flex-direction: row;
          width: 100%;

          .less-height {
            height: calc(100% - 120px);
          }

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .title {
            display: none;
          }

          .table {
            display: none;
          }

          .link-content {
            padding-right: 3%;
            padding-left: 3%;
            margin-bottom: -50px;

            p {
              font-size: 13px;

              a {
                font-size: 14px;
              }
            }
          }

          .table-mobile {
            display: flex;
            flex-direction: column;

            .title-responsive {
              display: block;
              margin: 20px 12px;
            }

            .header {
              display: grid;
              grid-template-columns: 50px 110px;
              align-items: center;
            }

            .body {
              &__content {
                display: grid;
                grid-template-columns: 50px repeat(2, 144px);
                align-items: flex-start;
                margin: 10px 10px;
                padding: 15px;
                box-shadow: 0 5px 15px rgba(35, 41, 46, 0.1490196078);
                border-radius: 10px;

                &--information {
                  display: flex;
                  flex-direction: column;
                  width: 260px;
                }

                &--information:last-child {
                  span {
                    display: flex;
                    justify-content: end;
                  }
                }
              }

              &__content:hover {
                background-color: $color-background-hover-menu;
                border-radius: 5px;
              }
            }
          }
        }

        &__secondary {
          left: 50%;
          margin-left: -100px;
          top: 88%;
        }
      }
    }
  }
}

.method-filter-content {
  max-width: 65% !important;

  &.hide-date-section {
    max-width: 40% !important;
  }

  width: 100%;
}

.filter-report-modal {
  &__content {
    display: flex;
    flex-direction: column;

    .title-filter {
      display: flex;
      margin: 0 auto 30px auto;
    }

    .header-download {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 20px;
    }

    .sections {
      display: grid;
      grid-template-columns: 300px repeat(2, 225px);

      &.hide-date-section {
        display: grid;
        grid-template-columns: 300px 225px;
      }

      .delivery-companies {
        &__content {
          .information {
            display: grid;
            grid-template-columns: 170px 100px;
            align-items: center;
            justify-content: center;

            .name {
              display: grid;
              grid-template-columns: 40px auto;
              margin-left: 15px;

              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }

      .date-information {
        margin-top: 6px;
      }

      .states {
        &__title {
          display: flex;
          align-items: center;
        }

        &__information {
          display: grid;
          grid-template-columns: repeat(2, 80px);
          align-items: center;
          justify-content: center;

          .checkbox {
            display: flex;
            justify-content: end;
          }
        }
      }
    }
  }

  .button-cancel {
    min-width: 180px !important;
    width: 110px;
  }

  .button-confirm {
    width: 188px;
  }
}

.order-my-collections__mobile {
  width: 100%;

  .first-content {
    display: flex;
    flex-direction: column;

    &__items {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 0 30px;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  .second-content {
    &__items {
      display: grid;
      grid-template-columns: 133px auto;
      gap: 0 36px;
      align-items: center;
      justify-content: space-evenly;
    }

    .second-title {
      text-align: center;
      margin: 30px 0 20px 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .method-filter-content {
    max-width: 92% !important;

    .filter-report-modal {
      .sections {
        // display: grid;
        // grid-template-rows: 300px 188px 130px;
        // grid-template-columns: auto;
        // gap: 30px;
        display: flex;
        flex-direction: column;

        .delivery-companies__content {
          .information {
            grid-template-columns: repeat(2, auto);
            justify-content: space-between;
          }
        }

        .date-information {
          margin-top: 32px;

          .date-picker-mobile {
            grid-gap: 0;
          }
        }

        .states {
          &__information {
            display: flex;
          }
        }
      }

      .modal-children-footer .container-buttons-footer {
        button {
          min-width: 220px !important;
          width: 110px !important;
        }

        button:nth-child(2) {
          height: 40px;
        }
      }
    }
  }

  .search-my-collections {
    .container-options__select-general {
      width: 100%;
    }

    .container__input-all {
      .container__input-general {
        .content-top__input-general {
          .icon-right:hover {
            background-color: $color-background;
            border-radius: 30px;
          }
        }
      }
    }
  }

  .filter-content {
    margin-left: 35px;

    &__button {
      min-width: 42px !important;
      height: 35px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-family: Ubuntu-MediumItalic, serif;
        font-size: 16px;
        color: $color-accent;
        padding: 0 5px;
      }
    }
  }
}

.filter-content {
  margin-left: 35px;

  &__button {
    min-width: 120px;
    height: 35px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-family: Ubuntu-MediumItalic, serif;
      font-size: 16px;
      color: $color-accent;
      padding: 0 5px;
    }
  }
}

.magnifying-glass {
  width: 29px;
  height: 22px;
  background-color: $color-accent-varian-gray;
  mask: url(../../../assets/images/search.svg) no-repeat center / contain;
  -webkit-mask: url(../../../assets/images/search.svg) no-repeat center / contain;

  &.focusOrange {
    background-color: $color-accent;
  }
}

.magnifying-glass:hover {
  background-color: $color-accent;
}

.search-my-collections {
  width: 100%;

  .button-mobile {
    padding: 60px 0 29px 0;
  }

  .last-button-mobile {
    margin-bottom: 70px;
  }
}
