@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.content-edit-contact-info {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .content-with-button {
    height: 100%;
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .main-title-edit-contact {
      font-size: 14px;
      margin-top: 5%;
      color: $color-empty-state-text;
      font-family: "Heebo-Regular", sans-serif;

      span {
        font-size: 14px;
        color: $color-empty-state-text;
        font-family: "Heebo-Medium", sans-serif;
      }
    }
    .input-content {
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .content-edit-left {
        padding-right: 12%;

        .inputContainer-contact {
          height: 58px;
          width: 267px;
          margin-bottom: 70px;
        }
      }

      .content-edit-right {
        padding-left: 12%;
        border-left: 1px solid $color-gray-disabled;

        .inputContainer-contact {
          height: 58px;
          width: 267px;
          margin-bottom: 30px;
        }
      }
    }
  }

  .content-button {
    width: 267px;
    height: 12%;
    margin-bottom: 25px;
  }

  &.edit-mobile {
    .content-with-button {
      overflow-y: auto;
      overflow-x: hidden;
      .main-title-edit-contact {
        align-items: center;
        width: 267px;
      }
      .input-content {
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .content-edit-left {
          width: 267px;
          padding: 0;
        }

        .content-edit-right {
          width: 267px;
          padding: 0;
          border: none;
        }
      }
    }
    .content-button {
      width: 267px;
      height: 12%;
      margin-top: 20px;
    }
  }
}
