@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

$leter-size-in-ch: 5ch;
$spacing: .5 * 1ch;
$quantity-of-characters: 6;
$with: $quantity-of-characters * (1ch + $spacing);

.input-code {
  display: block;
  margin: 25px auto;
  border: none;
  padding: 0;
  width: 205px;
  background: repeating-linear-gradient(90deg, $colr-ligth-gray 0, $colr-ligth-gray 1ch, transparent 0, transparent 1ch + $spacing) 0
    100% / #{$with - $spacing} 3px no-repeat;
  letter-spacing: $spacing;
  color: $color-dark;

  font-family: 'Heebo-Regular', sans-serif;
  font-size: $leter-size-in-ch;

  &:focus {
    outline: none;
    color: $color-accent;
  }
}

@media (max-width: 900px) {
  .input-code {
    width: 230px;
  }
}
