@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.modal-new-terms-and-conditions{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px 20px;
  box-sizing: border-box;

  h1{
    margin: 30px 0;
  }
  p{
    max-width: 500px;

    span{
      text-decoration: underline;
      color: $color-accent;
      cursor: pointer;
    }
  }
  .accept-new-terms-and-conditions{
    max-width: 500px !important;
    margin: 20px 0;
    .text-checkbox{ margin-left: 8px }
  }
  button{
    max-width: 250px;
    min-height: 40px !important;
    max-height: 40px !important;
    height: 40px !important;
  }
  .error-message{
    margin-top: 4px;
  }
}