@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.carousel{
  width: 100%;
  height: min-content;
  position: relative;

  .change-margin-left{
    padding: 14px 12px 14px 16px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: $color-light;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    cursor: pointer;

    &:first-child{
      transform: rotate(180deg);
    }

    &.hidden{
      visibility: hidden;
      opacity: 0;
    }
  }

  .container-content-carousel{
    width: 100%;
    overflow: hidden;

    & > div{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      transition: all 200ms ease;
    }
  }
}
