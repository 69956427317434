@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.container-wallet__section-desktop{
  border-radius: 12px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .primary-column{
    width: 80%;
    height: 100%;
    visibility: visible;
    opacity: 1;
  }

  .secondary-column{
    width: 45%;
    visibility: visible;
    opacity: 1;
    padding: 0 20px;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .mile-counter-wallet{
      width: calc(100% - 40px);
    }
  }

  .full-container{
    width: 100%;
  }

  .hidden{
    width: 0%;
    visibility: hidden;
    opacity: 0;
  }
}

.cash-content {
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 14px;
    color: $color-dark;
    margin-bottom: 6px;
    font-family: 'Heebo-Medium', sans-serif;
  }
}
