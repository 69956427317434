@import '../../../themes/colors.scss';
@import '../../../themes/fonts.scss';

#reset-password{
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  overflow: auto;
  box-shadow: 0px 16px 12px -10px rgba(108, 152, 181, 0.5);

  .title-reset-password{
    margin: 0 0 20px 0;
  }

  button{
    margin: 20px 0;
    width: 85%;
  }

  .form__reset-password{
    margin-top: 20px;
    width: 85%;
    max-width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    button{
      width: 100%;
    }
  }

  a{
    font-family: "Heebo-Regular", sans-serif;
    font-size: 16px;
    color: $color-accent;
    cursor: pointer;
  }

  &.desktop-mode{
    .form__reset-password{
      max-width: 300px;
    }

    button{
      max-width: 250px;
    }
  }
}
