@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

.card-container-mobile-shipping-details {
  background: #FFFFFF;
  width: calc(100% - 30px);
  height: calc(100% - 20px);
  border-radius: 11px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.05);

  .content-datails-shipment {
    overflow: auto;
    height: calc(100% - 3rem);
    margin: 0;


    .content-datails {
      padding: 0;
      display: flex;
      margin: 0 1.5rem;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      padding-top: 8px;

      .container-error {
        background-color: white;
        position: sticky;
        top: 0;
        padding-bottom: 10px;

        .container-title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          background-color: $colot-background-error;
          border-radius: 8px;

          .image {
            border-radius: 50%;
            border-color: $color-error;
            border-style: solid;
            border-width: 2px;
            min-width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;

            .ex {
              background-color: $color-error;
              mask: url(../../../assets/images/close.svg) no-repeat center / contain;
              -webkit-mask: url(../../../assets/images/close.svg) no-repeat center / contain;
              width: 7px;
              height: 7px;
            }
          }

          .title {
            h3 {
              text-align: left;
              font-family: "Heebo-Regular", sans-serif;
              color: $color-empty-state-text;
              font-weight: normal;
              font-size: 14px;
            }
          }
        }
      }

      .secondary {
        width: 200px;
        margin-bottom: 3rem;

        .button-inner-text {
          margin-right: 10px;
        }
      }

    }
  }
}
