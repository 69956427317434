@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

.container-select-filter {
  margin: 2px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  .container-selector {
    max-width: 260px;
    height: 32px;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: $color-primary solid 1px;

    .label {
      font-family: "Heebo-Regular", serif;
      font-size: 16px;
      color: $color-primary;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
    }

    img {
      width: 32px;
      margin: 0 10px;
      color: $color-primary;
    }
  }

  .container-options {
    position: absolute;
    width: 184px;
    top: 32px;
    left: 0;
    height: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background-color: white;
    overflow: hidden;
    max-height: 200px;
    overflow-y: auto;

    .options-delivery-company {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      font-family: "Heebo-Regular", sans-serif;
      font-size: 16px;
      color: $color-dark;

      &:hover {
        background-color: #009AF01A;
        width: 100%;
      }

      img {
        height: 28px;
        border-radius: 4px;
        margin-right: 5px;
      }
    }
  }

  &.show {
    box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);

    .container-options {
      height: auto;
      box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  }
}
