@import '../../../themes/fonts';
@import '../../../themes/colors';
@import '../../../themes/general';

.container-tracking__shipping-details{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .subtitle{ margin-bottom: 16px}

  .delivery-tracking{
    &.mobile{
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .container-step-progress {
        width: 32px;
        margin-top: 4px;

        &.vertical{
          height: auto;
          #container-points__step-progress{
            height: auto;
            .step-point {
              margin: 16px 0;

              &:first-child{
                margin-top: 0;
              }
            }
          }

          #container-lines__step-progress{
            height: auto;
            margin-top: 6px;

            .step-line {
              height: 44px;
            }
          }
        }

        &.select-step-1{
          .point-1 {
            margin-bottom: 116px !important;
          }
          .line-1{
            height: 144px !important;
          }
        }
        &.select-step-2{
          .point-2 {
            margin-bottom: 116px !important;
          }
          .line-2{
            height: 144px !important;
          }
        }
        &.select-step-3{
          .point-3 {
            margin-bottom: 116px !important;
          }
          .line-3{
            height: 144px !important;
          }
        }
        &.select-step-4{
          .point-4 {
            margin-bottom: 116px !important;
          }
          .line-4{
            height: 144px !important;
          }
        }
        &.select-step-5{
          .point-5 {
            margin-bottom: 116px !important;
          }
          .line-5{
            height: 144px !important;
          }
        }
        &.select-step-6{
          .point-6 {
            margin-bottom: 116px !important;
          }
          .line-6{
            height: 144px !important;
          }
        }
        &.select-step-7{
          .point-7 {
            margin-bottom: 116px !important;
          }
          .line-7{
            height: 144px !important;
          }
        }
      }

      .container-states-and-details{
        .state-and-details{
          margin: 24px 0;

          &:first-child{
            margin-top: 0;
          }

          .state{
            width: 100%;
            display: flex;
            align-items: center;
            height: 20px;
            font-family: "Heebo-Medium", serif;
            color: $color-text-value;
            font-size: 16px;
            cursor: pointer;

            &.checked{
              color: $color-primary-variant;
            }

            &.error{
              color: $color-error;
            }
          }

          .details{
            height: 0;
            width: 0;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;

            &.show{
              height: 100px;
              width: 100%;
              overflow: hidden;
              opacity: 1;
              visibility: visible;
            }

            .state-description{
              font-size: 14px;
            }
          }
        }
      }
    }

    &.desktop{
      margin-top: 20px;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .container-states{
        width: calc(100% + 15%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .state{
          padding-top: 16px;
          margin-top: -7px;
          width: 15%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-family: "Heebo-Regular", serif;
          font-size: 14px;
          color: $color-general-text;

          &.checked{ color: $color-primary-variant }
          &.error{ font-family: "Heebo-Medium", serif; color: $color-error }
          &.pointer{ cursor: pointer }
          &.disabled{ color: $color-general-disabled }
        }
      }

      .container-details{
        width: calc(100% + 7.5%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 32px;
      }

      .container-details-novelties{
        width: calc(100% + 7.5%);
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(4, auto);

        .head-table-details-novelties{
          font-family: "Heebo-Medium", serif;
          font-size: 16px;
          color: $color-dark-title;
        }

        .one{
          grid-column: 1;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          text-align: center;
          padding: 0 10px;
          box-sizing: border-box;
          max-width: 200px;
        }

        .two{ grid-column: 2 / 4; display: flex; align-items: flex-start; justify-content: flex-start }

        .three{grid-column: 4; display: flex; align-items: flex-start; justify-content: flex-end }

        .button-manage-solution{
          grid-column: 3 / 5; display: flex; align-items: flex-start; justify-content: flex-end;
          button{
            margin-top: 20px;
            max-width: 250px;
          }
        }

        p{ margin: 8px 0 }
      }
    }
  }

  .data{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img{
      width: 48px;
      height: 48px;
    }

    .state-description{
      width: 180px;
      margin: 0 80px 0 20px;
    }

    .container-dates{
      display: flex;
      flex-direction: column;
      align-items: center;

      .primary-date{
        display: flex;
        align-items: center;

        span{
          font-family: "Heebo-Medium", serif;
          font-weight: 600;
          font-size: 14px;
        }

        .day{
          margin-top: 6px;
          margin-right: 4px;
          font-size: 40px;
          color: $color-primary-variant;

          &.error{
            color: $color-error;
          }
        }

        .month-year{
          height: 40px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          color: $color-text-label-input;
          span::first-letter{ text-transform: uppercase }
        }

        .hour{
          margin-left: 16px;
          font-weight: unset;
        }
      }
    }
  }
}
