@import '../../themes/colors.scss';

* {
  transition: none !important;
}

.date-picker {
  &__content {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &-items {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .value {
        margin-bottom: 12px;
      }

      .datepicker-content {
        display: flex;
        align-items: center;

        .icon-calendar {
          margin-right: 6px;
        }
      }
    }
  }
}

@media only screen and (min-width: 375px) and (max-width: 1020px) {
  .date-picker__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.MuiInputBase-root {
  width: 135px;
  border: 1px solid $color-empty-state-text;
  border-radius: 5px !important;
  padding-right: 5px !important;

  input {
    text-align: center;
    padding: 4px 0;
  }

  input:focus-visible {
    outline: 0;
  }

  &.MuiButtonBase-root {
    visibility: hidden;
  }

  &:hover {
    border-color: $color-accent !important;
  }
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border: none !important;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: $color-accent !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiIconButton-root {
  flex: 0 !important;

  &.Mui-disabled {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.26) !important;
    border: none !important;
  }
}

.MuiInput-underline::before, .MuiInput-underline::after {
  border-bottom: 0 !important;
  border-color: transparent;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
}
