@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

.container-shipping-history-and-quotes {
  .content-section__header {
    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: max-content;
      font-family: 'Heebo-Bold', sans-serif;
      font-size: 18px;
      font-weight: unset;
    }

    .updateShippingList {
      width: max-content;
      padding: 7px;
      margin-right: 12px;


      &.noShow {
        visibility: hidden !important
      }

      img {
        width: 20px
      }

      span {
        margin: 0 8px
      }
    }
  }



  .header-left {
    width: 20% !important;
  }

  .header-rigth {
    width: 25% !important;
  }

  &.mobile-container-header {
    &.shipping-selected-no-right {
      .header-rigth {
        width: 0 !important;
      }
    }

    .header-rigth {
      width: 15% !important;
    }
  }
}

.normal-left-and-right {
  .header-left, .header-rigth  {
    width: auto !important;
  }
}

#container-shipping-history {
  width: 100%;
  height: 100%;

  .container-list__shipping-history {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    z-index: 0;

    .image-empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;

      .buttonEmpyState {
        max-width: 280px;
      }
    }

    .button-download__shipping-history {
      position: fixed;
      bottom: 40px;
      width: 80%;
      max-width: 250px;
      right: 60px;
    }
  }

  &.mobile-mode {
    .text-options__shipping-history {
      text-align: center;
      color: $color-general-text;
    }

    .container-list__shipping-history {
      height: calc(100% - 80px);
      width: 100%;
      max-width: 100%;
      overflow-x: hidden;
      display: grid;

      .container-flotant-button__shipping-history {
        position: fixed;
        bottom: 36px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: white;
        width: 80%;
        left: 10%;
        box-shadow: 4px 16px 14px -8px rgba(0, 0, 0, 0.25);
      }

      .bottom-shippint-list-mobile {
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .see-more {
        * {
          color: $color-primary-variant;
        }
      }
    }
  }

  &.desktop-mode {
    .container-list__shipping-history {
      height: calc(100% - 144px);
    }
  }
}

.info-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;

  .preloader {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: 3px solid #eee;
    border-top: 3px solid #666;
    border-radius: 50%;
    animation-name: girar;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes girar {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
