@import "../../../../../themes/colors.scss";
@import "../../../../../themes/fonts.scss";

.contacInfo-container {
  height: 85%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  .main-title {
    font-size: 14px;
    margin-bottom: 8%;
    text-align: center;
    color: $color-empty-state-text;
    font-family: "Heebo-Regular", sans-serif;

    span {
      font-size: 13px;
      color: $color-empty-state-text;
      font-family: "Heebo-Medium", sans-serif;
    }
  }

  .content-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .content-left {
      max-width: 281px;
      padding-right: 6%;

      div {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .title-info {
          font-size: 16px;
          color: $color-empty-state-text;
          font-family: "Heebo-Medium", sans-serif;

          span {
            font-size: 14px;
            font-family: "Heebo-Regular", sans-serif;
          }
        }
      }
      .subtitle-info {
        font-size: 16px;
        color: $color-empty-state-text;
        font-family: "Heebo-Regular", sans-serif;
      }
    }
    .last {
      margin-bottom: 0;
    }
  }
  .content-right {
    max-width: 281px;
    padding-left: 6%;
    border-left: 1px solid $color-gray-disabled;

    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .title-info {
        font-size: 16px;
        color: $color-empty-state-text;
        font-family: "Heebo-Medium", sans-serif;
      }
      .subtitle-info {
        font-size: 16px;
        color: $color-empty-state-text;
        font-family: "Heebo-Regular", sans-serif;
      }
    }
    .last {
      margin-bottom: 0;
    }
  }

  &.invoicing-mobile {
    height: 100%;
    overflow-x: hidden;
    .main-title {
      margin: 20px 0;
      max-width: 300px;
    }
    .content-info {
      max-width: 300px;
      width: auto;
      display: flex;
      margin-bottom: 30px;
      align-items: flex-start;
      flex-direction: column;

      .content-left {
        max-width: 285px;
        width: auto;
        padding: 0 10px;

        div {
          .title-info {
            line-break: normal;
          }
        }
      }
      .content-right {
        max-width: 285px;
        width: auto;
        padding: 0 10px;
        border: none;

        div {
          .title-info {
            line-break: normal;
          }
        }
      }
    }
  }
}
