@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.apikey-container {
  width: 60%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-direction {
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 18px;
      font-family: "Heebo-Medium", sans-serif;
    }
  }

  .text-direction {
    display: flex;
    width: 100%;
    font-size: 14px;
    flex-direction: column;
    align-items: flex-start;
    color: $color-empty-state-text;
    font-family: "Heebo-Regular", sans-serif;
    margin-bottom: 30px;
  }

  .apikey {
    width: 100%;
    margin-bottom: 25px;
    border-bottom: 2px solid $color-gray-disabled;

    .apikey-title {
      font-size: 14px;
      color: $color-text-label;
      font-family: "Heebo-Regular", sans-serif;
    }

    .apikey-info {
      display: flex;
      height: 25px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;

      .apikey-info-left {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        padding: 0 5px 0 10px;
        cursor: pointer;

        .copy-item {
          width: auto;
          padding: 0 10px;
        }

        .password {
          font-size: 18px;
          color: $color-header-gray;
          font-family: "Heebo-Regular", sans-serif;
          overflow-x: auto;
          white-space: nowrap;
          -ms-overflow-style: none;
          scrollbar-width: none;
          max-width: 210px;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .tooltip {
          visibility: hidden;
          background-color: $color-white;
          color: $color-header-gray;
          text-align: center;
          border-radius: 5px;
          padding: 5px;
          position: absolute;
          font-size: 12px;
          z-index: 1;
          bottom: 140%;
          right: -28px;
          margin-left: -50px;
          opacity: 0;
          transition: opacity 0.3s;
          width: 86px;
          font-family: "Heebo-Regular", sans-serif;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

          &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $color-white transparent transparent transparent;
          }
        }

        &:hover .tooltip {
          visibility: visible; /* Muestra el tooltip al hacer hover */
          opacity: 1;
        }

        &:hover {
          border-radius: 25px;
          background-color: $color-blue-light-hover;
        }
      }

      .apikey-info-rigth {
        cursor: pointer;
        .icon-show {
          border-radius: 25px;
          padding: 4px;
          content: url("../../../../assets/images/IconVisibilityOff.svg");

          &:hover {
            background-color: $color-blue-light-hover;
            content: url("../../../../assets/images/IconVisibilityOffBlue.svg");
          }
        }

        .icon-hidden {
          border-radius: 25px;
          padding: 4px;
          content: url("../../../../assets/images/IconVisibility.svg");

          &:hover {
            background-color: $color-blue-light-hover;
            content: url("../../../../assets/images/IconVisibilityBlue.svg");
          }
        }
      }
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .delete-button {
      width: 126px;
      height: 38px;
    }

    .create-button {
      width: 183px;
      height: 42px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .apikey-container {
    width: 80%;

    .apikey {
      .apikey-info {
        .apikey-info-left {
          .password {
            max-width: 120px;
          }
        }
        .apikey-info-rigth {
          .icon-hidden {
          padding: 0;
        }
        }
      }
    }
  }
}
