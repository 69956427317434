@import '../../themes/colors.scss';

.container-section.full-width {
  width: 100% !important;
}

@media only screen and (max-width: 1023px) {
  .container-section {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 80px);
    top: 80px;
    left: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .container-section {
    position: fixed;
    width: calc(100% - 230px);
    height: calc(100% - 90px);
    bottom: 0;
    right: 0;
  }
}
