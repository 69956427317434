@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.container__input-all {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
  transition: all 200ms ease !important;

  * {
    transition: all 200ms ease !important;
  }

  &.contaier__input-operation {
    width: 278px;
    margin: 0 auto;
  }

  .span__input-general {
    width: 100%;
    height: auto;
    font-size: 14px;
    font-family: "Heebo-Regular", serif;
    color: $color-general-text;

    &.disabledFirstSpan {
      color: $color-gray-disabled;
    }

    &.error {
      color: $color-error;
    }

    &.last {
      color: $color-text-label-input-fiel;
      font-size: 14px;
    }

    &.labelCreateDeliveryCompany {
      color: $color-text-label;
    }

    &.processColor {
      color: $color-dark;
    }

    &.placeholder {
      color: $color-general-placeholder;
    }

    &.foscus {
      color: $color-dark;
    }
  }

  .container__input-general {
    width: 100%;

    .content-top__input-general {
      padding: 0 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &.file-span {
        &:hover {
          cursor: pointer;

          .add_box {
            background-color: $color-primary-variant-light;
          }
        }
      }

      img {
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 5px;

        &.cursor-pointer {
          cursor: pointer;

          &:hover {
            border-radius: 50%;
            background-color: $color-primary-variant-light;
          }
        }
        span {
          margin-bottom: 5px;
        }
      }

      .icon-wrapper {
        height: 34px;
        width: 34px;
        padding: 2px 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        div.search-icon-blue {
          height: 24px;
          width: 24px;
          background-color: $color-accent;
          mask: url(../../assets/images/search.svg) no-repeat center / contain;
          -webkit-mask: url(../../assets/images/search.svg) no-repeat center / contain;
        }

        div {
          height: 25px;
          width: 24px;

          &.no-visibility-icon-orange {
            background-color: $color-gray-disabled;
            mask: url(../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;
            -webkit-mask: url(../../assets/images/icon-visibility-color-gray.svg) no-repeat center / contain;
          }

          &.visibility-icon-orange {
            background-color: $color-gray-disabled;
            mask: url(../../assets/images/icon-visibility-off-color-gray.svg) no-repeat center / contain;
            -webkit-mask: url(../../assets/images/icon-visibility-off-color-gray.svg) no-repeat center / contain;
          }
        }

        &.hover {
          &:hover {
            cursor: pointer;
            border-radius: 50%;
            background-color: $color-primary-variant-light;

            div {
              &.no-visibility-icon-orange {
                background-color: $color-primary-variant;
              }

              &.visibility-icon-orange {
                background-color: $color-primary-variant;
              }
            }
          }
        }
      }
    }

    input {
      width: 100%;
      height: 30px;
      margin: 6px 0;
      font-size: 16px;
      border: none;
      outline: none;
      font-family: "Heebo-Regular", serif;
      color: $color-header-gray;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0 50px #fff inset;
        -webkit-box-shadow: 0 0 0 50px #fff inset;
      }

      &:disabled {
        background-color: initial;
        color: $color-gray-disabled;
      }

      &::placeholder {
        color: $color-accent-varian-gray;
      }

      &:focus {
        &::placeholder {
          color: $color-gray-disabled;
        }
      }

      &.typeHidden {
        overflow: hidden;
        height: 0;
        width: 0;
        padding: 0;
        font-size: 0;
      }

      &.fontSizeCreateDeliveryCompany {
        font-size: 18px;
        color: $color-text-label-input;
      }

      &.focusChange::placeholder {
        color: #afb4b8;
      }

      &.focusChange:focus::placeholder {
        color: #6b6b6b;
      }
    }

    hr.help-to-line {
      width: 100%;
      box-sizing: border-box;
      border: solid 1px $color-dark-light;
      border-radius: 1px;
      background-color: $color-dark-light;

      &.line-error {
        border: solid 1px $color-error;
        background-color: $color-error;
      }

      &.transparent {
        border: solid 1px transparent !important;
        background-color: transparent;
      }

      &.lineHidden {
        overflow: hidden;
        height: 0;
        width: 0 !important;
        margin: 0;
        padding: 0;
        font-size: 0;
        border-width: 0;
      }
    }
  }

  .container-suggestions__input-general {
    position: relative;
    width: 100%;
    height: 0;
    overflow-y: hidden;
    visibility: hidden;

    ul {
      list-style: none;
      position: absolute;
      width: 100%;
      height: 0;
      max-height: 120px;
      overflow-y: auto;
      background-color: $color-light;
      border: 1px solid $color-dark-light;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      transition: all 2000ms ease !important;
      z-index: 2;

      li {
        text-decoration: none;
        padding: 8px 16px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: "Heebo-Regular", serif;
        color: $color-dark;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    &.visible {
      overflow-y: visible;
      visibility: visible;

      ul {
        height: auto;
      }
    }
  }

  .container-lines__input-general {
    position: relative;
    width: 100%;
    height: 2px;
    margin-top: -2px;
    border-radius: 1px;
    overflow: hidden;
    background: transparent;

    * {
      height: 100%;
      width: 0;
      top: 0;
      left: 0;
      position: absolute;
    }

    .line-focused {
      width: 100%;
      background: $color-primary-variant;
    }

    .line-focused-register {
      width: 100%;
      background: $color-accent-variant-register;
    }

    .lineHidden {
      overflow: hidden;
      height: 0;
      width: 0 !important;
      margin: 0;
      padding: 0;
      font-size: 0;
      visibility: hidden;
      border-width: 0;
    }
  }

  .annotation__input-general {
    width: 100%;
    height: auto;
    font-size: 14px;
    font-family: "Heebo-Regular", serif;
    color: $color-anotation;

    &.focus {
      color: $color-anotation;
    }

    &.error {
      font-size: 0;
      color: $color-error;

      &.visible {
        font-size: 14px;
      }
    }

    &.lineHidden {
      overflow: hidden;
      height: 0;
      width: 0 !important;
      margin: 0;
      padding: 0;
      font-size: 0;
      visibility: hidden;
      border-width: 0;
    }
  }

  &.hidden {
    margin: 0;

    * {
      overflow: hidden;
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
      font-size: 0;
    }
  }
}
