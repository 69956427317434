@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

* {
  transition: all 200ms ease !important;
}

.containter-select-all {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 5px 0;

  &.disabled {
    color: $color-gray-disabled;
    cursor: default;
  }

  &.top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.top-rigth {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &.bottom-left {
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.bottom-rigth {
    align-items: flex-end;
    justify-content: flex-end;
  }

  .container-value-selected__select-general {
    padding: 4px 0 4px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    box-sizing: border-box;

    span {
      margin-right: 5px;
      font-family: "Heebo-Regular", sans-serif;
      font-size: 16px;
      width: max-content;
      // white-space: nowrap;

      &.grayColor {
        color: $color-header-gray;
      }
      &.orangeColor {
        color: $color-primary-variant;
      }

      &.accentColor {
        color: $color-accent;
      }
    }

    img {
      height: 24px;
      width: 24px;
      margin-right: 4px;
    }
  }

  .container-options__select-general {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $color-light;
    border-radius: 6px;
    z-index: 1;

    &.primary {
      border: 1px solid $color-primary-variant;
    }

    &.accent {
      border: 1px solid $color-accent;
    }

    &.gray {
      border: 1px solid $color-header-gray;
    }
    &.gray {
      border: 1px solid $color-header-gray;
    }

    &.disabled {
      border: 1px solid $color-gray-disabled;
    }

    * {
      transition: all 400ms ease !important;
    }

    .container-value-selected__select-general {
      opacity: 1;

      .accent {
        color: $color-accent;
      }

      .primary {
        color: $color-primary-variant;
      }

      .gray {
        color: $color-header-gray;
      }
    }

    &.visible {
      border-color: transparent;
      box-shadow: 0 3px 6px $color-box-shadow-select;
      background: var($color-light) 0 0 no-repeat padding-box;
      z-index: 99;

      .container-value-selected__select-general {
        // border-radius: 6px;
        position: relative;
        //width: 100%;
        //top: -2px;
        //left: -1%;
        //background: white;

        &.accent {
          border-bottom: 1px solid $color-accent;
        }

        &.primary {
          border-bottom: 1px solid $color-primary;
        }

        &.gray {
          border-bottom: 1px solid $color-header-gray;
        }
      }

      .grayColor {
        border-width: 0 0 1px 0;
        border-color: $color-header-gray;
        border-radius: 0;
      }
    }

    span.option-select-general {
      padding: 0;
      font-size: 0;
      font-family: "Heebo-Regular", sans-serif;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: $color-background;
      }

      &.selected {
        background-color: $color-background;
      }

      &.grayColor {
        color: $color-header-gray;
      }

      img {
        width: 0;
      }
    }

    &.visible {
      span.option-select-general {
        padding: 10px;
        font-size: 16px;
        display: flex;
        align-items: center;
        flex-direction: row;

        img {
          width: 25.5px;
          margin-right: 10px;
        }
      }
    }
  }

  .annotation__select-general {
    width: 100%;
    height: auto;
    font-size: 12px;
    font-family: "Heebo-Regular", sans-serif;
    color: $color-dark;
    margin-top: 2px;

    &.focus {
      color: $color-accent-variant;
    }

    &.error {
      font-size: 0;
      color: $color-error;

      &.visible {
        font-size: 12px;
      }
    }
  }
}
