@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";

#my-discounts {
  height: calc(100% - 40px);
  background-color: $color-surface;
  border-radius: 10px;

  .wrapper-header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-primary;

    .go-back {
      padding: 6px;
      height: 16px;
      width: 20px;
    }

    .go-back:hover {
      background-color: rgba(255, 156, 25, .3);
      border-radius: 45px;
    }

    .title {
      padding-left: 10px;
    }
  }

  .wrapper-discounts {
    display: flex;
    flex-direction: column;

    .information {
      display: flex;

      &__assessor {
        padding-right: 90px;

        .data {
          padding-bottom: 6px;
        }

        .data-first {
          padding-top: 8px;
          padding-bottom: 6px;
        }
      }

      &__sendings {
        padding-right: 28px;

        .data {
          margin-top: 8px;
        }

        .data-green {
          margin-top: 8px;
          color: $color-success-payments;
        }
      }

      &__money {
        width: 186px;
        height: 79px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $color-gray-light;
        border-radius: 4px;

        .saved-money {
          font-style: italic;
          font-weight: bold;
          margin-bottom: 8px;
        }

        .total {
          color: $color-success-payments;
          font-weight: bold;
          margin-top: 5px;
        }
      }
    }

    .description-negotiation {
      .description {
        margin-top: 7px;
      }
    }
  }

  &.mobile-mode {
    width: calc(100% - 40px);
    height: calc(100% - 30px);

    .wrapper-header {
      display: flex;
    }

    .wrapper-discounts {
      padding: 10px 15px;
      height: 485px;
      overflow-y: auto;

      .information {
        flex-direction: column;

        &__money-mobile {
          background-color: $color-gray-light;
          padding: 20px;
          border-radius: 4px;
          margin-top: 8px;
          margin-bottom: 24px;

          .saved-money {
            font-size: 16px;
            font-style: italic;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .total {
            color: $color-success-payments;
            font-weight: bold;
            margin-top: 10px;
          }
        }

        &__money {
          display: none;
        }

        &__assessor {
          margin-bottom: 15px;
        }

        &__sendings {
          margin-bottom: 24px;
        }
      }

      .table-discounts {
        margin: 24px 0 15px 0;

        .title-table {
          text-align: center;
          margin-bottom: 16px;
        }

        .mobile-information  {
          margin-bottom: 24px;

          .item_company {
            margin-bottom: 5px;
          }

          .item_company h1 {
            font-size: 16px;
            font-family: "Heebo-Medium", serif;
            color: $color-dark;
          }

          .item {
            display: grid;
            grid-template-columns: 150px 120px;
            grid-gap: 20px;
            margin: 12px 0;
            position: relative;

            &__title {
              font-size: 14px;
              font-family: "Heebo-Medium", serif;
              color: $color-header-gray;
            }

            &__information {
              position: absolute;
              left: 70%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  &.desktop-mode {
    margin-right: 20px;
    margin-top: 20px;

    .wrapper-discounts {
      padding: 20px 56px 70px 56px;

      .none {
        display: none;
      }

      .information {
        display: grid;
        grid-template-columns: auto auto auto auto auto;

        &__money {
          padding: 5px;
        }
      }

      .table-discounts {
        margin-top: 24px;

        .title-table {
          margin-bottom: 18px;
        }

        .table-header {
          width: 100%;

          &__title {
            font-family: "Heebo-Medium", serif;
            font-weight: normal;
            font-size: 16px;
            color: $color-dark;
            min-width: 100px;
            padding-bottom: 6px;
          }
        }

        .table-body {
          &__content:first-child{
            text-align: start;
            min-width: 100px;

            .item {
              margin: 8px 0;
            }
          }

          &__content {
            min-width: 100px;
            text-align: center;
          }
        }
      }

      .description-negotiation {
        margin-top: 18px;
      }
    }
  }
}
