@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.main-dinamic-data {
  display: flex;
  align-items: center;
  flex-direction: column;

  #content-contact-profile {
    width: auto;
    min-width: 400px;
    margin-left: 2%;

    &.mobile-mode {
      min-width: auto;
      margin: 0 4%;
    }
  }

  .complete-data {
    margin: 25px 0;
    width: 209px;
  }

  .complete-data-mobile {
    margin: 25px 0;
    padding: 0 10px;
    width: 267px;
  }
}
