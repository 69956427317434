@import '../../../themes/fonts.scss';
@import '../../../themes/colors.scss';

.card-section-mobile{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-light;
  border-radius: 12px;
  margin: 14px;
  padding: 10px;
  box-sizing: border-box;

  .container-img__card-section-mobile{
    height: 100%;
    width: calc(33.3% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      height: 50px;
      width: 50px;
    }
  }

  span{
    margin-left: 20px;
    font-family: "Ubuntu-MediumItalic";
    font-size: 16px;
    color: $color-dark;
  }
}
