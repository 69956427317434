  @import "../../themes/colors.scss";
  @import "../../themes/fonts.scss";
  .header-button-back {
    border-bottom: 1.5px solid $color-primary-variant;
    height: 50px;
    padding: 0 30px 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;

    .button-header {
      display: none;
    }

    .content-back {
      display: flex;
      flex-direction: row;
      align-items: center;

      .button-back {
        font-size: 20px;
        margin-left: 10px;
        color: #353a3d;
        font-weight: unset;
        letter-spacing: 0;
      }

      img {
        cursor: pointer;
        height: 16px;
        width: 16px;
        padding: 8px;
        border-radius: 50%;

        &:hover {
          background-color: $color-primary-variant-light;
        }
      }
    }

    &.mobile-mode {
      padding: 0;
      height: 60px;
      top: -80px;
      left: 20px;
      width: calc(100vw - 40px);
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .content-back {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 10px
      }

      .button-header {
        border: 1px solid white;
        background-color: white;
        width: 80%;
        display: block;

        button {
          min-width: 132px;
          width: 132px;
          min-height: 28px;
          height: 28px;
        }
      }
    }
  }
