@import "../../../../../themes/fonts";
@import "../../../../../themes/colors";

section {
  width: 100%;
  height: 100%;

  .contentStepQuoteShippingExpress {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content.stepOneExpress {
      display: flex;
      width: 100%;
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-evenly;

      .contentImput {
        width: 40%;

        .magnifying-glass {
          height: 20px !important;
          background-color: #b0b9c1;
          mask: url(../../../../../assets/images/search.svg) no-repeat center / contain;
          -webkit-mask: url(../../../../../assets/images/search.svg) no-repeat center / contain;

          &.focusOrange {
            background-color: $color-primary-variant;
          }
        }

        .dataList {
          width: 100%;

          option {
            width: 100%;
          }
        }

        .description {
          position: absolute;
          width: 26%;
          max-height: 340px;
          background-color: $color-light;
          box-shadow: 0 6px 10px #00000029;
          border-radius: 0 0 10px 10px;

          .scroll {
            overflow-y: scroll;
            max-height: 273px;

            .address-select {
              display: flex;
              border-bottom: 1px solid #D6DBDF;
              height: 90px;

              &:hover .address-select-hover {
                background-color: #009AF0;
              }

              &.isActive .address-select-hover {
                background-color: #009AF0;
              }

              .address-select-hover {
                width: 10px;
              }

              .default-address {
                font-size: 16px;
                font-family: "Heebo-Regular", serif;
                color: $color-empty-state-text;
              }

              .address-subtitle {
                font-size: 14px;
                font-family: "Heebo-Regular", serif;
                color: $color-empty-state-text;
              }

              .interValidateDisabled {
                  width: 102px;
                  height: 21px;
                  margin: 2% 0;
                  content: url("../../../../../assets/images/tagInterDisabled.png");
                }

              .interValidateEnabled {
                width: 102px;
                height: 21px;
                margin: 2% 0;
                content: url("../../../../../assets/images/tagInterEnabled.png");
              }

              .interValidateInProcess {
                width: 102px;
                height: 21px;
                margin: 2% 0;
                content: url("../../../../../assets/images/tagInterInProcess.png");
              }

              .adress-content {
                cursor: pointer;
                width: 100%;
                padding-left: 10%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
              }
            }
          }

          .create-address-button {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #009AF0;
            cursor: pointer;
            font-family: "Heebo-Medium", serif;

            &:hover {
              background-color: $color-background;
            }
          }
        }
      }
    }

    .containerAddresses {

      div {
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        .interValidateDisabled {
            width: 102px;
            height: 21px;
            margin: 8px 10px;
            content: url("../../../../../assets/images/tagInterDisabled.png");
          }

          .interValidateEnabled {
            width: 102px;
            height: 21px;
            margin: 8px 10px;
            content: url("../../../../../assets/images/tagInterEnabled.png");
          }

          .interValidateInProcess {
            width: 102px;
            height: 21px;
            margin: 8px 10px;
            content: url("../../../../../assets/images/tagInterInProcess.png");
          }

        .title {
          font-family: "Heebo-Regular", serif;
          font-size: 14px;
          margin-bottom: 5px;
          color: $color-dark;
        }

        .subtitle {
          margin-left: 10px;
          font-family: "Heebo-Regular", serif;
          font-size: 14px;
          color: $color-anotation;
        }
      }
    }

    .containerButtonsStepsQuoteShippingExpress {
      width: 50%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      button {
        margin: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  section {
    .contentStepQuoteShippingExpress {
      .content.stepOneExpress {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;

        .contentImput {
          width: 60%;
          padding-top: 5%;

          .description {
            width: 55%;
          }
        }
      }
    }
  }
}
