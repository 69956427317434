@import "../../../../themes/fonts";

#container-info-receiver {
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%;
  position: relative;

  .content{
    max-width: 742px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 75px;
    row-gap: 0;
    justify-content: space-between;

    .last-item {
      grid-column: 2 / span 1;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin: 7% auto 20px auto;
    max-width: 240px;
  }


  .mobile-mode {
    width: 100%;
    min-height: 100%;

    .title {
      padding: 0 20px;
    }

    .content{
      width: 85%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .last-item, .items {
        width: 100%;
      }
    }

    .wrapper-button {
      padding-bottom: 20px;
    }
  }
}
