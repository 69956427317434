@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

#login {
  border-radius: 12px;
  background-color: $color-light;
  width: 400px;
  max-height: calc(100% - 40px);
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .second-container__login {
    width: 100%;
    height: fit-content;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-login {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      justify-content: center;
      position: relative;
  
      .title-login {
        margin: 20px 0 0;
        font-weight: 600;
        font-size: 18px;
      }
  
      .close-login {
        position: absolute;
        right: 27.5px;
        top: 27.5px;
        width: 15px;
        height: 15px;
        mask: url(../../assets/images/close.svg) no-repeat center / contain;
        -webkit-mask: url(../../assets/images/close.svg) no-repeat center / contain;
        background-color: $color-primary-variant;
        cursor: pointer;
      }
    }
  
    .form__login {
      max-height: 70vh;
      overflow-y: auto;
      width: 100%;
      max-width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      &::-webkit-scrollbar-track{ margin: 8px 0 }
  
      .socialLogin{ margin: 20px 0 0 }
    
      .separator{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    
        hr{
          margin-top: 4px;
          width: 50%;
          border: 0;
          border-top: 1px solid $color-general-disabled;
        }
    
        span{
          font-family: 'Ubuntu-MediumItalic', sans-serif;
          font-weight: 600;
          font-size: 18px;
          margin: 12px 12px;
        }
      }

      .container__input-all,
      .container-checkbox-and-label{
        width: 90%;
        max-width: 280px;
      }
  
      button {
        margin: 20px 0;
        width: 85%;
        max-width: 240px;

        &.button-secondary{
          height: 32px;
          max-height: 32px;
          min-height: 32px;
        }&.button-primary{
          height: 40px;
          max-height: 40px;
          min-height: 40px;
        }
      }
  
      a {
        font-family: "Heebo-Regular";
        font-size: 15px;
        color: $color-accent;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  #login { max-width: 400px }
}

@media only screen and (max-width: 1023px) {
  #login { max-width: 90% }
}