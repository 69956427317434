@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.main-container {
  position: relative;
  width: auto;
  height: 48px;
  display: flex;
  padding: 0 12px;
  min-width: 110px;
  max-width: 250px;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  border-radius: 25px;
  justify-content: space-evenly;
  background-color: $color-light;

  &.default-cursor {
    cursor: default;
  }

  .icon-default {
    width: 24px;
    margin-right: 5px;

    &:hover {
      cursor: default;
      border-radius: none;
      background-color: none;
    }
  }

  .blue {
    width: 25px;
    margin: -5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    position: relative;

    img {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;
    }
  }

  .orange {
    width: 32px;
    margin-right: 5px;
  }

  &:hover {
    .blue {
      border-radius: 50%;
      background-color: $color-blue-hover;

      img {
        max-width: 80%;
        max-height: 80%;
      }
    }

    .orange {
      border-radius: 50%;
      background-color: $color-secondary-hover-button;
    }
  }

  .default {
    margin: 0 5px 0 8px;
    font-size: 18px;
    color: $color-gray;
    font-family: "Heebo-Regular", sans-serif;
  }

  .cash {
    width: max-content;
    margin: 0 6px;
    font-size: 18px;
    color: $color-success-payments;
    font-family: "Heebo-Medium", sans-serif;
  }

  .text-right {
    font-size: 18px;
    color: $color-gray;
    font-family: "Heebo-Regular", sans-serif;
  }

  .tooltip {
      visibility: hidden;
      position: absolute;
      margin-top: 100px;
      z-index: 1;
      left: 50%;
      text-align: center;
      transform: translateX(-50%);
      max-width: calc(213px * 1.2);
      width: max-content;
      padding: 0 10px;
      height: auto;
      min-height: 29px;
      font-size: 14px;
      line-height: 1.0;
      background: white;
      border: 1px solid transparent;
      border-radius: 5px;
      box-shadow: 0px 3px 46px 2px $color-box-shadow-select;
      font-family: "Heebo-Regular", sans-serif;
      color: $color-anotation;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        top: -35%;
        left: 47%;
        margin-left: -1px;
        line-height: 10px;
        border-color: #fff transparent transparent;
        border-style: solid;
        border-width: 11px 5px 0px 7px;
        transform: rotate(180deg);
      }
    }

    &:hover {
      .tooltip {
        visibility: visible;
      }
    }
}
