@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

#container-recharge-balance {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 80px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  transform: translateX(-10px);
  // box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);

  .close {
    position: absolute;
    top: 2%;
    left: 92%;
    width: 15px;
    height: 15px;
    z-index: 1;
    background-color: $color-primary-variant;
    mask: url(../../../assets/images/close.svg) no-repeat center / contain;
    -webkit-mask: url(../../../assets/images/close.svg) no-repeat center / contain;
    cursor: pointer;
  }

  #header__container-recharge-balance {
    width: 90%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 2px solid $color-primary;

    &.full-width {
      width: 100%;
    }

    .left,
    .rigth {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &.more-space {
        width: 100%;
      }
    }
    .left {
      justify-content: flex-start;
    }

    .center {
      display: flex;
      align-items: center;

      .left-space {
        padding-left: 30px;
      }
    }

  }

  #body__container-recharge-balance {
    width: 100%;
    overflow: auto;
    height: calc(100% - 50px);

    .second-body__container-recharge-balance {
      width: 100%;
      min-height: 100%;
      height: 100%;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      form {
        height: 50%;
      }

      .form {
        height: 80%;
        width: 60%;
        min-width: 280px;
      }

      .container-input-and-checkbox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 125px;

        .center {
          justify-content: flex-start;
        }
      }

      .new-color {
        color: $color-dark;
      }

      .container-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 80%;
        max-width: 400px;

        .text {
          font-family: "Heebo-Regular", sans-serif;
          font-size: 16px;
          color: $color-text-value;
          margin: 20px 0;

          b {
            font-family: "Heebo-Medium", sans-serif;
          }
        }
      }

      .container-buttons {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 25%;

        button,
        a {
          margin: 10px;
        }
      }

      .center {
        text-align: center;

        .payment-method-title {
          font-family: "Heebo-Medium", sans-serif;
          font-size: 16px;
          margin: 35px 0;
          margin-bottom: 8px;
        }
      }

      .payment-methods {
        padding: 0px 0;

        .each {
          width: 84%;
          padding: 8px 24px;
          margin: 16px 0;
          display: flex;
          align-items: center;
          border: 1px solid #d6dbdf;
          border-radius: 5px;
          cursor: pointer;

          .img {
            width: 24px;
            height: 24px;
            background-color: #009af0;
            mask: url(../../../assets/images/payment_black.svg) no-repeat center / contain;
            -webkit-mask: url(../../../assets/images/payment_black.svg) no-repeat center / contain;
          }

          .left-column {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .method {
              font-size: 14px;
              padding-left: 16px;
            }

            .arrow {
              width: 24px;
              height: 24px;
              background-color: #f08700;
              transform: rotate(180deg);
              mask: url(../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
              -webkit-mask: url(../../../assets/images/icon-arrow-back-color-primary.svg) no-repeat center / contain;
            }
          }
        }
        .recharge-button {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .recharge-button-tutorial {
            width: 75%;
            margin: 18px 0;
          }

          .link-text {
            display: flex;
            font-family: "Heebo-Regular", sans-serif;
            font-weight: 100;
            font-size: 14px;
            color: #353A3D;

            a {
              font-size: 14px;
              color: #009AF0;
              width: fit-content;
              margin-left: 4px;
              font-family: "Heebo-Regular", sans-serif;
            }
          }
        }
      }
    }
  }

  &.desktop-mode {
    #body__container-recharge-balance {
      .second-body__container-recharge-balance {
        .container-buttons {
          flex-direction: row-reverse;

          button,
          a,
          input {
            width: 100%;
            max-width: 300px;
          }
        }
      }
    }
  }

  &.mobile-mode {
    #header__container-recharge-balance {
      padding: 0 10px;

      .center {
        h1 {
          font-size: 18px;
        }
        .left-space {
          padding-left: 30px;
        }
      }

      .rigth {
        width: 0px;
      }

      .left {
        width: 36px;
      }
    }
    #body__container-recharge-balance {
      .second-body__container-recharge-balance {
        width: 88%;
        margin: 0 auto;

        .form {
          width: 100%;
          min-width: 100%;
        }

        .payment-methods {
          .each {
            padding: 8px 18px;
          }

          .link-text {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .container-buttons {
          flex-direction: column;

          button,
          a {
            width: 90%;
            max-width: 300px;
          }
        }
      }
    }
  }
}

#container-recharge-balance-elp {
  border-radius: 11px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.5);
}

* {
  transition: all 200ms ease;
}

.Button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-family: "Ubuntu-MediumItalic", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;

  @keyframes girar {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  &.button-primary {
    color: $color-light;
    height: 50px;
    min-height: 50px;
    border-radius: 25px;
    background: rgba(0, 154, 240, 1);
    background: -moz-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(0, 154, 240, 1)),
      color-stop(100%, rgba(0, 105, 163, 1))
    );
    background: -webkit-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
    background: -o-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
    background: -ms-linear-gradient(left, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
    background: linear-gradient(to right, rgba(0, 154, 240, 1) 0%, rgba(0, 105, 163, 1) 100%);
    box-shadow: 0px 10px 14px -8px rgba(0, 0, 0, 0.25);
    border: none;

    &:active {
      color: $color-dark-light;
      transform: scale(0.9);
    }
  }

  &:disabled {
    background: $color-light !important;
    border: 2px solid $color-dark-light;
    color: $color-dark-light;
    box-shadow: none;
    cursor: initial;
  }

  &.hidden {
    width: 0 !important;
    height: 0 !important;
    font-size: 0;
  }

  &.noBold {
    font-weight: normal;
  }

  &.sizeReports {
    width: 116%;

    &:disabled {
      height: 32px;
    }
  }

  &.spaceBetween {
    margin: 40px 0;
  }
}

.recharge-balance-modal {
  width: 50%;
  max-width: 563px !important;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &.more-height {
    height: 150px;
  }

  .processing-title {
    text-align: center;
    font-family: "Heebo-BoldItalic", sans-serif;
  }

  .error-text {
    text-align: center;
    color: $color-error;
    font-size: 14px;
    font-family: "Heebo-Bold", sans-serif;
    margin: 16px 0;
  }

  .close_modal_button {
    max-width: 150px;
    margin: 0 auto;
  }

  &.mobile-mode {
    width: 90%;
  }
}

@media only screen and (max-width: 1480px) {
  .link-text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  .recharge-button-tutorial {
    text-align: center;
  }
 }
