@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

.containterEntryDataQuoteShipping {
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-height: 650px;
  margin: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;

  .arrowBack {
    visibility: hidden;
    width: 38px;
    height: 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;

    &.visible {
      visibility: visible;
      height: 38px;
      margin: 8px;
      position: relative;
      width: 38px;
    }

    &:hover {
      background-color: rgba(255, 156, 25, .3)
    }
  }

  &>header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;

    .adjust-shipping {
      width: 20%;
      min-width: 85px;
      line-height: 65px;
      border-bottom: 3px solid #F0870033;

      .edit-hover {
        height: 42px;
        display: flex;
        width: 60%;
        border-radius: 25px;
        margin-bottom: 10px;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .shipping-title {
          color: $color-header-gray;
        }

        &:hover {
          background-color: #F0870033;
        }
      }

      &.isActive {
        border-bottom: 3px solid $color-primary-variant;

        .shipping-title {
          color: $color-dark;
        }
      }

    }

    .express {
      width: 30%;
      min-width: 221px;
      line-height: 65px;
      border-bottom: 3px solid #F0870033;

      .edit-hover {
        height: 42px;
        display: flex;
        width: 80%;
        border-radius: 25px;
        margin-bottom: 10px;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .express-title {
          color: $color-header-gray;
        }

        &:hover {
          background-color: #F0870033;
        }
      }

      &.isActiveExpress {
        border-bottom: 3px solid $color-primary-variant;

        .express-title {
          color: $color-dark;
        }
      }
    }

    .massive {
      width: 30%;
      min-width: 191px;
      line-height: 65px;
      border-bottom: 3px solid #F0870033;

      .edit-hover {
        height: 42px;
        display: flex;
        width: 90%;
        border-radius: 25px;
        margin-bottom: 10px;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .massive-title {
          color: $color-header-gray;
        }

        &:hover {
          background-color: #F0870033;
        }
      }

      &.isActiveMassive {
        border-bottom: 3px solid $color-primary-variant;

        .edit-hover {
          .massive-title {
            color: $color-dark;
          }
        }
      }
    }

    .empty {
      width: 100%;
      border-bottom: 3px solid #F0870033;
    }

    .adjust-empty {
      width: 100%;
      border-bottom: 3px solid #F0870033;
    }

    p {
      width: 40px;
      opacity: 0;
      white-space: nowrap;
      overflow: hidden;

      &.visible {
        opacity: 1;
        padding: 0;
        width: max-content;
      }
    }
  }

  &>body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .containerTitleAndProgressBar {
      width: 100%;
      height: 100px;
      padding: 5px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      &>section {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        box-sizing: border-box;

        div {
          width: 40px
        }
      }

      .container-step-progress {
        height: 40px
      }
    }

    .contentQuoteShippingSteps {
      width: 100%;
      height: calc(100% - 90px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        margin: 8px 0
      }

      &.fullHeight {
        height: 100%
      }
    }
  }
}

@media only screen and (min-width: 1220px) {
  .main-container-modal {
      width: 730px;
      height: 548px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .iconClose {
        width: 13.18px;
        height: 13.18px;
        position: absolute;
        mask: url(../../../assets/images/close.svg) no-repeat center / contain;
        -webkit-mask: url(../../../assets/images/close.svg) no-repeat center / contain;
        cursor: pointer;
        background-color: $color-primary-variant;
        margin-left: 660px;
        margin-bottom: 470px;
      }

      .image-container {
        width: 642px;
        height: 429px;
        margin-top: 30px;
        content: url(../../../assets/images/modal_Interrapidísimo.png);
      }
    }
}

@media only screen and (min-width: 1024px) {

  .containterEntryDataQuoteShipping body {
    height: calc(100% - 35px);
    background: transparent;
    overflow: overlay;

    .container-step-progress {
      max-width: 190px
    }

    .contentQuoteShippingSteps .containerButtonsStepsQuoteShipping {
      flex-direction: row;
      padding-top: 0;

      button {
        max-width: 300px;

        &.widthless {
          height: 38px;
          min-height: 38px
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .containterEntryDataQuoteShipping header {
    display: none;
  }

  .containterEntryDataQuoteShipping body {
    height: 100%;

    .container-step-progress {
      width: 80%
    }

    .contentQuoteShippingSteps .containerButtonsStepsQuoteShipping {
      flex-direction: column;
      padding: 18px;

      button {
        width: 90%;
        max-width: 240px
      }
    }
  }
  .container-message-irp-mobile {
    display: flex;
    align-items: center;
    padding: 19px 10px;
    margin-left: 1em;
    width: -webkit-fill-available;

    div {
      margin-left: 5px;
    }

    p {
      font-size: 14px;
    }
  }
}

/* -------------------------------------------------------------------------------------- */
#containter-quote-shipping__entry-data {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 650px;
  max-width: 650px;
  padding: 0 20px;

  #header__containter-quote-shipping__entry-data {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 2px solid $color-primary;

    .header-flex {
      display: flex;

      .left {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        img {
          height: 15px;
          cursor: pointer;
        }
      }

      .center {
        display: flex;
        align-items: center;

        img {
          height: 20px;
          margin-right: 5px;
        }
      }
    }

    .rigth-alone {
      .required_text {
        color: $color-header-gray;
      }
    }

    .left,
    .rigth {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        height: 15px;
        cursor: pointer;
      }
    }

    .center {
      display: flex;
      align-items: center;

      img {
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  #body__containter-quote-shipping__entry-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    justify-content: flex-start;

    .container-header__body__containter-quote-shipping__entry-data {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 70px;
      margin: 12px 0;

      #header__containter-quote-shipping__entry-data {
        border: none;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;

        .left,
        .rigth {
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          img {
            height: 15px;
            cursor: pointer;
          }
        }

        .center {
          display: flex;
          align-items: center;

          img {
            height: 20px;
            margin-right: 5px;
          }
        }
      }

      .container-step-progress {
        height: 50px;
      }
    }

    #container-quote-shipping-steps {
      width: 100%;
      height: calc(100% - 110px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.collection-service {
        height: calc(100% - 70px);
      }
    }

    &.mobile-mode {
      height: 100%;

      .container-step-progress {
        width: 80% !important;
        margin: 0;
      }

      #container-quote-shipping-steps {
        &.collection-service {
          height: calc(100% - 0px);
        }
      }

      .center {
        display: flex;
        align-items: center;

        h2 {
          font-size: 16px;
        }
      }
    }

    &.desktop-mode {
      height: calc(100% - 50px);

      .container-header__body__containter-quote-shipping__entry-data {
        width: 60%;
      }

      .container-step-progress {
        margin-bottom: 0px;
        max-width: 190px;
      }
    }
  }
}

#container-deliveries-list-quote-shipping {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 20px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  #header__container-deliveries-list-quote-shipping {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid $color-primary;
    background: white;

    .left,
    .rigth {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &.more-width {
        width: 210px;
      }

      &.stric-width {
        width: 217px;
      }

      img {
        height: 15px;
        cursor: pointer;
      }

      .order-deliveries-list {
        img {
          &:first-child {
            display: none;
          }
        }
      }

      .z90 {
        z-index: 90;

        .container-options__select-general {
          .option-select-general {
            white-space: nowrap;
            text-align: center;

            .container-value-selected__select-general {
              img:first-child {
                display: none;
              }
            }
          }

          &.visible {
            .option-select-general {
              border-top: 1px solid $color-primary;
            }
          }
        }
      }
    }

    .center {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 64%;
      justify-content: flex-start;

      .italic {
        font-size: 18px;
      }

      img {
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  #body__container-deliveries-list-quote-shipping {
    width: 100%;

    .container-header__body__container-deliveries-list-quote-shipping {
      border: none;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      background: white;

      .left,
      .rigth {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        img {
          height: 15px;
          cursor: pointer;
        }

        .z90 {
          z-index: 90;

          .container-options__select-general {
            .option-select-general {
              white-space: nowrap;
              text-align: center;
            }

            &.visible {
              .option-select-general {
                border-top: 1px solid $color-primary;
              }
            }
          }
        }
      }

      .center {
        display: flex;
        align-items: center;
        text-align: center;
        max-width: 60%;
      }
    }

    .container-body__body__container-deliveries-list-quote-shipping {
      height: 100%;

      .deliveries-list-step-three-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow-y: hidden;
      }
    }
  }

  &.mobile-mode {
    #body__container-deliveries-list-quote-shipping {
      height: 100%;

      .container-header__body__container-deliveries-list-quote-shipping {
        .rigth {
          .containter-select-all {
            .container-options__select-general {
              border: none;

              img {
                height: 24px;
              }

              &.visible {
                border: 2px solid #FF9C19;
              }

              .container-value-selected__select-general {
                img {
                  &:last-child {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .center {
          max-width: 100%;

          h2 {
            font-size: 18px;
            font-family: 'Ubuntu-MediumItalic', sans-serif;
          }
        }
      }

      .container-body__body__container-deliveries-list-quote-shipping {
        height: calc(100% - 60px);
      }
    }
  }

  &.desktop-mode {
    #body__container-deliveries-list-quote-shipping {
      height: calc(100% - 60px);

      .container-body__body__container-deliveries-list-quote-shipping {
        height: 100%;
        background-color: $color-white;
      }
    }
  }

  &.without-background {
    box-shadow: 0 8px 12px -6px rgba(0, 0, 0, 0);
  }

  .container-message-irp {
    display: flex;
    align-items: center;
    padding: 19px 10px;
    margin-left: 3.5em;
    width: -webkit-fill-available;

    div {
      margin-left: 5px;
    }

    p {
      font-family: 'Heebo-Regular', sans-serif;
      font-size: 14px;
    }
  }
}

#container-last-details-quote-shipping {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  .progress-bar-shipping {
    width: 240px;
    height: 70px;
    display: flex;
    justify-content: center;
  }

  #body__container-last-details-quote-shipping {
    width: 100%;
    overflow: hidden;
  }

  &.desktop-mode {
    #body__container-last-details-quote-shipping {
      height: calc(100% - 50px);
    }
  }

  &.mobile-mode {
    #header__container-last-details-quote-shipping {
      margin-top: 24px;
      border-bottom: none;

      .center {
        justify-content: center;

        &.more-width {
          width: 100%;
        }

        .required_text {
          color: $color-header-gray;
        }
      }
    }

    #body__container-last-details-quote-shipping {
      height: 100%;
    }
  }
}

#container-payment-shipping-quote-shipping {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  #header__container-payment-shipping-quote-shipping {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 2px solid $color-primary;

    .left,
    .rigth {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        height: 24px;
        cursor: pointer;
      }
    }

    .center {
      display: flex;
      align-items: center;

      img {
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  #body__container-payment-shipping-quote-shipping {
    width: 100%;
    overflow: hidden;
    height: calc(100% - 50px);
  }
}

#container-shipping-generated-quote-shipping {
  border-radius: 12px;
  background: $color-light;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  #header__container-shipping-generated-quote-shipping {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 2px solid $color-primary;

    .left,
    .rigth {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .center {
      display: flex;
      align-items: center;
    }
  }

  #body__container-shipping-generated-quote-shipping {
    width: 100%;
    overflow: auto;
    height: calc(100% - 50px);

    .second-body__container-shipping-generated-quote-shipping {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .container-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 80%;
        max-width: 400px;

        .text {
          font-family: "Heebo-Regular", sans-serif;
          font-size: 16px;
          color: $color-text-value;
          margin: 20px 0;

          b {
            font-family: "Heebo-Medium", sans-serif;
          }
        }
      }

      .container-buttons {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        button {
          margin: 10px;
        }

        &.desktop-mode {
          flex-direction: row-reverse;

          button {
            width: 45%;
            max-width: 300px;
          }
        }

        &.mobile-mode {
          flex-direction: column;

          button {
            width: 90%;
            max-width: 300px;
          }
        }
      }
    }
  }

  &.desktop-mode {
    #body__container-shipping-generated-quote-shipping {
      .second-body__container-shipping-generated-quote-shipping {
        .container-buttons {
          flex-direction: row-reverse;

          button {
            width: 45%;
            max-width: 300px;
          }
        }
      }
    }
  }

  &.mobile-mode {
    #body__container-shipping-generated-quote-shipping {
      .second-body__container-shipping-generated-quote-shipping {
        .container-buttons {
          flex-direction: column;

          button {
            width: 90%;
            max-width: 300px;
          }
        }
      }
    }
  }
}

.quote-shipping__modal-login {
  background-color: transparent !important;
}

.quote-shipping__modal-bank-data {
  max-width: 90% !important;
  width: 100%;
}

.modal__sending-save {
  width: 350px;
  padding: 20px 0;
  box-sizing: border-box;
  max-width: 95%;

  .body__sending-save {
    width: 90%;
    text-align: center;
    margin: 20px 5%;

    span {
      font-family: "Heebo-Regular", sans-serif;
      font-size: 16px;
      color: $color-text-value;

      b {
        font-family: "Heebo-Medium", sans-serif;
      }
    }
  }

  .footer__sending-save {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin: 10px 0;
      max-width: 250px;
    }
  }
}

.modal__no-data-bank {
  width: 350px;
  max-width: 95%;
  padding: 20px;
  box-sizing: border-box;

  .header__no-data-bank {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left,
    .right {
      height: 30px;
      width: 30px;

      img {
        height: 16px;
        cursor: pointer;
        padding: 10px;

        &:hover {
          border-radius: 50%;
          background-color: $color-background-hover-menu;
        }
      }
    }

    .center {
      width: 60%;
      text-align: center;

      .title__header__no-data-bank {
        font-size: 18px;
      }
    }
  }

  .body__no-data-bank {
    width: 80%;
    text-align: center;
    margin: 20px 10%;
  }

  .footer__no-data-bank {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin: 10px 0;
      max-width: 200px;
    }
  }
}

.modal__error-to-generate-shipping {
  width: 350px;
  max-width: 95%;
  padding: 20px;
  box-sizing: border-box;

  .header__error-to-generate-shipping {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left,
    .right {
      height: 20px;
      width: 20px;

      img {
        height: 100%;
        cursor: pointer;
      }
    }

    .center {
      width: 60%;
      text-align: center;

      .title__header__error-to-generate-shipping {
        font-size: 18px;
      }
    }
  }

  .body__error-to-generate-shipping {
    width: 80%;
    text-align: center;
    margin: 20px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer__error-to-generate-shipping {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin: 10px 0;
      max-width: 200px;
    }
  }
}
