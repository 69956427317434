@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.content-edit-info {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .content-with-button {
    height: 100%;
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .main-title {
      font-size: 14px;
      margin-top: 5%;
      text-align: center;
      color: $color-empty-state-text;
      font-family: "Heebo-Regular", sans-serif;

      span {
        font-size: 13px;
        color: $color-empty-state-text;
        font-family: "Heebo-Medium", sans-serif;
      }
    }
    .input-content {
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .content-edit-left {
        padding-right: 12%;

        .inputContainer {
          height: auto;
          width: 270px;
          margin-bottom: 30px;
        }

        .container__input-all .container__input-general {
          width: 100%;
        }

        .line-focused {
          width: 100%;
        }

        .nit-container {
          width: auto;
          max-width: 280px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          .inputContainer-nit {
            height: auto;
            width: 120px;
            margin-bottom: 20px;
          }

          .line {
            padding-top: 42px;
          }

          .inputContainer-number {
            height: auto;
            width: auto;
            min-width: 90px;
            margin-bottom: 20px;

            .container__input-all .container__input-general {
              width: 40px;
            }

            .line-focused {
              width: 30px;
            }
          }
        }

        .containter-select-all .container-value-selected__select-general {
          width: 270px;
        }

        .selectContainer {
          height: 58px;
          width: 270px;
          padding: 20px 0;

          .textType {
            font-size: 14px;
            font-family: "Heebo-Regular", sans-serif;
          }
        }
      }

      .content-edit-right {
        padding-left: 12%;
        border-left: 1px solid $color-gray-disabled;

        .inputContainer {
          height: auto;
          width: 270px;
          margin-bottom: 30px;
        }

        .containter-select-all .container-value-selected__select-general {
          width: 270px;
        }

        .selectContainer {
          height: 58px;
          width: 270px;
          padding: 20px 0;

          .textType {
            font-size: 14px;
            font-family: "Heebo-Regular", sans-serif;
          }
        }
      }
    }
  }

  .content-button {
    width: 267px;
    height: 12%;
    margin-bottom: 25px;
  }

  &.billing-edit-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content-with-button {
      overflow-x: hidden;
      .main-title {
        margin: 2% 0;
        max-width: 274px;
        width: auto;
      }
      .input-content {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content-edit-left {
          max-width: 350px;
          width: auto;
          padding: 0;
        }
        .content-edit-right {
          max-width: 350px;
          width: auto;
          padding: 0;
          border: none;
        }
      }
    }
  }
}
