.error-ubication-modal {
  width: 548px;

  .modal-children-body {
    .text {
      width: 372px;
      padding: 0 50px;
      text-align: center;
      margin-bottom: 24px;
      margin-top: 6px;

      .span {
        font-family: "Heebo-Medium", serif;
        font-weight: bold;
      }
    }
  }
}
