@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

.information-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  max-width: 320px;
  margin: 32px 0;

  .subtitle {
    font-family: "Heebo-Medium", sans-serif;
    margin-bottom: 10px;

    &.company_title {
      text-transform: capitalize;
    }
  }

  .sender-data__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .user-avatar {
      width: max-content;
    }

    .text__sender-data {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
    }
  }

  .space-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container-field-and-value {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .field {
      font-family: "Heebo-Medium", serif;
      font-weight: unset;
      margin-right: 5px;
    }

    .dark {
      color: $color-dark-gray;
    }
  }

  .margin-top-and-bottom {
    margin: 10px 0;
  }

  .collection-service-message {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    color: $color-primary-variant;
  }

  .delivery-company-data__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .companyName {
      margin-top: 3px;
    }

    .data {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 8px;

      img {
        height: 48px;
        width: 48px;
        border-radius: 8px;
        margin-right: 10px;
      }

      .routeType-content {
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        .routeType {
          width: auto;
          height: 22px;
          display: flex;
          font-size: 12px;
          padding: 0 6px;
          color: $color-dark;
          border-radius: 25px;
          align-items: center;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: rgba(162, 170, 172, 0.3);
        }
        .routeType-express {
          width: auto;
          height: 22px;
          display: flex;
          font-size: 12px;
          padding: 0 6px;
          color: $color-dark;
          border-radius: 25px;
          align-items: center;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: $color-primary;
        }
      }
    }
  }

  .with-margin {
    margin: 5px 0;

    &.first {
      margin-top: 10px;
    }

    &.second {
      margin-bottom: 10px;
    }
  }

  .container-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    button {
      margin: 10px 0;
      max-width: 300px;

      &.secondary {
        max-width: 200px;

        .button-inner-text {
          display: block;
          margin-right: 12px;
        }
      }
    }

    .button-and-tooltip {
      display: flex;
      align-items: center;

      .tooltip {
        margin-left: 16px;
        height: 19px;

        .tooltip__content-overlay {
          bottom: 24px;
          right: 10px;

          &::after {
            top: 88%;
            right: 0;
            visibility: visible;
          }
        }
      }

      .delivery-confirmation-normal-text {
        font-family: "Heebo-Regular", sans-serif;
        color: $color-header-gray;
      }

      .delivery-confirmation-word-tooltip {
        font-size: 14px;
        font-family: "Heebo-Medium", sans-serif;
        color: $color-header-gray;
      }
    }
  }
}
