@import "../../themes/colors.scss";
@import "../../themes/fonts.scss";

#bank-data {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: $color-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  box-sizing: border-box;

  .header__bank-data {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0px;
    box-sizing: border-box;

    .left {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;


      .header-text {
        font-weight: normal;
      }

      img {
        height: 16px;
        cursor: pointer;
        margin-right: 18px;
        padding: 10px;

        &:hover {
          background-color: $color-background-hover-menu;
          border-radius: 50%;
        }
      }
    }

    .rigth {
      position: absolute;
      right: 0;

      img {
        height: 16px;
        padding: 10px;
        cursor: pointer;

        &:hover {
          border-radius: 50%;
          background-color: $color-background-hover-menu;
        }
      }
    }

    .center {
      display: flex;
      align-items: center;
    }
  }

  .body__bank-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .second-body__bank-data {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
    }


    .mgTop {
      margin-top: 20px;
      flex-direction: row !important;
    }

    &.mobile-mode {
      .mgTop {
        flex-direction: column !important;
      }
    }

    .container__body__bank-data {
      width: 94%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .container-top-bottom-or-side__bank-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
    }

    &.mobile-mode {
      .container__body__bank-data {
        flex-direction: column;
        align-items: center;

        .container-top-bottom-or-side__bank-data {
          width: 90%;
          align-items: flex-start;

          &:last-child {
            padding-top: 24px;
          }

          .container__input-all {
            margin: 15px 0;
          }
        }
      }
    }

    &.desktop-mode {
      .container__body__bank-data {
        justify-content: space-evenly;
        flex-direction: row;
        height: 100%;

        .container-top-bottom-or-side__bank-data {
          width: 38%;
          height: 100%;
          // margin: 15px 0;

          .container__input-all {
            padding: 0 25px;
          }
        }

        .line-container {
          height: 80%;

          hr {
            width: 0;
            height: 100%;
            border-right: 0;
            border-left: 2px solid $color-line;
          }
        }
      }

      &.completeScreen {
        .second-body__bank-data {
          width: calc(100% - 250px);
          max-width: 980px;
          height: 100%;
        }
      }
    }

    .container-buttons__bank-data {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      &.mobile-mode {
        width: 85%;
        flex-direction: row;
      }

      &.desktop-mode {
        width: 100%;
        flex-direction: row-reverse;

        button {
          max-width: 250px;
        }
      }
    }
  }

  .radio-button {
    margin: 4px 0;
  }

  &.mobile-mode {
    width: 99%;
    height: 100%;
    max-height: 90vh;
    padding: 24px 0;
    margin-bottom: 15px;

    .select-identification-type {
      min-height: auto;
    }

    .header__bank-data {
      height: 50px;
      padding: 0 16px;

      .left,
      .rigth {
        width: fit-content;

        img {
          height: 20px;
        }
      }
    }

    .body__bank-data {
      height: calc(100% - 50px);

      .container-buttons__bank-data {
        margin-top: 30px;
        min-height: 70px;

        .container__body__bank-data {
          .container-top-bottom-or-side__bank-data {
            margin: 0 auto;
            margin-top: 30px;

            &:last-child {
              width: 78%;
            }
          }
        }
      }
    }
  }

  &.desktop-mode {
    width: 900px;
    max-height: 90%;
    padding: 20px 86px;
    box-sizing: border-box;
    margin: 5px auto;
    margin-bottom: 0px;

    &.completeScreen {
      width: calc(100% - 40px);
      padding: 24px 32px;
    }


    .header__bank-data {
      height: 50px;
      border-bottom: 1px solid $color-primary-variant;
    }

    .body__bank-data {
      height: calc(100% - 50px);

      .container-buttons__bank-data {
        min-height: 70px;
      }
    }
  }

  .container-select-bank-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;

    .grayColor {
      color: $color-text-value;
      font-size: 14px;
      font-family: "Heebo-Regular", sans-serif;
    }
  }

  .select-identification-type {
    width: 100%;
    min-height: 78px;

    .container-value-selected__select-general {
      width: 100%;
    }

    .container-options__select-general {
      width: 90%;
      border: 2px solid $color-dark-light;
    }

    &.focus {
      .container-options__select-general {
        border: 2px solid $color-primary;
      }
    }
  }

  .container-radio-buttons__bank-data {
    width: 100%;
    padding-top: 10px;
    min-height: 110px;

    .grayColor {
      color: $color-text-label-input;
      font-size: 14px;
    }

    .primeryColor {
      &.focus {
        color: $color-primary-variant;
      }
    }
  }
}

.confirmation__bank-data {
  padding: 20px;
  box-sizing: border-box;
  width: 530px;

  .center-text {
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    font-size: 18px;
  }

  .modal__text-confirmation {
    display: block;
    text-align: center;
    margin: 10px auto;
    width: 300px !important;
    font-family: "Heebo-Regular";
    font-size: 15px;
    color: $colot-darker-gray;

    .bold {
      padding-left: 4px;
      font-family: 'Heebo-Medium';
    }
  }

  .modal-confirmation__bank-data {
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      max-width: 180px;
      margin: 10px 0;
      padding: 0 20px;
    }
  }

  &.mobile-mode {
    .modal__text-confirmation {
      width: 100% !important;
      padding: 20px 0;
      box-sizing: border-box;
      max-width: 400px;
    }

    .modal-confirmation__bank-data {
      flex-direction: column-reverse;
    }
  }
}
