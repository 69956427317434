@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.settings-payment-notifications {
  display: flex;
  padding: 0 2rem;
  flex-direction: column;

  &.isInactive {
    height: 40px;
  }

  .toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .container-tooltip {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 2rem;
    }

    .active-notifications {
      font-family: 'Heebo-Medium', serif;
      font-size: 14px;
      white-space: nowrap;
    }

    .show-shipping-info-detail {
      margin: 10px 0;
      display: flex;
      justify-content: center;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          font-family: "Heebo-Medium", serif;
          font-size: 16px;
          color: $color-accent;
        }

        .image {
          mask: url(../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
          -webkit-mask: url(../../assets/images/selectIcons/arrow_drop_down-Orange.svg) no-repeat center / contain;
          width: 25px;
          height: 25px;
          background: $color-accent 0 0 no-repeat padding-box;

          &.displayed {
            transform: rotate(180deg);
          }
        }
      }
    }
  }


  .section-payment-notifications {
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    &.isInactive {
      height: 0;

      .container-state-switch {
        display: none;
        transition-duration: 0.5s;
      }
    }

    .title-section-notifications {
      text-align: center;
      margin: 1rem 0;
    }

  }

}
