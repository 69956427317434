@import '../../../../themes/fonts.scss';
@import '../../../../themes/colors.scss';
@import '../../../../themes/general.scss';

#multiple-shippings__payment-success{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;

  .details-prices-shippings{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-details-success{
      text-align: center;
      width: 100%;
      max-width: 350px;
      margin-bottom: 10px;
    }

    .container-field-value{
      margin: 6px 0;
      width: 100%;
      max-width: 350px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .value{
        margin-left: 10px;
      }
    }

    hr{
      width: 90%;
      max-width: 300px;
      margin: 10px 5%;
      border: none;
      border-top: 1px solid $color-primary-variant;
    }
  }

  .buttons{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button{
      margin: 10px 0;
      max-width: 250px;
    }
  }

  &.mobile-mode{
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .details-prices-shippings{
      width: 80%;
    }

    .buttons{
      width: 80%;
    }
  }
}
