@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.activ-filters{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content-activ-filters{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: calc(100% - 200px);
    border-radius: 8px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    p{
      min-width: fit-content;
      font-family: 'Heebo-Medium', serif;

      &.without-max-width{
        width: max-content;
      }
    }

    .item-activ-filter{
      display: flex;
      align-items: center;
      padding: 4px 8px 4px 8px;
      background-color: $color-accent-variant-light;
      border-radius: 50px;
      margin-left: 20px;

      span{
        width: max-content;
        font-family: 'Heebo-Medium', serif;
        color: $color-dark;
        font-size: 14px;
      }

      img{
        width: 20px;
        height: 20px;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    // .degraded{
    //   position: absolute;
    //   background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    //   width: 80px;
    //   height: 100%;
    //   right: 0;
    // }
  }

  .clear-filter{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 140px;
    height: 24px;
    font-family: "Ubuntu-MediumItalic", serif;
    font-size: 14px;
    cursor: pointer;
    border-radius: 12px;
    color: $color-red;
    border: 2px solid $color-red;
    background: $color-white;
    transition: all 200ms ease;
  }
}

@media only screen and (max-width: 1023px) {
  .activ-filters {

    .content-activ-filters {
      width: calc(100% - 154px);
    }

    .clear-filter {
      margin-right: 5px;
    }
  }
}
