@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.first-container {
  margin: 8px 0;

  &.edit {
    border-bottom: 1px solid $color-gray-disabled;
  }

  &.focus {
    border-bottom: 1px solid $color-primary-variant;
  }

  .editable-container {
    display: flex;
    justify-content: space-between;

    img {
      width: 19px;
      height: 14px;
    }

    .label {
      font-family: "Heebo-Medium", serif;
      color: #707B84;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .value {
      font-family: 'Heebo-Regular', serif;
      color: $color-header-gray;
      font-size: 14px;
      text-align: end;
    }
  }

  .editable-container-input {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px;

    img {
      width: 19px;
      height: 14px;
    }

    &__icon-label {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .label {
      font-family: 'Heebo-Medium', serif;
      color: $color-header-gray;
      font-size: 14px;
    }

    .value {
      input {
        border: none;
        outline: none;
        font-family: 'Heebo-Regular', serif;
        color: $color-header-gray;
        font-size: 14px;
      }
    }
  }

  .editable-container-input:last-child {
    border-bottom: none;
  }

  .annotation__input-custom {
    font-size: 12px;
    font-family: 'Heebo-Regular', serif;

    &.error {
      color: $color-error;
    }
  }
}

