@font-face {
  font-family: 'Ubuntu-BoldItalic';
  src: url('../assets/fonts/ubuntu/Ubuntu-BoldItalic.ttf');
}

@font-face {
  font-family: 'Ubuntu-Medium';
  src: url('../assets/fonts/ubuntu/Ubuntu-Medium.ttf');
}

@font-face {
  font-family: 'Ubuntu-MediumItalic';
  src: url('../assets/fonts/ubuntu/Ubuntu-MediumItalic.ttf');
}

@font-face {
  font-family: 'Ubuntu-Regular';
  src: url('../assets/fonts/ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: 'Heebo-BoldItalic';
  src: url('../assets/fonts/ubuntu/Ubuntu-BoldItalic.ttf');
}

@font-face {
  font-family: 'Heebo-Regular';
  src: url('../assets/fonts/heebo/Heebo-Regular.ttf');
}

@font-face {
  font-family: 'Heebo-Medium';
  src: url('../assets/fonts/heebo/Heebo-Medium.ttf');
}

@font-face {
  font-family: 'Heebo-Bold';
  src: url('../assets/fonts/heebo/Heebo-Bold.ttf');
}
