@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

.card-myAddress {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  height: 90%;
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .container-section-myAddress {
    display: flex;
    height: calc(85% - 50px);
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
  }

  .container-title-notificaion {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 60px;
    margin: 1.6rem 4rem;

    p {
      font-size: 1em;
      color: #535A5F;

      span {
        cursor: pointer;
        font-weight: bold;
        color: $color-accent;
        font-family: "Heebo-Regular", serif;
      }
    }
  }

  .container-title-notificaion2 {
    display: none;
  }

  .default-address2 {
    margin: 10px 0 10px 2rem;
    padding: 10px 2rem;
    padding-right: 0;
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    gap: 10px;
    row-gap: 0px;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: $color-background;
    }

    .adjust-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 22px;
    }

    .font-title2 {
      font-family: 'Heebo-Medium', serif;
      font-size: 16px;
      word-break: normal;
    }

    .edit-profile2 {
      color: $color-accent-variant-register;
      cursor: pointer;
      font-family: 'Ubuntu-MediumItalic', serif;
      margin-bottom: 3px;
    }

    .address-font2 {
      font-family: 'Heebo-Regular', serif;
      font-size: 16px;
      word-break: normal;
    }

    .interValidateEnabled {
      width: 102px;
      height: 21px;
      margin-right: 20px;
      content: url("../../../assets/images/tagInterEnabled.png");
    }

    .interValidateInProcess {
      width: 102px;
      height: 21px;
      margin-right: 20px;
      content: url("../../../assets/images/tagInterInProcess.png");
    }

    .editaTrashcan {
      margin-left: 15px;
      padding: 5px 7px;
      width: 20.16px;
      height: 24px;
      border-radius: 25px;
      cursor: pointer;

      content: url("../../../assets/images/trashcan.svg");

      &:hover {
        content: url("../../../assets/images/trashcanRed.svg");
        background-color: #FCCCCC;
        transition: background-color 0.8s;
      }
    }
  }

  .default-address {
    margin-left: 4rem;
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    margin-top: 22px;


    .font-title {
      font-family: 'Heebo-Medium', serif;
      padding-bottom: 20px;
    }

    .font-title3 {
      font-family: 'Heebo-Medium', serif;
      width: 182px;
    }

    .height-space {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      min-width: 138px;

      .height-space2 {
        min-width: 102px;
        height: 21px;
        margin-top: 44px;

        content: url("../../../assets/images/tagInterDisabled.png");
      }

      .edit-profile {
        color: $color-accent-variant-register;
        cursor: pointer;
        font-family: 'Ubuntu-MediumItalic', serif;
        padding-top: 44px;
        margin: 0 10px 0 20px;
      }

      .address-font {
        font-family: 'Heebo-Regular', serif;
        font-size: 16px;
      }
    }
  }

    .empty-state {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      right: 0;
      bottom: 0;

      div {
        width: 198px;
      }
  }
}

.imagesEmptyState {
  .imageEmptyTitle {
    font-size: 22px;
  }

  .emptyDescription {
    max-width: 350px;

    p {
      font-size: 16px;
      color: $color-empty-state-text-light;
    }
  }

}
@media (min-width: 1367px) {
  .imagesEmptyState {

    .imageEmpty {
      max-width: 112px !important;
    }

  }
}
@media only screen and (max-width: 1366px) {
  .imagesEmptyState {

    .imageEmpty {
      max-width: 112px;
    }

  }

}

@media only screen and (max-width: 1110px) {
  .card-myAddress {
    .container-section-myAddress {
      display: flex;
      height: calc(92% - 50px);
      overflow: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: hidden;
    }

    .default-address {
      margin: 0;
    }

    .container-title-notificaion {
      display: none;
    }

    .container-title-notificaion2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
      max-width: 300px;
      margin: 1.6rem 5rem;

      p {
        font-size: 1em;
        color: #535A5F;

        span {
          cursor: pointer;
          font-weight: bold;
          color: $color-accent;
          font-family: "Heebo-Regular", serif;
        }
      }

    }

    .default-address2 {
      margin: 10px 2rem;
      padding: 10px 3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $color-gray-disabled;
      text-align: start;

      .address-font2 {
        font-family: 'Heebo-Regular', serif;
        font-size: 16px;
        width: 300px;
        word-break: normal;
      }

      .edit-profile2 {
        color: $color-accent-variant-register;
        cursor: pointer;
        font-family: 'Ubuntu-MediumItalic', serif;
        margin-bottom: 3px;
      }

      .font-title2 {
        font-family: 'Heebo-Medium', serif;
        font-size: 16px;
        width: 250px;
        word-break: normal;
      }

      .editaTrashcan {
        margin-left: 15px;
        padding: 5px 7px;
        width: 20.16px;
        height: 24px;
        border-radius: 25px;
        content: url("../../../assets/images/trashcan.svg");
      }
    }

    .default-address {
      margin-left: 5rem;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid $color-gray-disabled;

      .font-title {
        display: none;
      }

      .font-title3 {
        width: 300px;
        word-break: normal;
      }

      .height-space {
        min-width: 120px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .edit-profile {
          color: $color-accent-variant-register;
          cursor: pointer;
          font-family: 'Ubuntu-MediumItalic', serif;
          padding-top: 0;
          margin: 10px 10px;
        }

        .height-space2 {
          margin: 10px 0;
        }

        .address-font {
          font-family: 'Heebo-Regular', serif;
          font-size: 16px;
        }
      }
    }
  }
}

.changeButton {
  position: absolute;
  right: 50px;
  bottom: 12%;

  div {
    width: 198px;
  }
}

@media only screen and (max-width: 450px) {
  .card-myAddress {
    .container-title-notificaion2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
      max-width: 300px;
      margin: 1rem 2rem;

      p {
        font-size: 1em;
        color: $color-empty-state-text-light;
      }

      span {
        cursor: pointer;
        font-weight: bold;
        color: $color-accent;
        font-family: "Heebo-Regular", serif;
      }
    }

    .default-address2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $color-gray-disabled;
      text-align: start;
      padding: 0;

      .address-font2 {
        font-family: 'Heebo-Regular', serif;
        font-size: 16px;
        width: 200px;
        word-break: normal;
      }

      .edit-profile2 {
        color: $color-accent-variant-register;
        cursor: pointer;
        font-family: 'Ubuntu-MediumItalic', serif;
        margin-bottom: 3px;
      }

      .font-title2 {
        font-family: 'Heebo-Medium', serif;
        font-size: 16px;
        width: 200px;
        word-break: normal;
      }

      .editaTrashcan {
        margin-left: 15px;
        padding: 5px 7px;
        width: 20.16px;
        height: 24px;
        border-radius: 25px;
        content: url("../../../assets/images/trashcan.svg");
      }
    }

    .default-address {
      flex-direction: column;
      width: 200px;
      margin-left: 2rem;
      border-bottom: 1px solid $color-gray-disabled;

      .font-title {
        display: none;
      }

      .height-space2 {
        margin: 10px 0;
        padding-top: 10px;
      }
    }
  }

  .changeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: 100%;
    position: static;

    div {
      width: 198px;
    }
  }

  .imagesEmptyState {
    .imageEmptyTitle {
      font-size: 22px;
      width: 300px;
      text-align: center;
    }

    .emptyDescription {
      max-width: 300px;

      p {
        font-size: 16px;
        color: $color-empty-state-text-light;
      }
    }

  }
}
