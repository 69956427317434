@import '../../themes/fonts.scss';
@import '../../themes/colors.scss';

.imagesEmptyState{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  margin: 50px auto;

  .imageEmpty{
    display: block;
    margin: 0 auto;
    // margin-right: 100px;
  }

  .imageEmptyTitle{
    margin: 20px auto 10px;
    font-family: "Ubuntu-MediumItalic";
    font-size: 18px;
    letter-spacing: 0.45px;
    color: #353A3D;
    opacity: 1;
    width: max-content;
  }

  .emptyDescription, .emptySupportDescription{
    text-align: center;
    max-width: 300px;
    opacity: 1;
    font-family: 'Heebo-Regular';
    font-size: 14px;
    letter-spacing: 0px;
    color: $color-text-value;
  }

  .emptySupportDescription{
    margin-top: 10px;
    .link{ cursor:pointer; color:$color-accent }
  }
}
.description-section{
  width: 90%;
  height: 100%;
  margin: 50px auto;
  justify-content: space-around;

  p{
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }

  .buttons-collection{
    display:flex;
    justify-content: space-around;
    width: 100%;

    .less-width{
      width: 48%;
      height: 40px;
      min-height: 40px;
    }
  }
}
@media (max-width: 1023px) {
  .imagesEmptyState {
    .imageEmptyTitle{
      font-size: 16px;
    }
  }

  .description-section{
    width: 90%;
    height: 100%;
    margin: 15% auto;

    p {
      width: 70%;
      margin: 10% auto !important;
    }
  }
  .buttons-collection{
    display: inline-block !important;
    width: 100%;
    margin-top: 5% !important;

    .less-width{
      width: 48%;
      height: 40px;
      min-height: 40px;
      margin:10px auto;
    }
  }
}
@media (max-width: 1366px) {
  .imageEmpty{
    max-width: 70px;
  }
  .description-section p {
    width: 60% !important;
    margin-top: 10px !important;
  }
  .buttons-collection{
    margin-top: -1% !important;
  }
}
@media (min-width: 1367px ) {
  .imagesEmptyState{
    margin: 2% auto;
    font-size: 18px;
  }
  .imageEmpty{
    max-width: 70px !important;
  }
  .description-section {
    margin-top: 10% !important;
  }
  .description-section p {
    width: 60% !important;
    margin-top: 10% !important;
  }
  .buttons-collection{
    margin-top: 11% !important;
  }
}




