@import "../../../../themes/colors.scss";

#next-collection-detail {
  width: calc(100% - 25px);
  height: calc(100% - 30px);
  background-color: $color-light;
  border-radius: 10px;
  margin-top: 15px;

  .top {
    min-width: 360px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 18px;
    padding-bottom: 3px;

    .left {
      display: flex;
      align-items: center;
    }

    .right-mobile {
      display: none;
    }

    .arrow-back {
      width: 30px;
      height: 30px;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 50%;

       &.arrow-back {
       cursor: pointer;

        &:hover{
         background-color: $color-primary-alpha3;
         }
       }
    }

    .arrow-back-mobile {
      display: none;
    }

    .title {
      margin-left: 10px;
    }
 }

  .line {
    border: 1px solid $color-primary-variant;
    border-top: 0;
  }

  .first-subtitle {
    text-align: center;
    word-break: break-word;
    padding: 24px 0;
  }

  .second-subtitle {
    display: none;
  }

  .header-container {
    display: flex;
    width: 80%;
    margin: 0 48px;

    .scroll-container {
      width: 100%;
      min-width: 750px;
      display: flex;
      justify-content: space-between;

      .columns {
        width: 34%;

        &:first-child {
          max-width: 220px;
        }

        .row {
          padding: 10px 0;
          display: flex;

          &:first-child { padding-top: 0; }

          .header-quote-gray {
            margin-left: 8px;
          }

          .tooltip {
            width: fit-content;
            margin-left: 8px;
          }

          .text-overlay {
            .span {
              color: $color-dark;
              font-weight: bold;
              display: inline-flex;
              margin-right: 3px;
            }

            .link {
              color: $color-accent;
              text-decoration: underline;
              display: block;
              font-family: Heebo-Regular, serif;
              font-size: 14px;
            }
          }
        }
      }
    }

  }

  .customTotalColumns {
    grid-template-columns:
          0.5fr
          2fr
          2fr
          2fr
          1fr
          2fr
          2fr
          !important;
  }

  .wrapper-next-collection-senging-list {
    height: calc(100% - 232px);
  }
}


@media (max-width: 1023px) {
  #next-collection-detail {
    overflow: hidden;

    .top {
      min-width: 19em;
      .rigth {
        width: 30px;
        height: 30px;
      }
    }

    .first-subtitle {
      padding: 16px;
      text-align: left;
    }

    .second-subtitle {
      display: block;
      text-align: center;
      padding-top: 32px;
    }

    .header-container {
      width: calc(100% - 24px);
      margin: 0px;
      overflow-x: auto;
      padding: 0 24px;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      .scroll-container {
        min-width: 43em;
        justify-content: space-between;

        .columns:first-child {
          max-width: 120px;
        }
      }
    }

    .customTotalColumns {
      grid-template-columns: 5fr 5fr !important;
      grid-template-rows: 2fr 2fr 2fr 2fr 2fr;
    }
  }
}
