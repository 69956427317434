@import "../../../../themes/fonts.scss";
@import "../../../../themes/colors.scss";

$height-and-width: 40px;
$height-and-width-content: 14px;

.container-checkbox-and-label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  .container-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $height-and-width;
    width: $height-and-width;
    min-width: $height-and-width;

    .background-checkbox {
      position: absolute;
      border-radius: 50%;
      opacity: 0.2;
      background-color: transparent;
      width: $height-and-width-content;
      height: $height-and-width-content;

      &.checked {
        animation-name: checked;
        animation-duration: 400ms;
      }

      &.no-checked {
        animation-name: no-checked;
        animation-duration: 400ms;
      }
    }

    input {
      position: absolute;
      cursor: pointer;
      display: none;
    }

    .checkmark {
      position: absolute;
      height: $height-and-width-content;
      width: $height-and-width-content;
      border-width: 2px;
      border-style: solid;
      border-color: $color-dark-light;
      background-color: transparent;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        position: absolute;
        content: "";
        display: none;
        width: 2px;
        top: 2px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input {
      &:checked ~ .checkmark {
        background-color: $color-primary;
        border-color: $color-primary;

        &:after {
          display: block;
        }
      }

      &:checked ~ .checkmark.blue {
        background-color: $color-accent-variant-register;
        border-color: $color-accent-variant-register;

        &:after {
          display: block;
        }
      }
    }
  }

  span.text-checkbox {
    font-family: "Heebo-Regular", serif;
    font-size: 16px;
    color: $color-ligther-gray;

    &.color-blue {
      color: $color-blue;
    }
  }
}

@keyframes checked {
  0% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
  50% {
    background-color: $color-primary-variant;
    width: $height-and-width;
    height: $height-and-width;
  }
  100% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
}

@keyframes no-checked {
  0% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
  50% {
    background-color: $color-dark-light;
    width: $height-and-width;
    height: $height-and-width;
  }
  100% {
    background-color: transparent;
    width: $height-and-width-content;
    height: $height-and-width-content;
  }
}
