@import "../../../../themes/colors.scss";
@import "../../../../themes/fonts.scss";

.deleteDirection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 563px;
  height: 288px;
  -webkit-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
  -moz-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
  box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);

  .iconClose2 {
    width: 13.18px;
    height: 13.18px;
    position: absolute;
    mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
    -webkit-mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
    cursor: pointer;
    background-color: $color-primary-variant;
    margin-left: 495px;
  }

  .centerTitleModal {
    justify-content: center;
    display: flex;
  }

  .adjustDeleteDirection {
    display: flex;
    flex-direction: column;
  }

  .titlesDeleteDirection {
    width: 100%;
    display: flex;
    word-break: break-word;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    font-family: "Heebo-Medium", serif;

    .ajust-delete-address {
      margin-top: 15px;
      width: 120px;
    }

    div {
      display: flex;
      flex-direction: column;
      margin: 10px;
      max-width: 200px;
    }
  }


  .buttonModal {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

    #saveModalDelete {
      width: 196px;
      height: 40px;
      border: none;
      font-family: 'Ubuntu-MediumItalic', serif;
    }

    #cancelModalDelete {
      width: 212px;
      height: 34px;
    }
  }

  label {
    margin-top: 16px;
  }

  .confirmSaveDirection {
    width: 500px;
    -webkit-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
    -moz-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
    box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);

    .centerTitleModalDirection {
      justify-content: center;
      display: flex;
    }
  }

}

.confirmDeleteDirection {
  display: flex;
  height: 68px;
  width: 500px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .deleteDirection {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 308.6px;
    height: 473.05px;
    -webkit-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
    -moz-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
    box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);

    .iconClose2 {
      width: 13.18px;
      height: 13.18px;
      position: absolute;
      mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
      -webkit-mask: url(../../../../assets/images/close.svg) no-repeat center / contain;
      cursor: pointer;
      background-color: $color-primary-variant;
      margin-left: 270px;
      margin-top: -15px;
    }

    .centerTitleModal {
      margin-top: 10px;
      justify-content: center;
      display: flex;
    }

    .adjustDeleteDirection {
      display: flex;
      flex-direction: column;
    }

    .titlesDeleteDirection {
      margin-left: 25px;
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      font-family: "Heebo-Medium", serif;

      .ajust-delete-address {
        margin: 0;
        width: 100%;
      }

      div {
        display: flex;
        flex-direction: column;
        margin: 10px;
        max-width: 200px;
      }
    }


    .buttonModal {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-around;
      align-items: center;

      #saveModalDelete {
        width: 196px;
        height: 34px;
        border: none;
        font-family: 'Ubuntu-MediumItalic', serif;
      }

      #cancelModalDelete {
        margin-top: 15px;
        width: 196px;
        height: 34px;
      }
    }

    label {
      margin-top: 16px;
    }

    .confirmSaveDirection {
      width: 500px;
      -webkit-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
      -moz-box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);
      box-shadow: 2px 26px 20px -3px rgba(35 41 46 / 15%);

      .centerTitleModalDirection {
        justify-content: center;
        display: flex;
      }
    }

  }

  .confirmDeleteDirection {
    height: 68px;
    width: 316px;
    text-align: center;
  }
}
