@import "src/themes/fonts";
@import "src/themes/colors";
.container__content-info-delivery-companies {
  width: calc(100% - 10px);
  position: relative;
  z-index: 0;

  hr {
    position: absolute;
    bottom: 0;
    left: 18%;
    width: 64%;
    border: none;
    border-top: 1px solid $color-dark-light;
  }

.content-top__card-delivery-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0;
  flex-direction: column;
  width: 100%;

  .delivery-name {
    font-family: "Heebo-Medium", serif;
    color: $color-dark;
    font-size: 16px;
  }
}

  .shipping-real-value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 63px;
    border: 1px solid #d9dcdf;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    .icon-arrow-down {
      width: 10px;
      padding-right: 5px;
    }

    &__delivery {
      display: flex;
      align-items: center;
      gap: 5px;

      .discount {
        color: $color-green-discount;
      }

      .real {
        font-family: "Heebo-Regular", serif;
        font-size: 14px;
        color: $color-header-gray;
        display: flex;
        justify-content: flex-end;
        text-decoration: line-through;
      }
    }

    &__mount-score {
      display: flex;
      align-items: end;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      min-height: 82px;
    }
    span {
      font-family: "Heebo-Medium", serif;
      color: $color-dark;
      font-size: 16px;
      display: flex;
      align-items: flex-start;

      &.see-more {
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 2px;
        white-space: nowrap;
        color: $color-accent-variant;

        img {
          height: 8px;
          margin-left: 5px;

          &.up {
            transform: rotate(0deg);
          }

          &.down {
            transform: rotate(180deg);
          }
        }

        &:hover {
          border: 2px solid $color-primary;
        }
      }
    }

    .container-amount-without-discount {
      display: flex;
      flex-direction: column;
      margin-top: 4px;

      &__without-values {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .amount {
          text-decoration: none;
          font-size: 12px;
          color: $color-header-gray;
          font-family: "Heebo-Regular", serif;
        }
      }
    }

    .container-amount-discount {
      display: flex;
      flex-direction: column;
      text-align: end;
      margin-top: 4px;

      &__values {
        display: flex;
        gap: 3px;
        text-align: left;
        align-items: center;

        .amount-discount {
          font-size: 12px;
          color: $color-green-discount;
        }

        .previous-amount, .without-discount {
          font-size: 12px;
          color: $color-header-gray;
        }

        .previous-amount {
          text-decoration: line-through;
        }
      }

      &__text {
        font-size: 12px;
        color: $color-header-gray;
        font-family: "Heebo-Regular", serif;
      }
    }
  }

  .active {
    background-color: #f4f7f9;
  }


  .delivery-time {
    font-family: "Heebo-Regular", serif;
    color: $color-header-gray;
    font-size: 14px;
    margin: 10px 0;

    .blue {
      font-weight: bold;
      color: #009af0;
    }

    .days-delivered {
      font-family: "Heebo-Medium", serif;
    }
  }

  .container-delivery-info {
    display: flex;
    align-items: flex-start;
    z-index: 1;

    .score {
      display: flex;
      gap: 6px;
      margin-top: 3px;
      margin-left: 8px;

      p {
        font-family: "Heebo-Regular", serif;
        color: #7B8287;
      }
    }

    .delivery-img {
      height: 51px;
      width: 51px;
      margin-right: 16px;
      margin-top: 4px;
      position: relative;

      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .delivery-img .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(0 0 0 / 51%);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      opacity: 0;
      transition: opacity 0.3s;
      backdrop-filter: blur(5px);
    }

    .content-delivery-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      padding-right: 10px;
      box-sizing: border-box;

      .top {
        display: flex;
        gap: 10px;
      }

      .delivery-score {
        font-family: "Heebo-Regular", serif;
        color: $color-text-value;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 7px;

        .routeType {
          width: auto;
          font-size: 12px;
          padding: 4px 18px;
          color: $color-dark;
          border-radius: 25px;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: rgba(162, 170, 172, 0.3);
          margin-top: 4px;
        }
        .routeType-express {
          width: auto;
          font-size: 12px;
          padding: 4px 18px;
          color: $color-dark;
          border-radius: 25px;
          justify-content: center;
          font-family: "Ubuntu-Regular", sans-serif;
          background-color: $color-primary;
          margin-top: 4px;
        }

        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;

          & ~ div {
            height: 21px;
            display: flex;
            align-items: center;

            p {
              padding-top: 2px;
            }
          }
        }
      }
    }
  }
}
