@import "../../../../../themes/colors.scss";
@import "../../../../../themes/fonts.scss";

.modal-container {
  width: 602px;
  height: 271px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 25px;
    margin-top: -30px;
    margin-right: 15px;
    align-items: flex-end;

    .icon-button {
      width: auto;
      display: flex;
      flex-direction: column;
      .icon-close {
      padding: 8px;
      width: 17px;
      border-radius: 25px;
      cursor: pointer;

      &:hover {
        background-color: $color-primary-variant-light;
      }
    }
    }
  }

  .title-text {
    margin-top: -30px;
    font-size: 18px;
    color: $color-dark;
    font-family: "Ubuntu-BoldItalic", sans-serif;
  }

  .subtitle-modal {
    font-size: 16px;
    text-align: center;
    padding: 0 40px;
    line-height: 30px;
    margin-top: -22px;
    color: $color-empty-state-text;
    font-family: "Heebo-Regular", sans-serif;
  }

  .modal-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-evenly;

    .button-cancel {
      width: 212px;
      height: 38px;
    }

    .button-confirm {
      width: 196px;
      height: 38px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .modal-container {
    width: 303px;
    height: 374px;

    div {
      margin: 0;

      .icon-close {
        padding: 8px;
        margin-right: 10px;
      }
    }

    .title-text {
      margin-top: 0;
    }

    .subtitle-modal {
      margin-top: 0;
      padding: 0 20px;
      text-align: start;
    }

    .modal-buttons {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;

      .button-cancel {
        width: 179px;
        height: 38px;
        margin-top: 20px;
      }

      .button-confirm {
        width: 196px;
        height: 38px;
      }
    }
  }
}
