@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";
@import "../../themes/general.scss";

.modalOnboardingNoveltiesCenter{
  .modal-children-header{
    padding-left: 0 !important;

    .container-content-header{
      width: 100%;
    }

    h1{
      font-family: 'Ubuntu-BoldItalic';
      text-align: left !important;
    }
  }

  .contentModalOnboardingNoveltiesCenter{
    .top{
      width: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrowTop{
        width: fit-content;
        height: fit-content;
        padding: 7px 6px 7px 8px;
        border-radius: 50%;
        svg{ width: 20px; height: 20px }

        &:hover{
          background: rgba(0, 154, 240, 30%);
          svg .st2{ fill: $color-accent }
        }
      }

      .containerTextAndImage{
        display: flex;
        align-items: flex-start;

        img{
          width: 350px;
          border-radius: 8px;
          margin: 0 0 0 20px;
        }
        .containerText{
          margin: 0 20px;
          p, h1{ max-width: 370px }
        }

        &.widthLess{
          p, h1{ max-width: 280px}
        }
      }
    }

    .bottom{
      margin: 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .circle{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 8px;
        background-color: $color-general-disabled;

        &.selected{
          background-color: $color-primary;
        }
      }
    }
  }

  .modal-children-footer{
    margin: 0;
    button{ width: fit-content }
  }
}

@media only screen and (max-width: 1023px) {
  .modalOnboardingNoveltiesCenter{
    .contentModalOnboardingNoveltiesCenter{
      .top{
        .containerTextAndImage{
          flex-direction: column-reverse;
          
          img{
            max-width: 80vw;
          }

          .containerText{
            margin-bottom: 20px;
            p, h1{ max-width: 80vw }
          }
        }
      }
    }

    .modal-children-footer{
      button{
        svg{
          margin-left: 8px;
          transform: rotate(180deg);
          .b{ fill: white }
        }
      }
    }
  }
}
