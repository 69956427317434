@import "../../../themes/fonts.scss";
@import "../../../themes/colors.scss";
@import "../../../themes/general.scss";

$measure-image-item-card: 100px;
$margin-text-item-card: 8px;
.redeem-points-item-card{
  display: flex;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 4px;
  width: calc(100% - 8px);

  img{
    height: $measure-image-item-card;
    width: $measure-image-item-card;
    margin-right: 20px;
    object-fit: cover;
    background-color: $color-light;
    border-radius: 4px;
  }

  .text-card{
    width: calc(100% - #{$measure-image-item-card} - (#{$margin-text-item-card} * 2));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid $color-general-disabled;

    .title-and-points-text-card{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      p{ width: max-content }
    }

    & > p{
      margin: 10px 0;
    }

    .description-and-show-details-text-card{
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 10px;

      p{ max-width: 300px }
      h1{
        font-size: 18px;
        transition: all 100ms ease;
        &:hover{
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }

  &.is-possible-to-selected{
    cursor: pointer;
    &:hover,
    &.selected{
      background-color: $color-background;
    }
  }
}

$heigth-container-points: 80px;
.redeem-point-section{
  margin: 20px auto;
  width: min-content;

  .container-redeem-point-section{
    height: 100%;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span{
      display: flex;
      align-items: center;
      justify-content: center;
      height: $heigth-container-points;

      span.field{
        margin-right: 4px;

        & > span{
          font-family: 'Heebo-Regular', sans-serif;
        }
      }

      span.value{
        color: $color-primary-variant;
      }
    }

    .container-list-redeem-point-section{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 100px;
      box-sizing: border-box;
      height: calc(100% - #{$heigth-container-points});
      overflow-y: auto;
      padding-bottom: 10%;
    }

    .container-cash-redeem-point-section{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: calc(100% - #{$heigth-container-points});

      .input-cash-redeem-points{
        max-width: 350px;
      }

      .button-cash-redeem-points{
        max-width: 300px;
      }

      &.mobile-mode{
        .input-cash-redeem-points{
          max-width: 85%;
        }

        .button-cash-redeem-points{
          max-width: 80%;
        }
      }
    }
  }
}

.button-flotant-redeem-beneficts{
  position: fixed;
  bottom: 8%;
  right: 10%;
  max-width: 300px;
}

@media only screen and (max-width: 1023px) {
  .redeem-points-item-card{
    .text-card{
      .title-and-points-text-card{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        p{ margin: 10px 0 }
      }

      .description-and-show-details-text-card{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        h1{ max-width: 100%; margin: 10px 0 }
      }
    }

    &.is-possible-to-selected{
      cursor: pointer;
      &:hover,
      &.selected{
        background-color: $color-background;
      }

      .text-card{
        .description-and-show-details-text-card{
          h1{ &:hover{ font-size: 20px } }
        }
      }
    }
  }

  .redeem-point-section{
    width: calc(100% - 40px);

    .container-redeem-point-section{
      width: 100%;

      .container-list-redeem-point-section{
        padding: 0;
      }
    }
  }
}
