@import "../../themes/fonts.scss";
@import "../../themes/colors.scss";

.container-button {
  margin-top: 10% !important;
  margin-left: -25% !important;

  .description {
    width: 283px;
    background-color: #ffffff;
    font-family: "Heebo-Regular", serif;
    color: $color-primary-variant;
    padding: 8px;
    position: fixed;
  }

  .flexbox-container {
    display: flex;
    width: 95%;

    .button-help {
      width: 230px;
      padding: 3%;
      font-family: "Heebo-Regular", serif;
      background-color: #ffffff;

      p {
        color: $color-accent-variant;
        font-size: 18px;
        font-family: "Ubuntu-MediumItalic", serif;
      }

      img {
        width: 16px;
        padding-left: 10px
      }

    }
    .click-state {
      width: 300px;
      position: initial;
      border: 1px solid $color-primary-variant;
      transition: transform .35s ease-in-out;
      animation-duration: 10s;

      p {
        color: $color-primary-variant;
      }
    }
    .hidden-tooltip {
      visibility: hidden;
    }

    img {
      padding-right: 4%;
      cursor: pointer;
    }

    .right-image {
      padding: 0 4%;
    }

    .right-image > div {
      position: absolute;
      right: 30%;
      top: -5%;
      padding: 4%;
      border-radius: 10px;
      background: #ffffff;
      width: 280px;
      -webkit-box-shadow: 0px 0px 11px 1px #23292E26;
      -moz-box-shadow: 0px 0px 11px 1px #23292E26;
      box-shadow: 0px 0px 11px 1px #23292E26;
    }

    .right-image:not(:hover) > div {
      display: none;
    }

    .right-image > div::after {
      content: "";
      position: absolute;
      top: 80%;
      left: 88%;
      margin-left: -5px;
      border-width: 30px;
      border-right: 1px solid;
      line-height: 10px;
      border-style: solid;
      border-color: #ffffff transparent transparent transparent;
    }

    .title-contact {
      font-family: "Ubuntu-MediumItalic", serif;
      font-size: 16px;
    }
    .z-index {
      z-index: 9999;
    }

  }
}

@media only screen and (max-width: 1023px) {
  .container-button {
    width: 100%;
    margin-left: -5% !important;

    .flexbox-container {
      width: 100%;
      display: flex;
      justify-content: center;

      .button-help {
        margin-top: 2em;
      }
    }

  }

}
