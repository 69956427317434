@import "../../themes/colors.scss";

.add_box {
  border-radius: 5px;
  border: 1px solid $color-primary-variant;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  height: 32px !important;
  width: 32px !important;
  padding: 0px !important;
  margin: 10px !important;
  margin-bottom: 0px !important;
  cursor: pointer;

  &:hover {
    background-color: $color-primary-variant-light;
  }
}
