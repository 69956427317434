@import "../../../themes/colors.scss";
@import "../../../themes/fonts.scss";

#integrations {
  border-radius: 12px;
  background-color: $color-light;
  width: calc(100% - 40px);
  padding: 0 2%;
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .header-integrations {
    border-bottom: 3px solid $color-ligth-orange;
    height: 62.5px;
    font-size: 20px;
    padding: 0 30px 0 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    font-family: "Ubuntu-MediumItalic", sans-serif;

    .button-back {
      font-size: 20px;
      margin-left: 10px;
      color: #353a3d;
      font-weight: unset;
      letter-spacing: 0;
    }

    img {
      cursor: pointer;
      height: 16px;
      width: 16px;
      padding: 10px;
      border-radius: 50%;
      margin-right: 20px;

      &:hover {
        background-color: $color-primary-variant-light;
      }
    }
  }

  .integrations {
    height: 100%;
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;

    .left-menu {
      width: 20%;
      min-width: 200px;
      height: 100%;
      border-right: 1px solid $color-primary-variant;

      .select-menu {
        width: 187px;
        height: 56px;
        display: flex;
        padding-top: 10px;
        cursor: pointer;
        color: $color-text-label;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .text-menu {
          display: flex;
          height: 32px;
          font-size: 16px;
          padding-left: 15px;
          align-items: center;
          border-left: 3px solid $color-primary-variant;
          font-family: "Heebo-Medium", sans-serif;
        }
      }
    }

    .right-menu {
      width: 100%;
      height: 90%;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .header-mobile {
    width: 100%;
  }

  .integrations-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
    flex-direction: column;

    .integrations-direction {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
