@import '../../themes/colors.scss';

.container-section-desktop{
  position: fixed;
  width: calc(100% - 230px);
  height: calc(100% - 90px);
  bottom: 0;
  right: 0;

  &.full-width{
    width: 100% !important;
  }
}
